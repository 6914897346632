import { Filter, FilterProps, List, ListProps, TextField } from 'react-admin';
import React from 'react';
import ActionsColumn from '../ActionsColumn';
import FreeTextSearchInput from '../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import { PostPagination } from '../PostPagination';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import CustomDateField from '../Common/fields/CustomDateField';
import { FavoriteListEdit } from './FavoriteListEdit';
import { FavoriteListShow } from './FavoriteListShow';

const FavoriteActions = () => <ListActions showAction={{ selectAutoSave: true, export: false }} />;
const FavoriteFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput source="q" alwaysOn label="Free Text Search" />
    </Filter>
  );
};

export const FavoriteListList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Favorite Lists"
      sort={{ field: 'name', order: 'ASC' }}
      actions={<FavoriteActions />}
      perPage={50}
      pagination={<PostPagination />}
      filters={<FavoriteFilter />}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        expandSingle
        // optimized={true}
        editComponent={<FavoriteListEdit {...props} syncWithLocation={false} title={false} />}
        showComponent={<FavoriteListShow expandView />}
        bulkActionButtons={false}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <CustomDateField source="updatedAt" label="Updated At" sortable />
        <TextField source="updatedBy" label="Updated By" sortable />
        <TextField source="updatedByName" label="Updated By Name" sortable />
        <ActionsColumn label="Actions" />
      </DatagridConfigurableRbac>
    </List>
  );
};
