import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import { Button, useNotify, useRefresh, useUnselectAll } from 'react-admin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useChatContext } from '../Messaging/ChatContextProvider';
import { useDataProvider } from '../../provider/dataProvider/useDataProvider';
import { colors, shadows } from '../../theme';
import { RESOURCE_JOB_RESPONSE } from '../../provider/dataProvider/constants';
import { AddProspectProps } from './AddProspectButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: shadows.main,
  p: 4,

  '& h2': {
    color: colors.text.main,
    fontSize: '1rem',
    marginBottom: '1rem',
  },

  '& div': {
    marginBottom: '0.5rem',
  },
};

// window URL
const AddProspectSendNotificationButton: FC<AddProspectProps> = ({
  selectedIds = [],
  jobId,
  isRenderContentOnly = false,
  resource,
  onSuccess,
}) => {
  const [open, setOpen] = useState(isRenderContentOnly);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(resource);
  const { sendMessage } = useChatContext();
  const [textMessage, setTextMessage] = useState('');
  const dataProvider = useDataProvider();

  const addProspectAndNotify = async () => {
    let successCount = 0;
    let errorCount = 0;

    for (const candidateId of selectedIds) {
      await dataProvider
        .create(RESOURCE_JOB_RESPONSE, {
          data: {
            jobId,
            candidateId: candidateId,
            prospectStatusId: 1,
          },
        })
        .then(({ data }) => {
          successCount++;
        })
        .catch((error) => {
          console.log('Error', error);
          errorCount++;
        });
      if (successCount > 0) {
        await sendMessage(parseInt(candidateId as string), `${textMessage} candidate/joblink/${jobId}`);
      }
    }

    // Show summary notification
    if (successCount > 0) {
      notify(`Successfully added ${successCount} candidate(s) as prospect and notification sent`, {
        type: 'info',
        undoable: false,
      });
    }

    if (errorCount > 0) {
      notify(`Failed to add ${errorCount} candidate(s)`, { type: 'warning', undoable: false });
    }

    if (successCount > 0) {
      unselectAll();
      refresh();
      if (onSuccess) onSuccess();
    }
  };

  return (
    <>
      {!isRenderContentOnly && (
        <Button label="Add prospects and notify" onClick={handleOpen} style={{ marginRight: '1rem' }} disabled={false}>
          <NotificationsActive />
        </Button>
      )}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Enter Message to notify candidate
            </Typography>
            <TextField
              id="outlined-basic"
              label="Enter Message"
              variant="outlined"
              fullWidth
              value={textMessage}
              onChange={(e) => setTextMessage(e.target.value)}
            />

            <Button
              onClick={async () => {
                await addProspectAndNotify();
              }}
              label="Notify Candidate"
            >
              <NotificationsActive />
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

AddProspectSendNotificationButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddProspectSendNotificationButton;
