import { RowForm, RowFormProps } from '@react-admin/ra-editable-datagrid';
import { FC } from 'react';
import { required, SelectInput, useGetList } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';

type LookupRowFormProps = Omit<RowFormProps, 'children'> & {
  lookupResource: string;
  source?: string;
};

export const LookupRowForm: FC<LookupRowFormProps> = ({ lookupResource, source = 'lookupId', ...props }) => {
  const { data = [], isLoading } = useGetList(lookupResource, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
  });

  return (
    <RowForm {...props}>
      <SelectInput
        validate={[required()]}
        variant="outlined"
        label={false}
        source={source}
        choices={data}
        isLoading={isLoading}
      />
    </RowForm>
  );
};
