import Box from '@mui/material/Box';
import * as React from 'react';
import { Datagrid, FunctionField, List, TextField } from 'react-admin';
import { RESOURCE_CANDIDATE } from '../../provider/dataProvider/constants';
import CandidateMessageButton from '../Common/buttons/CandidateMessageButton';
import { Empty } from '../Common/Empty/Empty';
import LinkField from '../LinkField';
import { FilterFormTitle } from './components/FilterFormTitle';
import { DEFAULT_ROWS_PER_PAGE } from './constants';

const Header = () => {
  return (
    <Box display="flex" justifyContent="space-between" m={0} width="100%">
      <FilterFormTitle>New Candidates</FilterFormTitle>
    </Box>
  );
};

const defaultFilter = {
  notMessaged: true,
  deviceId_neq: null,
  isActive: true,
};

export const NewCandidates = () => {
  return (
    <List
      title=" "
      storeKey="dashboard-new-candidates"
      resource={RESOURCE_CANDIDATE}
      filterDefaultValues={defaultFilter}
      sort={{ field: 'availabilityDate', order: 'ASC' }}
      perPage={DEFAULT_ROWS_PER_PAGE}
      disableSyncWithLocation
      actions={<Header />}
    >
      <Datagrid
        rowClick={false}
        resource={RESOURCE_CANDIDATE}
        bulkActionButtons={false}
        empty={<Empty resourceName="candidates" />}
      >
        <LinkField source="id" label="Candidate" resource={RESOURCE_CANDIDATE}>
          <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} sortable={false} />
        </LinkField>
        <CandidateMessageButton />
      </Datagrid>
    </List>
  );
};
