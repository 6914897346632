import { FC } from 'react';
import { Box } from '@mui/material';
import { FunctionField, Labeled } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import { calculateRateWithMsp } from '../utils';

export const NetBillRateFields: FC = () => {
  return (
    <Box sx={commonStyles.flexBox}>
      <Labeled>
        <FunctionField
          label="Net ST Bill rate"
          render={(record) => (calculateRateWithMsp(record.straightTimeBillRate, record.msp) || 0).toFixed(2)}
          sx={commonStyles.flexBoxItem}
        />
      </Labeled>
      <Labeled>
        <FunctionField
          label="Net OT Bill rate"
          render={(record) => (calculateRateWithMsp(record.overtimeBillRate, record.msp) || 0).toFixed(2)}
          sx={commonStyles.flexBoxItem}
        />
      </Labeled>
      <Labeled>
        <FunctionField
          label="Net DT Bill rate"
          render={(record) => (calculateRateWithMsp(record.doubleTimeBillRate, record.msp) || 0).toFixed(2)}
          sx={commonStyles.flexBoxItem}
        />
      </Labeled>
    </Box>
  );
};
