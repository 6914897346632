import React from 'react';
import { Edit, EditProps, SimpleForm } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import { RESOURCE_CLIENT } from '../../provider/dataProvider/constants';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { ClientContactForm } from './ClientContactForm';

export const ClientContactEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit
      {...props}
      title="Edit Contact"
      actions={false}
      mutationMode="pessimistic"
      redirect={`/${RESOURCE_CLIENT}`}
      transform={(data) => {
        const sanitized = { ...data };
        if (data?.address) {
          if (isEmpty(data.address?.alternativeEmail)) {
            delete sanitized.address.alternativeEmail;
          }

          if (isEmpty(data.address?.email)) {
            delete sanitized.address.email;
          }
        }
        return sanitized;
      }}
    >
      <SimpleForm warnWhenUnsavedChanges toolbar={<FooterToolbar cancelButtonResource={RESOURCE_CLIENT} />}>
        <ClientContactForm />
      </SimpleForm>
    </Edit>
  );
};
