import { FC, useMemo } from 'react';
import { Route } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Admin as AdminEnterprise } from '@react-admin/ra-enterprise';
import { Resource } from '@react-admin/ra-rbac';
import './App.css';
import Work from '@mui/icons-material/Work';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import BusinessIcon from '@mui/icons-material/Business';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import { CustomRoutes, localStorageStore } from 'react-admin';
import { QueryClient } from '@tanstack/react-query';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
import {
  ROUTE_ENTER_EMAIL,
  ROUTE_FIELD_MANAGER_RESET_PASSWORD,
  ROUTE_FIELD_MANAGER_RESET_PASSWORD_CONFIRMATION,
  ROUTE_SENT_EMAIL,
  ROUTE_USER_PROFILE,
  RESOURCE_CANDIDATE,
  RESOURCE_CLIENT_CONTACT,
  RESOURCE_CLIENT,
  RESOURCE_JOB,
  RESOURCE_MESSAGING,
  RESOURCE_PERMISSION,
  RESOURCE_PLACEMENT,
  RESOURCE_PROJECT,
  RESOURCE_REPORT,
  RESOURCE_ROLE,
  RESOURCE_USER_FIELD_MANAGER,
  ROUTE_CANDIDATE_VERIFY_EMAIL,
  ROUTE_CANDIDATE_RESET_PASSWORD,
  ROUTE_CANDIDATE_RESET_PASSWORD_CONFIRMATION,
  RESOURCE_FAVORITE,
  RESOURCE_V3_JOB_ADDRESS,
  RESOURCE_CANDIDATE_DOCUMENTS,
  RESOURCE_V3_CANDIDATE_ADDRESS,
  RESOURCE_JOB_RESPONSE,
  RESOURCE_CLIENT_DOCUMENTS,
  RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES,
  RESOURCE_PROJECT_SUB_INDUSTRY,
  RESOURCE_PROJECT_DOCUMENTS,
  RESOURCE_CANDIDATE_MEMBERSHIPS,
  RESOURCE_CANDIDATE_CLEARANCES,
  RESOURCE_CANDIDATE_SKILLS,
  RESOURCE_CANDIDATE_SUBINDUSTRIES,
  RESOURCE_CANDIDATE_PROJECT_EXPERIENCE,
  RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_CANDIDATE_WORK_EXPERIENCE,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE,
  RESOURCE_CANDIDATE_CLIENT_EXPERIENCE,
  RESOURCE_HIGH_LEVEL_INDUSTRY,
  RESOURCE_PLACEMENT_DOCUMENTS,
  RESOURCE_PLACEMENT_COMMENTS,
  RESOURCE_CANDIDATE_COMMENTS,
  RESOURCE_CLIENT_COMMENTS,
  RESOURCE_PROJECT_COMMENTS,
  RESOURCE_JOB_COMMENTS,
  RESOURCE_PROJECT_CONTACT,
} from './provider/dataProvider/constants';
import { CandidateList } from './components/Candidate/CandidateList';
import { PlacementsList } from './components/Placements/PlacementsList';
import { PlacementShow } from './components/Placements/PlacementShow';
import { authProvider } from './provider/authProvider';
import { CandidateEdit } from './components/Candidate/CandidateEdit';
import Login from './components/Login';
import { useDataProvider } from './provider/dataProvider/useDataProvider';
import { theme } from './theme';
import { JobsList } from './components/Job/JobsList';
import { JobCreate } from './components/Job/JobCreate';
import { Dashboard } from './components/Dashboard/Dashboard';
import { AppLayout } from './AppLayout';
import { JobEdit } from './components/Job/JobEdit';
import { JobShow } from './components/Job/JobShow';
import { ClientList } from './components/Clients/ClientList';
import { ClientEdit } from './components/Clients/ClientEdit';
import { ClientShow } from './components/Clients/ClientShow';
import { ProjectList } from './components/Projects/ProjectList';
import { ProjectEdit } from './components/Projects/ProjectEdit';
import { ProjectShow } from './components/Projects/ProjectShow';
import { ClientCreate } from './components/Clients/ClientCreate';
import { ClientContactList } from './components/ClientContacts/ClientContactList';
import { ProjectCreate } from './components/Projects/ProjectCreate';
import { CandidateCreate } from './components/Candidate/CandidateCreate';
import { CandidateShow } from './components/Candidate/CandidateShow';
import { FavoriteListList } from './components/FavoriteList/FavoriteListList';
import { FavoriteListEdit } from './components/FavoriteList/FavoriteListEdit';
import { FavoriteListShow } from './components/FavoriteList/FavoriteListShow';
import { FavoriteListCreate } from './components/FavoriteList/FavoriteListCreate';
import { PlacementEdit } from './components/Placements/PlacementEdit';
import EnterEmail from './components/ForgotPassword/EnterEmail';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import EmailSent from './components/ForgotPassword/EmailSent';
import ResetPasswordConfirmation from './components/ForgotPassword/ResetPasswordConfirmation';
import { ProfileEdit, UserList, UserCreate, UserEdit, UserShow } from './components/Users';
import Messaging from './components/Messaging/Messaging';
import { ReportView } from './components/Reports/ReportView';
import { ReportList } from './components/Reports/ReportList';
import { PermissionCreate, PermissionEdit, PermissionList, PermissionShow } from './components/Permissions';
import { RoleList } from './components/Roles/RoleList';
import { RoleEdit } from './components/Roles/RoleEdit';
import { RoleShow } from './components/Roles/RoleShow';
import { RoleCreate } from './components/Roles/RoleCreate';
import { LOCAL_STORE_VERSION } from './App';
import CandidateVerifyEmail from './components/CandidateVerifyEmail/CandidateVerifyEmail';
import { ClientContactEdit } from './components/ClientContacts/ClientContactEdit';
import { ClientContactCreate } from './components/ClientContacts/ClientContactCreate';
import { ProjectContactList } from './components/ProjectContacts/ProjectContactList';

type Props = {
  queryClient: QueryClient;
};

const i18nProvider = polyglotI18nProvider(() => en, 'en', {
  allowMissing: true,
});

export const AppContent: FC<Props> = ({ queryClient }) => {
  const dataProvider = useDataProvider();

  return (
    <AdminEnterprise
      title="Harmoniq HR"
      theme={theme}
      darkTheme={null}
      loginPage={Login}
      queryClient={queryClient}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      layout={AppLayout}
      store={localStorageStore(LOCAL_STORE_VERSION)}
      disableTelemetry
    >
      <CustomRoutes>
        <Route key={ROUTE_ENTER_EMAIL} path={ROUTE_ENTER_EMAIL} element={<EnterEmail />} />
        <Route
          key={ROUTE_FIELD_MANAGER_RESET_PASSWORD}
          path={ROUTE_FIELD_MANAGER_RESET_PASSWORD}
          element={<ResetPassword resetPasswordFor="field-manager" />}
        />
        <Route
          key={ROUTE_FIELD_MANAGER_RESET_PASSWORD_CONFIRMATION}
          path={ROUTE_FIELD_MANAGER_RESET_PASSWORD_CONFIRMATION}
          element={<ResetPasswordConfirmation resetPasswordFor="field-manager" />}
        />
        <Route
          key={ROUTE_CANDIDATE_RESET_PASSWORD}
          path={ROUTE_CANDIDATE_RESET_PASSWORD}
          element={<ResetPassword resetPasswordFor="candidate" />}
        />
        <Route
          key={ROUTE_CANDIDATE_RESET_PASSWORD_CONFIRMATION}
          path={ROUTE_CANDIDATE_RESET_PASSWORD_CONFIRMATION}
          element={<ResetPasswordConfirmation resetPasswordFor="candidate" />}
        />
        <Route
          key={ROUTE_CANDIDATE_VERIFY_EMAIL}
          path={ROUTE_CANDIDATE_VERIFY_EMAIL}
          element={<CandidateVerifyEmail />}
        />
        <Route key={ROUTE_SENT_EMAIL} path={ROUTE_SENT_EMAIL} element={<EmailSent />} />
        <Route key={ROUTE_USER_PROFILE} path={ROUTE_USER_PROFILE} element={<ProfileEdit />} />
      </CustomRoutes>

      <Resource
        name={RESOURCE_CANDIDATE}
        list={CandidateList}
        edit={CandidateEdit}
        create={CandidateCreate}
        show={CandidateShow}
        icon={PeopleAltIcon}
        recordRepresentation={(record) => `${record.firstName} ${record.lastName}`}
        options={{ label: 'Candidate' }}
      />
      <Resource
        name={RESOURCE_JOB}
        list={JobsList}
        icon={Work}
        edit={JobEdit}
        create={JobCreate}
        show={JobShow}
        recordRepresentation={(record) => record.jobTitle}
        options={{ label: 'Job' }}
      />
      <Resource
        name={RESOURCE_PLACEMENT}
        list={PlacementsList}
        edit={PlacementEdit}
        show={PlacementShow}
        icon={AssignmentTurnedInIcon}
        recordRepresentation={(record) =>
          `${record.job.jobTitle} - ${record.candidate.firstName} ${record.candidate.lastName}`
        }
        options={{ label: 'Placement' }}
      />
      <Resource name={RESOURCE_MESSAGING} list={Messaging} icon={ChatBubbleIcon} options={{ label: 'Messaging' }} />
      <Resource
        name={RESOURCE_CLIENT}
        list={ClientList}
        edit={ClientEdit}
        create={ClientCreate}
        show={ClientShow}
        icon={BusinessIcon}
        recordRepresentation={(record) => record.name}
        options={{ label: 'Client' }}
      />
      <Resource
        name={RESOURCE_CLIENT_CONTACT}
        options={{ label: 'Contact' }}
        list={ClientContactList}
        create={ClientContactCreate}
        edit={ClientContactEdit}
        recordRepresentation={(record) => `${record.firstName} ${record.lastName}`}
      />
      <Resource
        name={RESOURCE_PROJECT}
        list={ProjectList}
        edit={ProjectEdit}
        create={ProjectCreate}
        show={ProjectShow}
        icon={ShopTwoIcon}
        recordRepresentation={(record) => record.name}
        options={{ label: 'Project' }}
      />
      <Resource
        name={RESOURCE_USER_FIELD_MANAGER}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        show={UserShow}
        recordRepresentation={(record) => `${record.firstName} ${record.lastName}`}
        options={{ label: 'Field Manager' }}
      />
      <Resource
        name={RESOURCE_FAVORITE}
        list={FavoriteListList}
        edit={FavoriteListEdit}
        create={FavoriteListCreate}
        show={FavoriteListShow}
        recordRepresentation={(record) => record.name}
        options={{ label: 'Favorite' }}
      />
      <Resource
        name={RESOURCE_REPORT}
        list={ReportList}
        show={ReportView}
        recordRepresentation={(record) => record.reportName}
        options={{ label: 'Report' }}
      />
      <Resource
        name={RESOURCE_PERMISSION}
        list={PermissionList}
        create={PermissionCreate}
        edit={PermissionEdit}
        show={PermissionShow}
        recordRepresentation={(record) => `${record.firstName} ${record.lastName}`}
        options={{ label: 'Permission Role' }}
      />
      <Resource
        name={RESOURCE_ROLE}
        list={RoleList}
        edit={RoleEdit}
        show={RoleShow}
        create={RoleCreate}
        recordRepresentation={(record) => record.name}
        options={{ label: 'Role' }}
      />

      <Resource
        name={RESOURCE_V3_JOB_ADDRESS}
        options={{ label: 'Address' }}
        recordRepresentation={(record) => record.address}
      />
      <Resource name={RESOURCE_CANDIDATE_DOCUMENTS} options={{ label: 'Document' }} />

      <Resource
        name={RESOURCE_V3_CANDIDATE_ADDRESS}
        options={{ label: 'Address' }}
        recordRepresentation={(record) => record.address}
      />
      <Resource name={RESOURCE_JOB_RESPONSE} options={{ label: 'Job Response' }} />
      <Resource name={RESOURCE_CLIENT_DOCUMENTS} options={{ label: 'Document' }} />
      <Resource name={RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES} options={{ label: 'Industry' }} />
      <Resource name={RESOURCE_PROJECT_SUB_INDUSTRY} options={{ label: 'Industry' }} />
      <Resource name={RESOURCE_PROJECT_DOCUMENTS} options={{ label: 'Document' }} />
      <Resource
        name={RESOURCE_PROJECT_CONTACT}
        list={ProjectContactList}
        options={{ label: 'Contact' }}
        recordRepresentation={(record) => `${record.firstName} ${record.lastName}`}
      />

      <Resource name={RESOURCE_CANDIDATE_MEMBERSHIPS} options={{ label: 'Membership' }} />
      <Resource name={RESOURCE_CANDIDATE_CLEARANCES} options={{ label: 'Clearance' }} />
      <Resource name={RESOURCE_CANDIDATE_SKILLS} options={{ label: 'Skill' }} />
      <Resource name={RESOURCE_CANDIDATE_SUBINDUSTRIES} options={{ label: 'Industry' }} />
      <Resource name={RESOURCE_CANDIDATE_PROJECT_EXPERIENCE} options={{ label: 'Project Experience' }} />
      <Resource name={RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE} options={{ label: 'Primary Work Experience' }} />
      <Resource name={RESOURCE_CANDIDATE_WORK_EXPERIENCE} options={{ label: 'Work Experience' }} />
      <Resource name={RESOURCE_CANDIDATE_OEM_EXPERIENCE} options={{ label: 'Oem Experience' }} />
      <Resource name={RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE} options={{ label: 'Oem Experience Type' }} />
      <Resource name={RESOURCE_CANDIDATE_CLIENT_EXPERIENCE} options={{ label: 'Client Experience' }} />
      <Resource name={RESOURCE_HIGH_LEVEL_INDUSTRY} options={{ label: 'Industry' }} />
      <Resource name={RESOURCE_PLACEMENT_DOCUMENTS} options={{ label: 'Document' }} />
      <Resource name={RESOURCE_PLACEMENT_COMMENTS} options={{ label: 'Comment' }} />
      <Resource name={RESOURCE_CANDIDATE_COMMENTS} options={{ label: 'Comment' }} />
      <Resource name={RESOURCE_CLIENT_COMMENTS} options={{ label: 'Comment' }} />
      <Resource name={RESOURCE_PROJECT_COMMENTS} options={{ label: 'Comment' }} />
      <Resource name={RESOURCE_JOB_COMMENTS} options={{ label: 'Comment' }} />
    </AdminEnterprise>
  );
};
