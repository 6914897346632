import React, { FC } from 'react';
import { CreateProps, SaveButton } from 'react-admin';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { SimpleCreateForm } from '../Common/forms/SimpleCreateForm';
import { UserCreateType } from './types';
import { UserForm } from './UserForm';

type UserCreateProps = Omit<CreateProps, 'children'>;

export const UserCreate: FC<UserCreateProps> = (props) => {
  return (
    <SimpleCreateForm
      {...props}
      title="Create User"
      toolbar={<FooterToolbar SaveButtonComponent={<SaveButton type="button" transform={transformUserObject} />} />}
    >
      <UserForm createMode />
    </SimpleCreateForm>
  );
};

function transformUserObject(data: UserCreateType) {
  return {
    ...data,
    acceptTerms: true,
  };
}
