import { FC } from 'react';
import { Box } from '@mui/material';
import { Identifier } from 'react-admin';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import JobCandidates from '../JobCandidates';
import { CandidateList } from '../CandidateList';

type CandidatesTabProps = {
  jobId: Identifier;
  withActions?: boolean;
};

export const CandidatesTab: FC<CandidatesTabProps> = ({ withActions = false, jobId }) => {
  return (
    <Box>
      <JobCandidates jobId={jobId} withActions={withActions} />
      {withActions && (
        <AccordionForm autoClose={false} toolbar={<></>} sx={{ paddingTop: '15px' }}>
          <AccordionFormPanel label="Add candidate as prospect">
            <CandidateList jobId={jobId} withActions={withActions} />
          </AccordionFormPanel>
        </AccordionForm>
      )}
    </Box>
  );
};
