import { Login, LoginForm } from 'react-admin';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Logo from '../assets/LeftLogo.png';
import { ROUTE_ENTER_EMAIL } from '../provider/dataProvider/constants';

const HRLogin = () => {
  return (
    <Login backgroundImage={Logo}>
      <Box sx={{ '.MuiTextField-root': { width: '100%' } }}>
        <LoginForm />
        <p style={{ textAlign: 'center', fontSize: '0.8rem' }}>
          <Link to={ROUTE_ENTER_EMAIL}>Forgot password ?</Link>
        </p>
      </Box>
    </Login>
  );
};

export default HRLogin;
