import { FC } from 'react';
import { AutocompleteInput, AutocompleteInputProps, ReferenceInput } from 'react-admin';
import { RESOURCE_ROLE } from '../../../provider/dataProvider/constants';
import { commonStyles } from '../../CommonStyles';

type RoleInputProps = AutocompleteInputProps;

export const RoleInput: FC<RoleInputProps> = (props) => {
  return (
    <ReferenceInput source="hrRoleId" reference={RESOURCE_ROLE}>
      <AutocompleteInput label="Role" sx={commonStyles.formComponentDefaultMargins} {...props} />
    </ReferenceInput>
  );
};
