import { Filter, FilterProps, List, ListProps, TextField, useRecordContext } from 'react-admin';
import React, { FC } from 'react';
import FreeTextSearchInput from '../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import { PostPagination } from '../PostPagination';
import ActionsColumn from '../ActionsColumn';
import { commonStyles } from '../CommonStyles';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import { Empty } from '../Common/Empty/Empty';
import { RoleEdit } from './RoleEdit';
import { RoleShow } from './RoleShow';

type RoleFilterProps = Omit<FilterProps, 'children'>;
const RoleFilter: FC<RoleFilterProps> = (props) => {
  return (
    <Filter {...props}>
      <FreeTextSearchInput alwaysOn />
    </Filter>
  );
};

const RoleActions: FC = () => {
  return (
    <ListActions showAction={{ selectAutoSave: true, export: false }}>
      <RoleFilter context="button" />
    </ListActions>
  );
};

const RoleActionsColumn: FC = () => {
  const record = useRecordContext();
  const isSystemRole = record?.name?.toLowerCase() === 'candidate' || record?.name?.toLowerCase() === 'admin';

  return <ActionsColumn label="Actions" actionsSettings={{ delete: !isSystemRole }} />;
};

export const RoleList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Roles"
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      exporter={false}
      perPage={50}
      pagination={<PostPagination />}
      actions={<RoleActions />}
      filters={<RoleFilter />}
      sx={[commonStyles.raFilterForm, commonStyles.formTabDatagrid]}
    >
      <DatagridConfigurableRbac
        editComponent={<RoleEdit expandView />}
        showComponent={<RoleShow expandView />}
        rowClick={false}
        bulkActionButtons={false}
        empty={<Empty resourceName="roles" />}
      >
        <TextField source="id" sortable label="ID" />
        <TextField source="name" sortable label="Role" />
        <RoleActionsColumn />
      </DatagridConfigurableRbac>
    </List>
  );
};
