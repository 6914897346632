import { FC } from 'react';
import { Filter, FilterProps } from 'react-admin';
import FreeTextSearchInput from '../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';

type PermissionFilterProps = Omit<FilterProps, 'children'>;

export const PermissionFilter: FC<PermissionFilterProps> = (props) => {
  return (
    <Filter {...props}>
      <FreeTextSearchInput alwaysOn />
    </Filter>
  );
};
