import { FC } from 'react';
import { Box } from '@mui/material';
import { EmailField, Labeled, ReferenceArrayField, SingleFieldList, TabbedShowLayout, TextField } from 'react-admin';
import { RESOURCE_CLIENT, RESOURCE_ROLE } from '../../provider/dataProvider/constants';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { commonStyles } from '../CommonStyles';

type UserShowProps = Omit<ShowLayoutProps, 'children'>;

export const UserShow: FC<UserShowProps> = (props) => {
  return (
    <ShowLayout {...props}>
      <TabbedShowLayout.Tab label="User" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="title" label="Title" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="firstName" label="First Name" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="lastName" label="Last Name" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <EmailField source="email" label="Email" sx={commonStyles.flexBoxLongItem} />
            </Labeled>
            <Labeled>
              <TextField source="phone" label="Phone Number" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <ReferenceArrayField
                source="clients"
                reference={RESOURCE_CLIENT}
                label="Clients"
                sx={commonStyles.flexBoxLongItem}
              >
                <SingleFieldList linkType={false} />
              </ReferenceArrayField>
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <ReferenceArrayField
                source="roles"
                reference={RESOURCE_ROLE}
                label="Roles"
                sx={commonStyles.flexBoxLongItem}
              >
                <SingleFieldList linkType="show" />
              </ReferenceArrayField>
            </Labeled>
          </Box>
        </Box>
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};
