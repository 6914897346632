import { FC } from 'react';
import { Box } from '@mui/material';
import { Datagrid, Labeled, List, TabbedShowLayout, TextField, useGetList, useRecordContext } from 'react-admin';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { commonStyles } from '../CommonStyles';
import { RESOURCE_CANDIDATE, RESOURCE_FAVORITE_CANDIDATE } from '../../provider/dataProvider/constants';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import LinkField from '../LinkField';
import { candidateRowStyle } from '../Candidate/CandidateCommon';
import { Empty } from '../Common/Empty/Empty';
import CustomDateField from '../Common/fields/CustomDateField';

type FavoriteListShowProps = Omit<ShowLayoutProps, 'children'>;

export const FavoriteListShow: FC<FavoriteListShowProps> = (props) => {
  return (
    <ShowLayout {...props}>
      <TabbedShowLayout.Tab label="Favorite List" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexBox}>
          <Labeled>
            <TextField source="id" label="ID" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled>
            <TextField source="name" label="Name" sx={commonStyles.flexBoxItem} />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBox}>
          <Labeled>
            <CustomDateField source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled>
            <TextField source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} />
          </Labeled>
          <Labeled>
            <TextField source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} />
          </Labeled>
        </Box>
        <CandidatesList />
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};

const CandidatesList: FC = () => {
  const record = useRecordContext();
  const { data: favoriteCandidates, isLoading: favoriteCandidatesLoading } = useGetList(RESOURCE_FAVORITE_CANDIDATE, {
    filter: { favoriteId: record.id },
    pagination: { page: 1, perPage: MAX_RECORD_PER_PAGE },
  });

  return (
    <List
      resource={RESOURCE_CANDIDATE}
      filter={{
        id: favoriteCandidates && favoriteCandidates.length > 0 ? favoriteCandidates.map((fc) => fc.candidateId) : [0],
        isActive: true,
      }}
      perPage={MAX_RECORD_PER_PAGE}
      sort={{ field: 'firstName', order: 'ASC' }}
      pagination={false}
      title=" "
      empty={<Empty resourceName="candidates" withWrapper />}
      actions={false}
      disableSyncWithLocation
      queryOptions={{ enabled: Array.isArray(favoriteCandidates) && !favoriteCandidatesLoading }}
    >
      <Datagrid
        sx={commonStyles.subGridDefaultStyle}
        rowSx={candidateRowStyle}
        rowClick={false}
        bulkActionButtons={false}
      >
        <LinkField resource={RESOURCE_CANDIDATE} source="id" label="First Name" showMode>
          <TextField source="firstName" sortable />
        </LinkField>
        <TextField source="lastName" sortable />
        <LinkField email source="personalEmail" showMode>
          <TextField source="personalEmail" label="Personal Email" sortable />
        </LinkField>
        <LinkField email source="workEmail" showMode>
          <TextField source="workEmail" label="Work Email" sortable />
        </LinkField>
        <LinkField phone source="homePhone" showMode>
          <TextField source="homePhone" label="Home Phone" sortable />
        </LinkField>
        <LinkField phone source="mobile" showMode>
          <TextField source="mobile" sortable />
        </LinkField>
        <CustomDateField source="availabilityDate" label="Availability" sortable />
        <CustomDateField source="availabilityDateLastUpdated" label="Avail Last Updated" sortable />
      </Datagrid>
    </List>
  );
};
