import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { DeleteWithConfirmButton, TextField } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import { StandaloneCreateButton } from '../buttons/StandaloneCreateButton';
import { Empty } from '../Empty/Empty';
import { ReferenceManyField } from '../fields/ReferenceManyField';
import RecordInfo from '../RecordInfo/RecordInfo';
import { LookupRowForm } from './LookupRowForm';
import { styles } from './styles';

type SimpleDatagridProps = {
  resource: string;
  lookupResource: string;
  defaultValues: object;
  label: string;
  selectSource?: string;
  textSource?: string;
  target?: string;
  outlined?: boolean;
  empty?: ReactElement;
  sx?: SxProps<Theme>;
  withActions?: boolean;
  emptyWithActions?: ReactElement;
};

export const SimpleDatagrid: React.FC<SimpleDatagridProps> = ({
  withActions = false,
  label,
  resource,
  lookupResource,
  defaultValues,
  selectSource,
  textSource = 'name',
  target = resource,
  sx,
  emptyWithActions = getDefaultEmptyWithActions(label),
  empty = getDefaultEmpty(label),
}) => {
  return (
    <Box sx={styles.container}>
      <ReferenceManyField reference={resource} target={target} sx={commonStyles.fullWidth}>
        <EditableDatagrid
          bulkActionButtons={false}
          editForm={withActions && <LookupRowForm lookupResource={lookupResource} source={selectSource} />}
          createForm={
            withActions && (
              <LookupRowForm lookupResource={lookupResource} defaultValues={defaultValues} source={selectSource} />
            )
          }
          actions={
            withActions && (
              <DeleteWithConfirmButton
                confirmTitle={
                  <>
                    Delete <RecordInfo />
                  </>
                }
                redirect={false}
                label=""
                sx={styles.deleteButton}
              />
            )
          }
          sx={[styles.datagrid, withActions ? styles.shrinkLastItem : {}, ...(Array.isArray(sx) ? sx : [sx])]}
          rowClick={false}
          empty={withActions ? emptyWithActions : empty}
        >
          <TextField source={textSource} label={label} sortable={false} />
        </EditableDatagrid>
      </ReferenceManyField>
    </Box>
  );
};

const getDefaultEmptyWithActions = (label: string) => (
  <StandaloneCreateButton sx={styles.createButton} label={`Create ${label}`} />
);
const getDefaultEmpty = (label: string) => <Empty resourceName={label.toLowerCase()} withWrapper />;
