import { AutocompleteInputProps } from 'ra-ui-materialui/src/input/AutocompleteInput';
import React, { FC, useEffect, useState } from 'react';
import { AutocompleteInput, required, useGetList, useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { RESOURCE_LOOKUP_COUNTRY } from '../../../provider/dataProvider/constants';

const MANUAL_DUMMY_ID = -9999;
const NOT_EXIST_ID = -9998;

export const CountryInput: FC<AutocompleteInputProps> = ({ source = 'addresses[0].country', ...rest }) => {
  const [finalData, setFinalData] = useState([]);
  const record = useRecordContext();

  const { data = [], isLoading } = useGetList(RESOURCE_LOOKUP_COUNTRY, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE,
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      const existingFieldValue = get(record, source, undefined);

      const _finalData = [...data];

      if (existingFieldValue) {
        const foundExistingValue = data.find((dataItem) => dataItem.name === existingFieldValue);
        if (!foundExistingValue) {
          _finalData.push({ id: NOT_EXIST_ID, name: existingFieldValue });
        }
      }

      setFinalData(_finalData);
    }
  }, [isLoading, data]);

  return (
    <AutocompleteInput
      {...rest}
      variant="outlined"
      source={source}
      optionValue="name"
      optionText="name"
      label="Country"
      isLoading={isLoading}
      choices={finalData}
      validate={[required()]}
      onCreate={(newItemName) => {
        const newItem = { id: MANUAL_DUMMY_ID, name: newItemName };
        const newChoices = [...finalData.filter((item) => item.id !== newItem.id), newItem];
        setFinalData(newChoices);
        return newItem;
      }}
    />
  );
};
