import { FC } from 'react';
import { Login } from 'react-admin';
import Logo from '../../assets/LeftLogo.png';
import { colors } from '../../theme';
import { CANDIDATE_APP_LINK_URL } from '../../provider/api';

type ResetConfirmationProps = {
  resetPasswordFor: 'field-manager' | 'candidate';
};

const ResetPasswordConfirmation: FC<ResetConfirmationProps> = ({ resetPasswordFor }) => {
  return (
    <Login backgroundImage={Logo}>
      <p
        style={{
          textAlign: 'center',
          marginTop: '2rem',
        }}
      >
        Your password has been reset.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2rem',
          marginBottom: '3rem',
        }}
      >
        <p
          style={{
            textAlign: 'center',
            marginTop: '0.5rem',
            fontSize: '0.9rem',
            marginBottom: '3rem',
            color: colors.text.light,
            padding: '0 2rem 0 2rem',
            width: '18rem',
          }}
        >
          {resetPasswordFor === 'field-manager' ? (
            <>
              Thank you - your password has been reset! You can now <a href="/login">login</a> to the HR Portal.
            </>
          ) : (
            <>
              Thank you - your password has been reset! You can now&nbsp;
              <a href={`${CANDIDATE_APP_LINK_URL}/hr-app-login`}>login</a>, build your profile, and set your date in the
              Rev1 Energy app.
            </>
          )}
        </p>
      </div>
    </Login>
  );
};

export default ResetPasswordConfirmation;
