import '@progress/kendo-theme-material/dist/all.css';
import './App.css';
import { QueryClient } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ChatContextProvider } from './components/Messaging/ChatContextProvider';
import createMyStore from './redux/createMyStore';
import { DATA_VALIDITY_DURATION } from './provider/constants';
import { AppContent } from './AppContent';

export const LOCAL_STORE_VERSION_KEY = 'RaStore.version';
export const LOCAL_STORE_VERSION = '1';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: DATA_VALIDITY_DURATION,
      },
    },
  });

  return (
    <Provider store={createMyStore()}>
      <ChatContextProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppContent queryClient={queryClient} />
        </LocalizationProvider>
      </ChatContextProvider>
    </Provider>
  );
}

export default App;
