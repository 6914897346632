import React from 'react';
import { EditProps, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import { RESOURCE_JOB_COMMENTS } from '../../provider/dataProvider/constants';
import { CommentsTab } from '../Comment/CommentsTab';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import { actionPermissions } from '../Permissions/constants';
import { useCanAccess } from '../../hooks/useCanAccess';
import { JobForm } from './JobForm';
import { CandidatesTab } from './tabs/CandidatesTab';
import { AddressesTab } from './tabs/AddressesTab';

export interface JobEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation?: boolean;
  expandView?: boolean;
}

export const JobEdit: React.FC<JobEditProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();
  const jobId = id || record?.id;
  const canEdit = useCanAccess(actionPermissions.edit);

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab label="Job">
        <JobForm />
      </LazyFormTab>

      <LazyFormTab label="Addresses">
        <AddressesTab jobId={jobId} withActions={canEdit} />
      </LazyFormTab>

      <LazyFormTab label="Comments">
        <CommentsTab reference={RESOURCE_JOB_COMMENTS} withActions={canEdit} />
      </LazyFormTab>

      <LazyFormTab label="Candidates">
        <CandidatesTab jobId={jobId} withActions={canEdit} />
      </LazyFormTab>
    </TabbedEditForm>
  );
};
