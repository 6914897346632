import { UseGetListOptions } from 'ra-core/src/dataProvider/useGetList';
import { GetListParams } from 'ra-core/src/types';
import { AutocompleteArrayInputProps } from 'ra-ui-materialui/src/input/AutocompleteArrayInput';
import { AutocompleteInputProps as RaAutocompleteInputProps } from 'ra-ui-materialui/src/input/AutocompleteInput';
import React from 'react';
import { AutocompleteArrayInput, AutocompleteInput as RaAutocompleteInput, useGetList } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';

type AutocompleteProps = AutocompleteArrayInputProps | RaAutocompleteInputProps;

const Autocomplete = ({ multiple, ...props }: AutocompleteProps) => {
  if (multiple) {
    return <AutocompleteArrayInput {...(props as AutocompleteArrayInputProps)} />;
  } else {
    return <RaAutocompleteInput {...(props as RaAutocompleteInputProps)} clearOnBlur clearOnEscape />;
  }
};

type AutocompleteInputProps = AutocompleteProps & {
  inputSource: string;
  queryParams?: Partial<GetListParams>;
  queryOptions?: UseGetListOptions;
  multiple?: boolean;
};

export const AutocompleteInput = ({
  source,
  queryParams = defaultQueryProp,
  queryOptions = defaultQueryProp,
  inputSource,
  ...rest
}: AutocompleteInputProps) => {
  const { data = [], isPending } = useGetList(
    source,
    {
      pagination: { page: 1, perPage: MAX_RECORD_PER_PAGE },
      ...queryParams,
    },
    queryOptions
  );

  return (
    <Autocomplete
      {...rest}
      source={inputSource}
      isPending={isPending}
      disabled={isPending || rest.disabled}
      choices={data}
    />
  );
};

const defaultQueryProp = {};
