import Box from '@mui/material/Box';
import React from 'react';
import { required } from 'react-admin';
import { commonStyles } from '../CommonStyles';
import { ActionInput } from './components/ActionInput';
import { PermissionInput } from './components/PermissionInput';
import { ResourceInput } from './components/ResourceInput';
import { RoleInput } from './components/RoleInput';

export const PermissionForm: React.FC = () => {
  return (
    <Box>
      <Box sx={commonStyles.flexBox}>
        <RoleInput validate={[required()]} sx={commonStyles.flexBoxLongItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <PermissionInput validate={[required()]} sx={commonStyles.flexBoxItem} />
        <ActionInput validate={[required()]} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ResourceInput validate={[required()]} sx={commonStyles.flexBoxLongItem} />
      </Box>
    </Box>
  );
};
