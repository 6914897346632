import Box from '@mui/material/Box';
import React from 'react';
import { Create, CreateProps, maxLength, required, SimpleForm, TextInput } from 'react-admin';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { US_COUNTRY_NAME } from '../../provider/constants';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';
import { CityInput } from '../Shared/AddressInputs/CityInput';
import { CountryInput } from '../Shared/AddressInputs/CountryInput';
import { StateInput } from '../Shared/AddressInputs/StateInput';
import { ClientSelect } from '../Shared/ClientSelect';

export const ProjectCreate: React.FC<CreateProps> = (props) => {
  const location = useLocation();
  const parsedSearch = qs.parse(location.search);
  const clientId = parsedSearch.clientId;

  return (
    <Create {...props} actions={false}>
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={<FooterToolbar />}
        sx={commonStyles.flexVertical}
        defaultValues={{ address: { country: US_COUNTRY_NAME }, clientId }}
      >
        <Box sx={commonStyles.flexBox}>
          <TextInput source="name" validate={[required(), maxLength(255)]} sx={commonStyles.flexBoxItem} />
          <ClientSelect />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="description"
            validate={[required()]}
            fullWidth
            multiline
            sx={commonStyles.flexBoxLongItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="address.addressLine1"
            label="Address"
            fullWidth
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxLongItem}
          />
          <TextInput
            source="address.zip"
            label="zip"
            validate={[maxLength(50)]}
            sx={{
              ...commonStyles.flexBoxItem,
              marginTop: '8px',
            }}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <CountryInput sx={commonStyles.flexBoxItem} source="address.country" />
          <StateInput sx={commonStyles.flexBoxItem} source="address.state" countryFieldName="address.country" />
          <CityInput sx={commonStyles.flexBoxItem} source="address.city" stateFieldName="address.state" />
        </Box>
      </SimpleForm>
    </Create>
  );
};
