import { AutocompleteInputProps } from 'ra-ui-materialui/src/input/AutocompleteInput';
import React, { FC } from 'react';
import { maxLength, required } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { RESOURCE_LOOKUP_STATE } from '../../../provider/dataProvider/constants';
import CustomAutoCompleteLookup from '../../CustomAutoComplete/CustomAutoCompleteLookup';

type StateInputProps = AutocompleteInputProps & {
  countryFieldName?: string;
};

export const StateInput: FC<StateInputProps> = ({
  countryFieldName = 'addresses[0].country',
  source = 'addresses[0].state',
  ...rest
}) => {
  const country = useWatch({ name: countryFieldName });

  return (
    <CustomAutoCompleteLookup
      {...rest}
      lookupResource={RESOURCE_LOOKUP_STATE}
      source={source}
      label="State/Province"
      parentName={country}
      validate={[required(), maxLength(50)]}
    />
  );
};
