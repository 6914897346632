import { FC } from 'react';
import { List, ListProps, TextField } from 'react-admin';
import { RESOURCE_ROLE } from '../../provider/dataProvider/constants';
import ActionsColumn from '../ActionsColumn';
import { PostPagination } from '../PostPagination';
import { commonStyles } from '../CommonStyles';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import LinkField from '../LinkField';
import { Empty } from '../Common/Empty/Empty';
import { ResourceField } from './components/ResourceField';
import { PermissionFilter } from './PermissionFilter';
import { PermissionListActions } from './components/PermissionListActions';
import { PermissionEdit } from './PermissionEdit';
import { PermissionSidebarFilters } from './components/FilterList';
import { DeletePermissionButton } from './components/DeletePermissionButton';
import { PermissionShow } from './PermissionShow';

export const PermissionList: FC<ListProps> = (props) => {
  return (
    <List
      title="Role Permissions"
      actions={<PermissionListActions />}
      filters={<PermissionFilter />}
      pagination={<PostPagination />}
      sort={{ field: 'id', order: 'ASC' }}
      perPage={50}
      sx={[commonStyles.raFilterForm, commonStyles.formTabDatagrid]}
      aside={<PermissionSidebarFilters />}
      {...props}
    >
      <DatagridConfigurableRbac
        bulkActionButtons={false}
        editComponent={<PermissionEdit expandView />}
        showComponent={<PermissionShow expandView />}
        empty={<Empty resourceName="role permissions" />}
        rowClick={false}
        expandSingle
      >
        <TextField source="id" label="ID" />
        <LinkField source="hrRoleId" label="Role" resource={RESOURCE_ROLE} sortBy="hrRole.name">
          <TextField source="hrRole.name" />
        </LinkField>
        <TextField source="permissionType" label="Permission Type" />
        <ResourceField source="resource" label="Resource" />
        <TextField source="action" label="Role Action" />
        <ActionsColumn label="Actions" customDeleteButton={<DeletePermissionButton />} />
      </DatagridConfigurableRbac>
    </List>
  );
};

const getSideFiltersKey = (resource: string): string => `${resource}.sideFilters`;
