import { Card } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { useRecordContext, useReferenceManyFieldController } from 'ra-core';
import { RaRecord } from 'ra-core/src/types';
import React from 'react';
import {
  ListContextProvider,
  ReferenceManyFieldProps as RAReferenceManyFieldProps,
  ResourceContextProvider,
} from 'react-admin';

type ReferenceManyFieldProps = RAReferenceManyFieldProps<RaRecord, RaRecord> & {
  alwaysDisplayWrapper?: boolean;
  sx?: SxProps<Theme>;
};

export const ReferenceManyField: React.FC<ReferenceManyFieldProps> = ({
  sx,
  alwaysDisplayWrapper = false,
  ...props
}) => {
  const {
    children,
    debounce,
    filter = defaultFilter,
    page = 1,
    pagination = null,
    perPage = 300,
    reference,
    resource,
    sort = defaultSort,
    source = 'id',
    storeKey,
    target,
    queryOptions,
  } = props;
  const record = useRecordContext(props);

  const controllerProps = useReferenceManyFieldController<RaRecord, RaRecord>({
    debounce,
    filter,
    page,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    storeKey,
    target,
    queryOptions,
  });

  const ContextProviders = (
    //@ts-ignore TS error occurs from react-admin
    <ResourceContextProvider value={reference}>
      <ListContextProvider value={controllerProps}>
        {children}
        {pagination}
      </ListContextProvider>
    </ResourceContextProvider>
  );

  if (alwaysDisplayWrapper || (controllerProps.data && controllerProps.data.length > 0)) {
    return <Card sx={sx}>{ContextProviders}</Card>;
  }

  return ContextProviders;
};

const defaultFilter = {};
const defaultSort = { field: 'id', order: 'DESC' as const };
