import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { Button, Identifier, useNotify, useRefresh, useUnselectAll } from 'react-admin';
import { RESOURCE_CANDIDATE, RESOURCE_CANDIDATE_CONTACT } from '../../../provider/dataProvider/constants';
import { useDataProvider } from '../../../provider/dataProvider/useDataProvider';
import ClientContactSelectionDialog from '../../ClientContacts/ClientContactSelectionDialog';

export type AddToContactFavoriteButtonProps = {
  selectedIds: Identifier[];
  onSuccess?: () => void;
};

const AddToContactFavoriteButton: React.FC<AddToContactFavoriteButtonProps> = ({ selectedIds = [], onSuccess }) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll(RESOURCE_CANDIDATE);
  const [isOpen, setIsOpen] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const onClicked = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button label="Add to Contact Favorite" onClick={onClicked} disabled={selectedIds.length === 0}>
        <FavoriteBorderIcon />
      </Button>

      {isOpen && (
        <ClientContactSelectionDialog
          onClose={() => setIsOpen(false)}
          onSelect={async (record) => {
            let successCount = 0;
            let errorCount = 0;

            for (const candidateId of selectedIds) {
              await dataProvider
                .create(RESOURCE_CANDIDATE_CONTACT, {
                  data: {
                    clientContactId: record.id,
                    candidateId: candidateId,
                  },
                })
                .then(({ data }) => {
                  successCount++;
                })
                .catch((error) => {
                  console.log('Error', error);
                  errorCount++;
                });
            }

            // Show summary notification
            if (successCount > 0) {
              notify(
                `Successfully added ${successCount} candidate(s) to "${record.firstName} ${record.lastName}" list`,
                {
                  type: 'info',
                  undoable: false,
                }
              );
            }

            if (errorCount > 0) {
              notify(`Failed to add ${errorCount} candidate(s)`, {
                type: 'warning',
                undoable: false,
              });
            }

            if (successCount > 0) {
              unselectAll();
              refresh();
            }

            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

AddToContactFavoriteButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddToContactFavoriteButton;
