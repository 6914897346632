import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { Button, Confirm, Identifier, useDelete, useGetMany, useNotify, useRefresh, useUnselectAll } from 'react-admin';
import { RESOURCE_CANDIDATE } from '../../../provider/dataProvider/constants';
import { sleep } from '../../../provider/UtilityFunctions';

export type DeleteMultipleButtonProps = {
  selectedIds: Identifier[];
};

const DeleteMultipleButton: React.FC<DeleteMultipleButtonProps> = ({ selectedIds = [] }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(RESOURCE_CANDIDATE);
  const [open, setOpen] = useState(false);
  const { data, isLoading, error } = useGetMany(RESOURCE_CANDIDATE, {
    ids: selectedIds,
  });
  const [deleteOne] = useDelete(RESOURCE_CANDIDATE);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    while (isLoading) {
      await sleep(100);
      console.log('Waiting for getMany resource is ready:', RESOURCE_CANDIDATE);
    }
    if (!error && data && data.length > 0) {
      try {
        await Promise.all(selectedIds.map((id) => deleteOne(RESOURCE_CANDIDATE, { id })));
        notify('Candidates deleted', { type: 'info', undoable: false });
        refresh();
        unselectAll();
      } catch (e) {
        notify(`Error while deleting candidates: ${e.message}`, { type: 'error' });
      }
    }
    setOpen(false);
  };

  return (
    <>
      <Button label="Delete Candidates" onClick={handleClick} disabled={isLoading}>
        <DeleteIcon />
      </Button>
      <Confirm
        isOpen={open}
        title={`Delete candidates ${selectedIds.join(', ')}?`}
        content="Are you sure you want to delete these items?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

DeleteMultipleButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default DeleteMultipleButton;
