import { AdditionalInfoType } from './types';

export const DEFAULT_AVATAR = 'https://harmoniqimages.s3.us-east-2.amazonaws.com/images/avatar/defaultavatar.png';
export const DEFAULT_DATE_FORMAT = 'MMM/DD/YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const MAX_RECORD_PER_PAGE = 5000;
export const MAX_EXPORT_PER_PAGE = 300000;
export const US_COUNTRY_NAME = 'United States';
export const LOOKUP_PAGE_SIZE = 100;

export const DATA_VALIDITY_DURATION = 15 * 60 * 1000; // 15 minutes

export const PROSPECT_SELECTED_AS_PROSPECT = 1;
export const PROSPECT_NOTIFIED_BY_RECRUITER = 2;
export const PROSPECT_NOT_INTERESTED = 3;
export const PROSPECT_INTERESTED_NOT_YET_SIGNED_RTR = 4;
export const PROSPECT_INTERESTED_RTR_SIGNED = 5;
export const PROSPECT_CANDIDATE_SUBMITTED = 6;
export const PROSPECT_CANDIDATE_DECLINED = 7;
export const PROSPECT_CANDIDATE_ACCEPTED = 8;
export const PROSPECT_PENDING_HELLOSIGN_APPROVAL = 9;

export const ROLE_ADMIN_ID = 1;
export const ROLE_CANDIDATE_ID = 2;

export const toggleStorageName = 'toggle';

export const generalTermsAndConditions: AdditionalInfoType = {
  businessExpenses: [
    'Pre-approval required for other business expenses prior to purchase.',
    'Pre-approval required for business expenses prior to purchase. A 10% administrative markup will be applied.',
    'N/A',
  ],
  expenses: [
    'All travel and living costs to be expensed including meals, lodging, rental car (mid-size/intermediate), fuel and other reasonable and customary expenses with receipts',
    'All travel and living costs to be expensed including meals, lodging, rental car (mid-size/intermediate), fuel and other reasonable and customary expenses with receipts. A 10% administrative markup will be applied.',
    'Per diem paid in lieu of expenses, paid 7 days per week. Per Diem will begin on employee’s first site working day.',
    'Per diem paid in lieu of expenses. Lodging per diem paid 7 days per week; Meals and Incidentals per diem paid only on days worked. Per Diem will begin on employee’s first site working day.',
    'N/A',
  ],
  rentalCar: '',
  rrHourly: ['Yes (Mileage or Coach Flight Only) - Supervisor Approval Required', 'NO'],
  paidRrTravel: ['Maximum of 8 hours at hourly rate each way', 'NO'],
  rev1Hardware: [
    'Laptop and email to be furnished by Rev1. Rev1 management reserves the right to reduce the cost of unreturned hardware from the employee`s final check.',
    'N/A',
  ],
  candidateMiscText: '',
  employerMiscText: '',
};
export const mobilizationAndDemobilization: AdditionalInfoType = {
  airFare: [
    'Coach class airfare reimbursed with receipt',
    'Coach class airfare reimbursed with receipt. A 10% administrative markup will be applied.',
    'N/A',
  ],
  vehicleUse: ['Personal vehicle will be reimbursed for mobilization and demobilization only.', 'N/A'],
  mileage: ['Mileage reimbursed at the max allowable IRS rate. Google map required.', 'N/A'],
  paidTravelTime: [
    'Maximum of 8 hours per day at hourly rate for greater than 500 miles. Less than 500 miles will be prorated',
    'N/A',
  ],
  hotelsAndMeals: [
    'Reasonable and customary with itemized receipts',
    'Reasonable and customary with itemized receipts. A 10% administrative markup will be applied.',
    'N/A',
  ],
};
export const billingInformation: AdditionalInfoType = {
  hourlyAndOvertimeText: [
    '{hourlyRate} ST/hour for up to and including 40 hours/week {overtimeRate} OT/hour for hours worked over 40/week',
  ],
  doubleTimeText: ['Double time at {doubleTimeRate}'],
  paymentTerms: ['All payment due Net Thirty (30) from date of receipt.'],
  foreignTax: ['All associated non-US tax liability will be paid directly by the customer'],
  otherCosts: ['Visas, work permits and non-US work related costs billed at cost.'],
};
