import { AutocompleteArrayInputProps } from 'ra-ui-materialui/src/input/AutocompleteArrayInput';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RESOURCE_LOOKUP_JOBTITLE } from '../../../provider/dataProvider/constants';
import { AutocompleteInput } from '../../Common/inputs/AutocompleteInput';

type JobTitleInputProps = AutocompleteArrayInputProps & {
  keyword?: string;
};

export const JobTitleInput: React.FC<JobTitleInputProps> = ({ keyword, ...props }) => {
  const { resetField } = useFormContext();
  const inputSource = 'jobTitleIds';

  useEffect(() => {
    resetField(inputSource);
  }, [keyword]);

  return (
    <AutocompleteInput
      {...props}
      label="Job Title"
      source={RESOURCE_LOOKUP_JOBTITLE}
      inputSource={inputSource}
      multiple
      disabled={!keyword}
      queryParams={{ filter: { keyword } }}
      queryOptions={{ enabled: Boolean(keyword) }}
    />
  );
};
