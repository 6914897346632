import { DataProvider, GetListParams } from 'ra-core/src/types';
import api from './api';
import { DEFAULT_AVATAR, MAX_RECORD_PER_PAGE } from './constants';
import { apiEndpointMatrix } from './dataProvider/apiEndpointMatrix';
import {
  RESOURCE_CANDIDATE_CLEARANCES,
  RESOURCE_CANDIDATE_CLIENT_EXPERIENCE,
  RESOURCE_CANDIDATE_MEMBERSHIPS,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE,
  RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_CANDIDATE_PROJECT_EXPERIENCE,
  RESOURCE_CANDIDATE_SKILLS,
  RESOURCE_CANDIDATE_SUBINDUSTRIES,
  RESOURCE_CANDIDATE_WORK_EXPERIENCE,
  RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES,
  RESOURCE_PLACEMENT_DOCUMENTS,
  RESOURCE_LOOKUP_JOBTITLE,
  RESOURCE_HIGH_LEVEL_INDUSTRY,
  RESOURCE_LOOKUP_STATE,
  RESOURCE_LOOKUP_CITY,
  RESOURCE_LOOKUP_MEMBERSHIPS,
  RESOURCE_LOOKUP_COUNTRY,
  RESOURCE_LOOKUP_SKILL,
  RESOURCE_LOOKUP_SUBINDUSTRY,
  RESOURCE_LOOKUP_WORK_EXPERIENCE,
  RESOURCE_LOOKUP_CLEARANCE,
  RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_LOOKUP_OEM_EXPERIENCE,
  RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE,
  RESOURCE_LOOKUP_CLIENT_EXPERIENCE,
  RESOURCE_LOOKUP_PROJECT_EXPERIENCE,
  RESOURCE_REPORT,
  RESOURCE_CLIENT_DOCUMENTS,
  RESOURCE_PROJECT_DOCUMENTS,
  RESOURCE_V3_CANDIDATE_ADDRESS,
  RESOURCE_V3_JOB_ADDRESS,
} from './dataProvider/constants';
import { getDocuments, processApiCall } from './restProviderHelper';
import { getUser } from './authProvider';

export const oldDataProvider: DataProvider = {
  getList: async (resource, params: GetListParams) => {
    switch (resource) {
      case RESOURCE_LOOKUP_JOBTITLE: {
        const transformData = (data) => data.map((item) => ({ id: parseInt(item.value), name: item.text }));

        return processApiCall(
          api.lookup.getData,
          { transformData },
          resource,
          params?.filter?.parentId,
          params?.filter?.parentName,
          params?.filter?.keyword
        );
      }
      case RESOURCE_HIGH_LEVEL_INDUSTRY:
      case RESOURCE_LOOKUP_COUNTRY:
      case RESOURCE_LOOKUP_STATE:
      case RESOURCE_LOOKUP_CITY:
      case RESOURCE_LOOKUP_MEMBERSHIPS:
      case RESOURCE_LOOKUP_CLEARANCE:
      case RESOURCE_LOOKUP_SKILL:
      case RESOURCE_LOOKUP_SUBINDUSTRY:
      case RESOURCE_LOOKUP_WORK_EXPERIENCE:
      case RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE:
      case RESOURCE_LOOKUP_OEM_EXPERIENCE:
      case RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE:
      case RESOURCE_LOOKUP_CLIENT_EXPERIENCE:
      case RESOURCE_LOOKUP_PROJECT_EXPERIENCE: {
        const transformData = (data) => data.map((item) => ({ id: parseInt(item.value), name: item.text }));

        return processApiCall(
          api.lookup.getData,
          { transformData },
          resource,
          params?.filter?.parentId,
          params?.filter?.parentName
        );
      }
      case RESOURCE_REPORT: {
        const transformData = (data) => {
          data.model.forEach((report) => {
            report.id = report.reportId;
          });
          return data.model;
        };
        const transformTotal = (data) => data.model.length;

        return processApiCall(api.reports.getList, { transformData, transformTotal });
      }
      default:
        throw `Not supported Resource: ${resource}`;
    }
  },
  getOne: async (resource, params) => {
    const apiFunc = apiEndpointMatrix[resource].get;
    const apiParams = params.id;

    if (!apiFunc) {
      throw `Not supported Resource: ${resource}`;
    }

    const transformData = (data) => {
      if (resource === RESOURCE_REPORT) {
        data.model.id = data.model.reportId;
      }
      return data.model;
    };
    const transformTotal = () => undefined;

    return processApiCall(apiFunc, { transformData, transformTotal }, apiParams);
  },
  getMany: async (resource, params) => {
    const transformTotal = () => undefined;
    const lookupResources = [RESOURCE_LOOKUP_STATE, RESOURCE_LOOKUP_CITY, RESOURCE_LOOKUP_COUNTRY];

    if (lookupResources.includes(resource)) {
      const transformData = (data) => data.map((item) => ({ id: parseInt(item.value), name: item.text }));
      return processApiCall(api.lookup.getData, { transformData, transformTotal }, resource);
    }

    const apiFn = apiEndpointMatrix[resource].getMany;
    if (!apiFn) {
      throw `Not supported Resource: ${resource}`;
    }

    const param = { pageNumber: 1, pageSize: MAX_RECORD_PER_PAGE, ids: params.ids };
    const transformData = (data) => data.model;

    return processApiCall(apiFn, { transformData, transformTotal }, param);
  },
  getManyReference: async (resource, params) => {
    const transformDataModel = (data) => data.model;

    switch (resource) {
      case RESOURCE_CLIENT_DOCUMENTS:
      case RESOURCE_PROJECT_DOCUMENTS:
      case RESOURCE_PLACEMENT_DOCUMENTS: {
        const apiFunc = apiEndpointMatrix[resource].getList;
        return getDocuments(apiFunc, params.id, params.sort);
      }
      case RESOURCE_V3_CANDIDATE_ADDRESS:
      case RESOURCE_V3_JOB_ADDRESS: {
        const apiFn = apiEndpointMatrix[resource].getList;
        const transformTotal = (data) => data.model.length;

        return processApiCall(apiFn, { transformData: transformDataModel, transformTotal }, params.id);
      }
    }

    const apiFunc = apiEndpointMatrix[resource].getList;
    if (!apiFunc) {
      throw `Not supported Resource: ${resource}`;
    }
    const apiPostProcessFunc = undefined;

    const transformData = (data) => (apiPostProcessFunc ? apiPostProcessFunc(data.model) : data.model);
    const transformTotal = (data) => data.model.length;

    return processApiCall(apiFunc, { transformData, transformTotal }, params.id);
  },
  create: async (resource, params) => {
    const transformData = (data) => data.model;
    const transformTotal = () => undefined;

    const apiFunc = apiEndpointMatrix[resource].create;
    if (!apiFunc) {
      throw `Not supported Resource: ${resource}`;
    }

    switch (resource) {
      case RESOURCE_CLIENT_DOCUMENTS:
      case RESOURCE_PROJECT_DOCUMENTS: {
        const formData = new FormData();
        if (params.data.candidateId) {
          formData.append('CandidateId', params.data.candidateId);
        } else if (params.data.clientId) {
          formData.append('ClientId', params.data.clientId);
        } else if (params.data.projectId) {
          formData.append('ProjectId', params.data.projectId);
        }
        formData.append('Name', params.data.Name);
        formData.append('DocumentType', params.data.DocumentType);
        formData.append('FileType', params.data.FileType);
        formData.append('File', params.data.File.rawFile);

        const transformData = () => ({ id: params.data.candidateId });

        return processApiCall(apiFunc, { transformData, transformTotal }, formData);
      }
      case RESOURCE_V3_CANDIDATE_ADDRESS: {
        return processApiCall(apiFunc, { transformData, transformTotal }, params.data?.candidateId, params.data);
      }
      case RESOURCE_V3_JOB_ADDRESS: {
        return processApiCall(apiFunc, { transformData, transformTotal }, params.data?.jobId, params.data);
      }
    }

    let apiParams = [params.data];

    switch (resource) {
      case RESOURCE_CANDIDATE_MEMBERSHIPS:
      case RESOURCE_CANDIDATE_CLEARANCES:
      case RESOURCE_CANDIDATE_SKILLS:
      case RESOURCE_CANDIDATE_SUBINDUSTRIES:
      case RESOURCE_CANDIDATE_PROJECT_EXPERIENCE:
      case RESOURCE_CANDIDATE_WORK_EXPERIENCE:
      case RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE:
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE:
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE:
      case RESOURCE_CANDIDATE_CLIENT_EXPERIENCE:
        {
          apiParams = [params.data.candidateId, params.data];
        }
        break;
      case RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES:
        {
          apiParams = [params.data.clientId, params.data];
        }
        break;
    }

    const transformCandidateData = (data) => {
      if (data) {
        return data.model;
      } else {
        console.error("Api needs a change. Didn't return any data after creation!", apiFunc, apiParams);
        return { id: 'unknown' };
      }
    };

    return processApiCall(apiFunc, { transformData: transformCandidateData, transformTotal }, ...apiParams);
  },
  update: async (resource, params) => {
    const transformData = (data) => data?.model || params.data;
    const transformTotal = () => undefined;

    const apiFn = apiEndpointMatrix[resource].update;
    if (!apiFn) {
      throw `Not supported Resource: ${resource}`;
    }

    switch (resource) {
      case RESOURCE_V3_CANDIDATE_ADDRESS: {
        const transformData = () => params.data;
        return processApiCall(apiFn, { transformData, transformTotal }, params.data.candidateId, params.data);
      }
      case RESOURCE_V3_JOB_ADDRESS: {
        const transformData = () => params.data;
        return processApiCall(apiFn, { transformData, transformTotal }, params.data.jobId, params.data);
      }
    }
  },
  updateMany: (resource) => Promise.reject(`Not supported Resource: ${resource}`),
  delete: async (resource, params) => {
    const apiFunc = apiEndpointMatrix[resource].delete;
    let apiParams = [params.id];

    if (!apiFunc) {
      throw `Not supported Resource: ${resource}`;
    }

    switch (resource) {
      case RESOURCE_V3_CANDIDATE_ADDRESS:
      case RESOURCE_CANDIDATE_MEMBERSHIPS:
      case RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE:
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE:
      case RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE:
      case RESOURCE_CANDIDATE_CLIENT_EXPERIENCE:
      case RESOURCE_CANDIDATE_CLEARANCES:
      case RESOURCE_CANDIDATE_SKILLS:
      case RESOURCE_CANDIDATE_SUBINDUSTRIES:
      case RESOURCE_CANDIDATE_PROJECT_EXPERIENCE:
      case RESOURCE_CANDIDATE_WORK_EXPERIENCE:
        apiParams = [params.previousData.candidateId, params.id];
        break;
      case RESOURCE_V3_JOB_ADDRESS:
        apiParams = [params.previousData.jobId, params.id];
        break;
      case RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES:
        apiParams = [params.previousData.clientId, params.id];
        break;
    }

    const transformData = () => ({ id: params.id, ...params.previousData });
    const transformTotal = () => undefined;

    return processApiCall(apiFunc, { transformData, transformTotal, successCodes: [204] }, ...apiParams);
  },
  deleteMany: (resource, params) => Promise.reject(`Not supported Resource: ${resource}`),
  getUserProfile: (params) => {
    const user = getUser();
    if (user) {
      return Promise.resolve({
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        avatar: DEFAULT_AVATAR,
      });
    }
    return Promise.reject("Couldn't get user details");
  },
};
