import { FC } from 'react';
import { DeleteWithConfirmButton, DeleteWithConfirmButtonProps, useRecordContext } from 'react-admin';
import ArchiveIcon from '@mui/icons-material/Archive';

type DeleteUserButtonProps = Omit<DeleteWithConfirmButtonProps, 'confirmTitle' | 'label' | 'children'>;

export const DeleteUserButton: FC<DeleteUserButtonProps> = (props) => {
  const record = useRecordContext();

  return record.isActive ? (
    <DeleteWithConfirmButton
      label=""
      confirmTitle="Deactivate User"
      confirmContent={`Are you sure you want to deactivate  ${record?.firstName} ${record?.lastName}?`}
      icon={<ArchiveIcon />}
      {...props}
    />
  ) : (
    <> </>
  );
};
