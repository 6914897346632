import { Option } from './types';

export const permissionTypeChoices = [
  { id: 'allow', name: 'Allow' },
  // { id: 'deny', name: 'Deny' },
];

export const actionPermissions = {
  list: 'list',
  create: 'create',
  edit: 'edit',
  show: 'show',
  delete: 'delete',
  forceDelete: 'forceDelete',
  export: 'export',
  import: 'import',
};

export const actionChoices = [
  { id: actionPermissions.list, name: 'List' },
  { id: actionPermissions.create, name: 'Create' },
  { id: actionPermissions.edit, name: 'Edit' },
  { id: actionPermissions.show, name: 'Show' },
  { id: actionPermissions.delete, name: 'Delete' },
  { id: actionPermissions.export, name: 'Export' },
  { id: actionPermissions.import, name: 'Import' },
];

export const resourceChoices: Option[] = [
  { id: 'candidate', name: 'Candidate' },
  { id: 'candidate-address', name: 'Candidate Address' },
  { id: 'candidate-device', name: 'Candidate Device' },
  { id: 'candidate-document', name: 'Candidate Document' },
  { id: 'candidate-image', name: 'Candidate Image' },
  { id: 'candidate-job', name: 'Candidate Job' },
  { id: 'candidates', name: 'Candidates' },
  { id: 'chat', name: 'Chat' },
  { id: 'client', name: 'Client' },
  { id: 'client-document', name: 'Client Document' },
  { id: 'clients', name: 'Clients' },
  { id: 'comments', name: 'Comments' },
  { id: 'comments-candidate', name: 'Comments Candidate' },
  { id: 'comments-client', name: 'Comments Client' },
  { id: 'comments-job', name: 'Comments Job' },
  { id: 'comments-placement', name: 'Comments Placement' },
  { id: 'comments-project', name: 'Comments Project' },
  { id: 'favorite', name: 'Favorite' },
  { id: 'field-manager-image', name: 'Field Manager Image' },
  { id: 'job', name: 'Job' },
  { id: 'job-address', name: 'Job Address' },
  { id: 'job-response', name: 'Job Response' },
  { id: 'jobs', name: 'Jobs' },
  { id: 'lookup', name: 'Lookup' },
  { id: 'lookup-candidate', name: 'Lookup Candidate' },
  { id: 'lookup-field-manager', name: 'Lookup Field Manager' },
  { id: 'lookup-job', name: 'Lookup Job' },
  { id: 'lookup-project', name: 'Lookup Project' },
  { id: 'messaging', name: 'Messaging' },
  { id: 'permission-role', name: 'Permission Role' },
  { id: 'placement', name: 'Placement' },
  { id: 'placement-document', name: 'Placement Document' },
  { id: 'placements', name: 'Placements' },
  { id: 'project', name: 'Project' },
  { id: 'project-document', name: 'Project Document' },
  { id: 'report', name: 'Report' },
  { id: 'role', name: 'Role' },
  { id: 'user', name: 'User' },
  { id: 'user-candidate', name: 'User Candidate' },
  { id: 'user-field-manager', name: 'User Field Manager' },
];
