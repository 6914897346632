import AddIcon from '@mui/icons-material/Add';
import { SxProps, Theme } from '@mui/material/styles';
import { useEditableDatagridContext } from '@react-admin/ra-editable-datagrid';
import React, { FC } from 'react';
import { Button } from 'react-admin';

type StandaloneCreateButtonProps = {
  label?: string;
  sx?: SxProps<Theme>;
};

export const StandaloneCreateButton: FC<StandaloneCreateButtonProps> = ({ label, sx }) => {
  const { openStandaloneCreateForm } = useEditableDatagridContext();

  const handleClick = () => {
    openStandaloneCreateForm();
  };

  return <Button startIcon={<AddIcon />} onClick={handleClick} label={label} sx={sx} />;
};
