import Typography from '@mui/material/Typography';
import * as React from 'react';
import { TypographyProps } from '@mui/material/Typography/Typography';

export const FilterFormTitle = ({ children, ...rest }: TypographyProps) => {
  return (
    <Typography
      {...rest}
      variant="h5"
      color="textSecondary"
      align="center"
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '58px',
        marginBottom: '5px',
        marginTop: '5px',
      }}
    >
      {children}
    </Typography>
  );
};
