import React, { FC } from 'react';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { EditPropsWithLocation } from '../types';
import { PermissionRoleTitle } from './components/PermissionRoleTitle';
import { PermissionForm } from './PermissionForm';

type PermissionEditProps = EditPropsWithLocation & {
  expandView?: boolean;
};

export const PermissionEdit: FC<PermissionEditProps> = (props) => {
  return (
    <TabbedEditForm {...props} title={<PermissionRoleTitle />}>
      <LazyFormTab label="Role Permission">
        <PermissionForm />
      </LazyFormTab>
    </TabbedEditForm>
  );
};
