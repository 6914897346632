import { RealtimeClient } from 'ably';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { loadChatRooms } from '../../../redux/actions/chatkitActions';
import api from '../../../provider/api';
import { ChatContextType } from '../ChatContextProvider';
import { OnMessageReceivesCallback } from '../types';
import {
  getAllActiveChannels,
  getCandidateChannelId,
  subscribeToCandidateChannel,
  subscribeToServiceChannel,
} from './channels-utils';
import { SERVICE_CHANNEL_NAME } from './constants';
import { initConnection } from './connection-utils';

export function useAblyChatContextProvider(onMessageReceived: OnMessageReceivesCallback): ChatContextType {
  const connectionRef = useRef<RealtimeClient | undefined>();
  const activeChannelsRef = useRef<Set<string>>();
  const dispatch = useDispatch();

  const checkAndSubscribeToChannel = (candidateChannelId: string) => {
    if (!activeChannelsRef.current.has(candidateChannelId)) {
      subscribeToCandidateChannel(connectionRef.current, candidateChannelId, onMessageReceived);
      activeChannelsRef.current.add(candidateChannelId);
    }
  };

  const subscribeToChannels = (client: RealtimeClient) => {
    getAllActiveChannels(client).then((channels) => {
      activeChannelsRef.current = channels;
      channels.forEach((channelId) => subscribeToCandidateChannel(client, channelId, onMessageReceived));

      subscribeToServiceChannel(client, (candidateChannelId) => {
        checkAndSubscribeToChannel(candidateChannelId);
      });
    });
  };

  const connect = (): void => {
    try {
      if (connectionRef?.current) {
        console.log('Chat is already connected');
        return;
      }
      activeChannelsRef.current = new Set();
      const client = initConnection();

      connectionRef.current = client;

      if (client) {
        subscribeToChannels(client);

        // @ts-ignore
        dispatch(loadChatRooms(undefined));
      } else {
        console.log('Unable to connect to chat');
      }
    } catch (err) {
      console.log("Couldn't start Chat connection", err);
      alert("Sorry, Couldn't connect to chat server");
    }
  };

  const disconnect = (): void => {
    try {
      if (isChatConnected()) {
        console.log('\n\nDisconnection from Chat Server \n\n');
        const connectionClient = connectionRef.current;
        if (connectionClient) {
          const serviceChannel = connectionClient.channels.get(SERVICE_CHANNEL_NAME);
          serviceChannel.unsubscribe();
          serviceChannel.detach().then(() => {
            connectionClient.channels.release(serviceChannel.name);
            connectionClient.close();
            connectionRef.current = undefined;
          });
          activeChannelsRef.current = new Set();
        }
      }
    } catch (_) {
      console.log('\n\nError occurred while trying to disconnect from the chat server \n\n');
    }
  };

  const isChatConnected = (): boolean => {
    console.log('checking connection state...', connectionRef.current?.connection?.state);
    return connectionRef.current?.connection?.state === 'connected';
  };

  const sendMessage = (candidateId: number, messageText: string): Promise<any> => {
    console.log('Trying to send a message ', messageText);
    if (isChatConnected()) {
      const candidateChannelId = getCandidateChannelId(candidateId);
      checkAndSubscribeToChannel(candidateChannelId);
      return api.chat.sendMessage({
        messageText,
        candidateId,
      });
    } else {
      return Promise.reject('Not connected!');
    }
  };

  return {
    connect,
    disconnect,
    isChatConnected,
    sendMessage,
  };
}
