import { List, ListProps, TextField } from 'react-admin';
import React from 'react';
import ActionsColumn from '../ActionsColumn';
import { ArchiveToggleButton } from '../Common/buttons/ArchiveToggleButton';
import { commonStyles } from '../CommonStyles';
import { PostPagination } from '../PostPagination';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import CustomDateField from '../Common/fields/CustomDateField';
import { ProjectFilter } from './components/ProjectFilter';
import { ProjectEdit } from './ProjectEdit';
import { ProjectShow } from './ProjectShow';

export const ProjectList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      title="Projects"
      sort={{ field: 'name', order: 'ASC' }}
      filters={<ProjectFilter />}
      actions={<ListActions showAction={{ selectAutoSave: true }} />}
      perPage={50}
      pagination={<PostPagination />}
      sx={commonStyles.raFilterForm}
      filterDefaultValues={{ isActive: true }}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        bulkActionButtons={false}
        editComponent={<ProjectEdit expandView />}
        showComponent={<ProjectShow expandView />}
        expandSingle
      >
        <TextField source="id" label="Project ID" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="client.name" label="Client" />
        <TextField source="address.addressLine1" label="Address" />
        <TextField source="address.city" label="City/Town" />
        <TextField source="address.state" label="State" />
        <TextField source="address.country" label="Country" />
        <CustomDateField source="updatedAt" label="Updated At" sortable />
        <TextField source="updatedBy" label="Updated By" sortable />
        <TextField source="updatedByName" label="Updated By Name" sortable />

        <ActionsColumn label="Actions" customDeleteButton={<ArchiveToggleButton />} />
      </DatagridConfigurableRbac>
    </List>
  );
};
