import React, { Fragment, ReactElement } from 'react';
import { RecordRepresentation, useResourceDefinition } from 'react-admin';
import { useResourceContext } from 'ra-core';

interface RecordInfoProps {
  title?: string;
}

const RecordInfo: React.FC<RecordInfoProps> = (): ReactElement => {
  const resource = useResourceContext();
  const { options } = useResourceDefinition({ resource });

  const pageTitle = options?.label || resource;

  return (
    <Fragment>
      {pageTitle}: <RecordRepresentation />
    </Fragment>
  );
};

export default RecordInfo;
