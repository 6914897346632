import Box from '@mui/material/Box';
import React from 'react';
import { EditProps, maxLength, NumberInput, required, TextInput, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import { RESOURCE_CLIENT_COMMENTS, RESOURCE_CLIENT_DOCUMENTS } from '../../provider/dataProvider/constants';
import { ClientContactList } from '../ClientContacts/ClientContactList';
import { CommentsTab } from '../Comment/CommentsTab';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import { UploadDocument } from '../Common/UploadDocument/UploadDocument';
import { commonStyles } from '../CommonStyles';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { CityInput } from '../Shared/AddressInputs/CityInput';
import { CountryInput } from '../Shared/AddressInputs/CountryInput';
import { StateInput } from '../Shared/AddressInputs/StateInput';
import { actionPermissions } from '../Permissions/constants';
import { useCanAccess } from '../../hooks/useCanAccess';
import { DocumentsTab } from '../Shared/DocumentsTab';
import CustomDateInputV3 from '../CustomDateInputV3';
import { ProjectsTab } from './tabs/ProjectsTab';
import { IndustriesTab } from './tabs/IndustriesTab';

export const CLIENT_CONTACTS_TAB_IND = 2;

export interface ClientEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation?: boolean;
  expandView?: boolean;
}

export const ClientEdit: React.FC<ClientEditProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();
  const clientId = id || record?.id;
  const showActions = useCanAccess(actionPermissions.edit);

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab label="Client">
        <Box sx={commonStyles.flexBox}>
          <NumberInput source="id" label="Client ID" sx={commonStyles.flexBoxItem} readOnly />
          <TextInput source="name" validate={[required(), maxLength(120)]} sx={commonStyles.flexBoxItem} />
          <TextInput source="address.phone" label="Phone" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="address.address"
            label="Address"
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxLongItem}
          />
          <TextInput source="address.zip" label="zip" validate={[maxLength(50)]} sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <CountryInput sx={commonStyles.flexBoxItem} source="address.country" />
          <StateInput sx={commonStyles.flexBoxItem} source="address.state" countryFieldName="address.country" />
          <CityInput sx={commonStyles.flexBoxItem} source="address.city" stateFieldName="address.state" />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <CustomDateInputV3 source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} disabled />
        </Box>
      </LazyFormTab>

      <LazyFormTab label="Projects">
        <ProjectsTab clientId={clientId} withActions />
      </LazyFormTab>

      <LazyFormTab label="Contacts">
        <ClientContactList clientId={clientId} withActions={showActions} />
      </LazyFormTab>

      <LazyFormTab label="Comments">
        <CommentsTab reference={RESOURCE_CLIENT_COMMENTS} withActions={showActions} />
      </LazyFormTab>

      <LazyFormTab label="Documents">
        <DocumentsTab
          reference={RESOURCE_CLIENT_DOCUMENTS}
          showMode={!showActions}
          footerActions={
            <UploadDocument
              idField="clientId"
              idValue={clientId}
              resource={RESOURCE_CLIENT_DOCUMENTS}
              documentTypes={[{ id: 'general', name: 'General' }]}
            />
          }
        />
      </LazyFormTab>

      <LazyFormTab label="Industries">
        <Box sx={{ width: '40%' }}>
          <IndustriesTab clientId={clientId} withActions />
        </Box>
      </LazyFormTab>
    </TabbedEditForm>
  );
};
