import Box from '@mui/material/Box';
import React from 'react';
import { BooleanInput, email, maxLength, NumberInput, required, TextInput } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import CustomDateInputV3 from '../../CustomDateInputV3';

export const HeaderTab = () => {
  return (
    <>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="id" label="Candidate ID" sx={commonStyles.flexBoxItem} readOnly />
        <TextInput source="title" validate={[required(), maxLength(50)]} sx={commonStyles.flexBoxItem} />
        <TextInput source="firstName" validate={[required(), maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <TextInput source="lastName" validate={[required(), maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <TextInput source="nickName" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="personalEmail"
          type="email"
          sx={commonStyles.flexBoxItem}
          validate={[required(), email(), maxLength(255)]}
        />
        <TextInput source="workEmail" type="email" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <TextInput source="mobile" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <TextInput source="homePhone" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <TextInput source="otherPhone" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput source="birthday" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <BooleanInput source="doNotHire" sx={commonStyles.flexBoxItem} />
        <BooleanInput source="hasMilitaryExperience" sx={commonStyles.flexBoxItem} />
        <BooleanInput source="completedWizard" label="Completed Wizard" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="referredBy" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInputV3 label="Availability Date" source="availabilityDate" sx={commonStyles.flexBoxItem} />
        <CustomDateInputV3
          source="availabilityDateLastUpdated"
          label="Availability last updated"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInputV3
          source="lastLoginOnMobileAppDate"
          label="Last login in mobile app"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInputV3 source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} disabled />
      </Box>
    </>
  );
};
