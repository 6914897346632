import React from 'react';
import { useRecordContext } from 'react-admin';

export const PermissionRoleTitle = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <span>
      Role Permission {record.hrRole.name}: {record.action} {record.resource}
    </span>
  );
};
