import { FC } from 'react';
import { AutocompleteArrayInput, AutocompleteArrayInputProps, ReferenceArrayInput } from 'react-admin';
import { RESOURCE_ROLE } from '../../provider/dataProvider/constants';
import { ROLE_CANDIDATE_ID } from '../../provider/constants';

type RolesReferenceArrayInputProps = Omit<
  AutocompleteArrayInputProps,
  'children' | 'source' | 'optionText' | 'optionValue'
>;

export const RolesReferenceArrayInput: FC<RolesReferenceArrayInputProps> = (props) => {
  return (
    <ReferenceArrayInput
      source="roles"
      reference={RESOURCE_ROLE}
      perPage={300}
      sort={{ field: 'name', order: 'ASC' }}
      filter={{ id_neq: ROLE_CANDIDATE_ID }}
    >
      <AutocompleteArrayInput label="Roles" {...props} />
    </ReferenceArrayInput>
  );
};
