import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { BooleanField, Labeled, RecordContextProvider, TextField, useGetOne, useRecordContext } from 'react-admin';
import { RESOURCE_CLIENT, RESOURCE_JOB, RESOURCE_PROJECT } from '../../../provider/dataProvider/constants';
import { commonStyles } from '../../CommonStyles';
import LinkField from '../../LinkField';
import CustomDateField from '../../Common/fields/CustomDateField';

type JobInfoTabProps = {
  editMode?: boolean;
};

export const JobInfoTab: FC<JobInfoTabProps> = ({ editMode = false }) => {
  const record = useRecordContext();
  const { data, isLoading } = useGetOne(RESOURCE_JOB, { id: record.jobId });

  return (
    <Box>
      {!isLoading && (
        <RecordContextProvider value={data}>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField label="Job ID" source="id" />
            </Labeled>

            <LinkField resource={RESOURCE_JOB} source="id" showMode={!editMode}>
              <Labeled sx={commonStyles.flexBoxItem}>
                <TextField source="jobTitle" label="Job Title" />
              </Labeled>
            </LinkField>
            <LinkField resource={RESOURCE_CLIENT} source="clientId" showMode={!editMode}>
              <Labeled sx={commonStyles.flexBoxItem}>
                <TextField source="client.name" />
              </Labeled>
            </LinkField>
            <LinkField resource={RESOURCE_PROJECT} source="projectId" showMode={!editMode}>
              <Labeled sx={commonStyles.flexBoxItem}>
                <TextField source="project.name" />
              </Labeled>
            </LinkField>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="numberOfPositions" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <CustomDateField source="startDate" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <CustomDateField source="endDate" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="region" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <BooleanField source="computerRequired" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <BooleanField source="emailRequired" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="perDiem" emptyText="0" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="msp" emptyText="0" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="straightTimeBillRate" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="overtimeBillRate" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="doubleTimeBillRate" emptyText="0" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="straightTimePayRate" emptyText="0" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="overtimePayRate" emptyText="0" />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="doubleTimePayRate" emptyText="0" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxFullWidthItem}>
              <TextField source="description" />
            </Labeled>
          </Box>
        </RecordContextProvider>
      )}
    </Box>
  );
};
