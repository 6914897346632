import { AxiosResponse } from 'axios';
import { GetManyReferenceParams } from 'ra-core/src/types';
import api, { GetListV2Filter, GetListV2Params } from './api';
import { SortData } from './types';
import { capitalizeFirstLetter, getAPIErrorMessage, isNotEmpty } from './UtilityFunctions';

export function sanitizeEmptyFields(data: object, fieldList: string[]) {
  const retVal = { ...data };
  fieldList.forEach((field) => {
    if (typeof retVal[field] === 'string' && retVal[field] === '') {
      retVal[field] = null;
    }
  });
  return retVal;
}

const sortDocuments = (originalData: any[] = [], sort?: SortData) => {
  const data = [...originalData];

  if (!sort) {
    // Default ASC sorting by document type
    return data.sort((a, b) => {
      const aValue = a.documentType;
      const bValue = b.documentType;

      return aValue.localeCompare(bValue);
    });
  }

  return data.sort((a, b) => {
    const aValue = a[sort.field];
    const bValue = b[sort.field];

    return sort.order === 'ASC' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });
};

export async function getDocuments(apiFn, Id, sort?: SortData) {
  try {
    const response: AxiosResponse = await apiFn(Id);
    if (response.status === 200) {
      const { data } = response;

      if (data && Array.isArray(data.model)) {
        const retVal = [];
        for (let i = 0; i < data.model.length; i++) {
          const url = await getDocumentUrl(data.model[i].id);
          retVal.push({ ...data.model[i], url });
        }

        const sortedData = sortDocuments(retVal, sort);

        return Promise.resolve({
          data: sortedData,
          total: sortedData.length,
        });
      }
    }
    return Promise.reject(`Couldn't get candidate documents(${Id})`);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getDocumentUrl(documentId) {
  try {
    const response = await api.documents.getUrl(documentId);
    const { data } = response;
    if (isNotEmpty(data) && data.includes('https')) {
      return data;
    }
  } catch (err) {
    console.error(err);
  }
  return undefined;
}

export const getFieldFilters = (filter?: object, array = ['searchTerm', 'ids']) => {
  const fieldFilters: GetListV2Filter[] = [];

  if (filter) {
    Object.keys(filter)
      .filter((filterName) => !array.includes(filterName) && typeof filter[filterName] === 'object')
      .forEach((filterName) => fieldFilters.push(filter[filterName]));
  }

  return fieldFilters;
};

type Options = {
  transformData?: (data: any) => any;
  transformTotal?: (data: any) => number;
  successCodes?: number[];
};

export const processApiCall = async <T,>(
  apiMethod: (...params: T[]) => Promise<any>,
  options: Options = {},
  ...params: T[]
) => {
  const { transformData, transformTotal, successCodes } = options;

  try {
    const response: AxiosResponse = await apiMethod(...params);

    if (response.status === 200 || successCodes?.includes(response.status)) {
      const { data } = response;
      const updatedData = transformData?.(data) || data;
      const total = transformTotal?.(data) || data.length;

      return Promise.resolve({ data: updatedData, total: total });
    } else {
      return Promise.reject(`Api returned ${response.status}`);
    }
  } catch (err) {
    return getAPIErrorMessage(err);
  }
};

export function convertToFreeTextSearch(searchTerm?: string) {
  if (!searchTerm) {
    return searchTerm;
  }

  let updatedSearchTerm = searchTerm.trim();
  if (!updatedSearchTerm.includes(' ') && !updatedSearchTerm.endsWith('*')) {
    updatedSearchTerm += '*';
  }
  return updatedSearchTerm;
}

export const convertManyReferenceParamsToListParams = (manyRefParam: GetManyReferenceParams): GetListV2Params => {
  const searchTerm = convertToFreeTextSearch(manyRefParam.filter?.searchTerm);
  const sortBy = capitalizeFirstLetter(manyRefParam?.sort?.field);
  const sortDirection = manyRefParam?.sort?.order;
  const fieldFilters: GetListV2Filter[] = getFieldFilters(manyRefParam.filter, ['searchTerm', 'ids', 'includeFields']);

  return {
    pageNumber: manyRefParam.pagination.page,
    pageSize: manyRefParam.pagination.perPage,
    searchTerm,
    sortBy,
    sortDirection,
    fieldFilters,
    ids: Array.isArray(manyRefParam.filter['ids']) ? manyRefParam.filter['ids'] : undefined,
    includeFields: Array.isArray(manyRefParam.filter['includeFields'])
      ? manyRefParam.filter['includeFields']
      : undefined,
  };
};
