import { RowForm } from '@react-admin/ra-editable-datagrid';
import { RowFormProps } from '@react-admin/ra-editable-datagrid/src/RowForm';
import React, { FC, useEffect, useState } from 'react';
import { AutocompleteInput, maxLength, required, TextInput, useRecordContext } from 'react-admin';
import { CreateItem } from '../CustomAutoComplete/CustomAutoCompleteLookup';
import { CityInput } from '../Shared/AddressInputs/CityInput';
import { CountryInput } from '../Shared/AddressInputs/CountryInput';
import { StateInput } from '../Shared/AddressInputs/StateInput';

type AddressRowFormProps = Omit<RowFormProps, 'children'> & {
  hasUnitNumber?: boolean;
  hasAddressType?: boolean;
  defaultValues?: { [key: string]: any };
};

const AddressRowForm: FC<AddressRowFormProps> = ({ hasUnitNumber, hasAddressType, ...rest }) => {
  const record = useRecordContext();

  const [addressTypes, setAddressTypes] = useState([
    { id: 'Home', name: 'Home' },
    {
      id: 'Work',
      name: 'Work',
    },
    { id: 'Default', name: 'Default' },
  ]);
  useEffect(() => {
    if (record?.addressType) {
      const found = addressTypes.find((add) => add.id === record?.addressType);
      if (found === undefined) {
        setAddressTypes([{ id: record?.addressType, name: record?.addressType }, ...addressTypes]);
      }
    }
  }, [record?.addressType]);

  return (
    <RowForm {...rest}>
      {hasAddressType && (
        <AutocompleteInput
          variant="outlined"
          optionValue="name"
          optionText="name"
          source="addressType"
          choices={addressTypes}
          create={
            <CreateItem
              onItemCreate={(item) => {
                setAddressTypes([...addressTypes, { id: item, name: item }]);
              }}
            />
          }
        />
      )}

      <TextInput source="address" variant="outlined" validate={[required(), maxLength(255)]} fullWidth />
      {hasUnitNumber && <TextInput source="unitNumber" validate={[maxLength(50)]} variant="outlined" />}

      <CountryInput source="country" />
      <StateInput source="state" countryFieldName="country" />
      <CityInput source="city" stateFieldName="state" />

      <TextInput source="zip" variant="outlined" validate={[required(), maxLength(50)]} label="zip" />
    </RowForm>
  );
};

export default AddressRowForm;
