/* eslint-disable prettier/prettier */
import { AxiosResponse } from 'axios';
import api from '../api';
import {
  RESOURCE_CANDIDATE_CLEARANCES,
  RESOURCE_CANDIDATE_CLIENT_EXPERIENCE,
  RESOURCE_CANDIDATE_COMMENTS,
  RESOURCE_CANDIDATE_MEMBERSHIPS,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE,
  RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_CANDIDATE_PROJECT_EXPERIENCE,
  RESOURCE_CANDIDATE_SKILLS,
  RESOURCE_CANDIDATE_SUBINDUSTRIES,
  RESOURCE_CANDIDATE_WORK_EXPERIENCE,
  RESOURCE_CLIENT_COMMENTS,
  RESOURCE_CLIENT_DOCUMENTS,
  RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES,
  RESOURCE_JOB_COMMENTS,
  RESOURCE_PLACEMENT_COMMENTS,
  RESOURCE_PLACEMENT_DOCUMENTS,
  RESOURCE_PROJECT_COMMENTS,
  RESOURCE_PROJECT_DOCUMENTS,
  RESOURCE_REPORT,
  RESOURCE_V3_CANDIDATE_ADDRESS,
  RESOURCE_V3_JOB_ADDRESS,
} from './constants';

type ApiFunction = (...params: any) => Promise<AxiosResponse>;
type MatrixType = Record<
  string,
  {
    get?: ApiFunction;
    create?: ApiFunction;
    delete?: ApiFunction;
    getList?: ApiFunction;
    update?: ApiFunction;
    getMany?: ApiFunction;
  }
>;

export const apiEndpointMatrix: MatrixType = {
  [RESOURCE_V3_CANDIDATE_ADDRESS]: { getList: api.candidates.getAddresses, create: api.candidates.createAddress, delete: api.candidates.deleteAddress, update: api.candidates.updateAddress },
  [RESOURCE_V3_JOB_ADDRESS]: { getList: api.jobs.getAddresses, create: api.jobs.createAddress, delete: api.jobs.deleteAddress, update: api.jobs.updateAddress },

  [RESOURCE_CANDIDATE_CLEARANCES]: { getList: api.candidates.getClearances, create: api.candidates.createClearance, delete: api.candidates.deleteClearance },
  [RESOURCE_CANDIDATE_CLIENT_EXPERIENCE]: { getList: api.candidates.getClientExperiences, create: api.candidates.createClientExperience, delete: api.candidates.deleteClientExperience },
  [RESOURCE_CANDIDATE_COMMENTS]: { getList: api.candidates.getComments, create: api.candidates.createComment, delete: api.candidates.deleteComment },
  [RESOURCE_CANDIDATE_MEMBERSHIPS]: { getList: api.candidates.getMemberships, create: api.candidates.createMembership, delete: api.candidates.deleteMembership },
  [RESOURCE_CANDIDATE_OEM_EXPERIENCE]: { getList: api.candidates.getOemExperience, create: api.candidates.createOemExperience, delete: api.candidates.deleteOemExperience },
  [RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE]: { getList: api.candidates.getOemExperienceType, create: api.candidates.createOemExperienceType, delete: api.candidates.deleteOemExperienceType },
  [RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE]: { getList: api.candidates.getPrimaryWorkExperiences, create: api.candidates.createPrimaryWorkExperience, delete: api.candidates.deletePrimaryWorkExperience },
  [RESOURCE_CANDIDATE_PROJECT_EXPERIENCE]: { getList: api.candidates.getProjectExperiences, create: api.candidates.createProjectExperience, delete: api.candidates.deleteProjectExperience },
  [RESOURCE_CANDIDATE_SKILLS]: { getList: api.candidates.getSkills, create: api.candidates.createSkill, delete: api.candidates.deleteSkill },
  [RESOURCE_CANDIDATE_SUBINDUSTRIES]: { getList: api.candidates.getSubindustries, create: api.candidates.createSubindustry, delete: api.candidates.deleteSubindustry },
  [RESOURCE_CANDIDATE_WORK_EXPERIENCE]: { getList: api.candidates.getWorkExperiences, create: api.candidates.createWorkExperience, delete: api.candidates.deleteWorkExperience },

  [RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES]: { getList: api.clients.getHighLevelIndustries, create: api.clients.createHighLevelIndustry, delete: api.clients.deleteHighLevelIndustry },

  [RESOURCE_JOB_COMMENTS]: { getList: api.jobs.getComments, create: api.jobs.createComment, delete: api.jobs.deleteComment },
  [RESOURCE_PLACEMENT_COMMENTS]: { getList: api.placements.getComments, create: api.placements.createComment, delete: api.placements.deleteComment },
  [RESOURCE_CLIENT_COMMENTS]: { getList: api.clients.getComments, create: api.clients.createComment, delete: api.clients.deleteComment },
  [RESOURCE_PROJECT_COMMENTS]: { getList: api.projects.getComments, create: api.projects.createComment, delete: api.projects.deleteComment },

  [RESOURCE_CLIENT_DOCUMENTS]: { getList: api.clients.getDocuments, create: api.clients.uploadDocument, delete: api.clients.deleteDocument },
  [RESOURCE_PLACEMENT_DOCUMENTS]: { getList: api.placements.getDocuments },
  [RESOURCE_PROJECT_DOCUMENTS]: { getList: api.projects.getDocuments, create: api.projects.uploadDocument, delete: api.projects.deleteDocument },

  [RESOURCE_REPORT]: { get: api.reports.getOne },
};
