import { FC } from 'react';
import { email, maxLength, required, TextInput } from 'react-admin';
import { Box, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { commonStyles } from '../CommonStyles';
import { RolesReferenceArrayInput } from './RolesReferenceArrayInput';
import { ClientsReferenceArrayInput } from './ClientsReferenceArrayInput';

type UserFormProps = {
  createMode?: boolean;
};

export const UserForm: FC<UserFormProps> = ({ createMode = false }) => {
  return (
    <Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput source="title" label="Title" validate={[required(), maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <TextInput
          source="firstName"
          label="First Name"
          validate={[required(), maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="lastName"
          label="Last Name"
          validate={[required(), maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="email"
          label="Email"
          type="email"
          validate={[required(), email(), maxLength(255)]}
          readOnly={!createMode}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="phone"
          label="Phone Number"
          type="tel"
          validate={[required(), maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
        {createMode && (
          <Box sx={{ ...commonStyles.flexBoxItem, display: 'flex', alignItems: 'flex-start' }}>
            <TextInput
              source="password"
              label="Password"
              type="password"
              validate={[required(), maxLength(50)]}
              sx={{ flex: 1 }}
            />
            <Tooltip
              title={
                <div>
                  Passwords must be at least 8 characters and must have:
                  <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                    <li>At least one non alphanumeric character</li>
                    <li>At least one uppercase (A-Z)</li>
                    <li>At least one lowercase (a-z)</li>
                    <li>At least one digit (0-9)</li>
                  </ul>
                </div>
              }
            >
              <IconButton size="small" sx={{ mt: 1, ml: 1 }}>
                <InfoIcon color="info" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ClientsReferenceArrayInput sx={commonStyles.flexBoxLongItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <RolesReferenceArrayInput validate={[required()]} sx={commonStyles.flexBoxLongItem} />
      </Box>
    </Box>
  );
};
