import React, { ReactElement } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import './styles.css';

interface CustomDialogProps {
  title: string;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
}

export const PreviewDialog: React.FC<CustomDialogProps> = ({ title, children, open, onClose }): ReactElement => {
  return (
    open && (
      <div id="k-window-wrapper">
        <Window
          title={title}
          onClose={onClose}
          modal
          doubleClickStageChange={false}
          minimizeButton={() => null}
          maximizeButton={() => null}
          initialWidth={850}
          initialHeight={750}
          draggable={false}
          appendTo={null}
        >
          {children}
        </Window>
      </div>
    )
  );
};
