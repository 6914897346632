import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as React from 'react';
import { Button, useRecordContext } from 'react-admin';
import { styles } from '../../Candidate/styles';
import { EmptyIconButtonPlaceholder } from '../EmptyIconButtonPlaceholder/EmptyIconButtonPlaceholder';

type DownloadButtonProps = {
  source: string;
  label?: string;
  sortable?: boolean;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ label, source }) => {
  const record = useRecordContext();

  return record && record[source] ? (
    <a href={record[source]} target="_blank" rel="noreferrer">
      <Button label={label}>
        <CloudDownloadIcon />
      </Button>
    </a>
  ) : (
    <EmptyIconButtonPlaceholder sx={styles.downloadCvButton} />
  );
};

export default DownloadButton;
