import { useQueryClient } from '@tanstack/react-query';
import { useResourceContext } from 'react-admin';
import { DEPENDENT_RESOURCE_MATRIX } from './resourceMatrix';

export const useInvalidateResourceQueryCache = (resourceName?: string, isInvalidateRelatedResources?: boolean) => {
  const resourceContext = useResourceContext();
  const resource = resourceName || resourceContext;

  const queryClient = useQueryClient();

  return async () => {
    await queryClient.invalidateQueries({ queryKey: [resource], exact: false });
    if (isInvalidateRelatedResources) {
      const dependentResources = DEPENDENT_RESOURCE_MATRIX[resource] ?? [];

      const dependentQueries = async (dependentResource: string) => {
        queryClient.removeQueries({ queryKey: [dependentResource], exact: false });
      };

      await Promise.all(dependentResources.map(dependentQueries));
    }
  };
};
