import { BooleanInput, useListContext } from 'react-admin';
import * as React from 'react';

export const PendingCheckboxInput = () => {
  const { filterValues, setFilters } = useListContext();

  const onChange = (e) => {
    const newFilter = {
      isPending: e.target.checked,
    };
    setFilters({ ...filterValues, ...newFilter });
  };

  return (
    <BooleanInput
      label="Pending"
      source="isPending"
      defaultValue={filterValues.isPending}
      onChange={onChange}
      helperText={false}
    />
  );
};
