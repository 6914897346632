import dayjs from 'dayjs';
import { ValidationErrorMessage, Validator } from 'react-admin';
import { DEFAULT_DATE_FORMAT } from '../provider/constants';

export const datePickerErrors = ({
  error,
  maxDate,
  minDate,
}: {
  error: string;
  maxDate: null | string;
  minDate: null | string;
}): ValidationErrorMessage => {
  const errors = {
    invalidDate: 'Invalid Date',
    maxDate: maxDate ? `The date must not exceed ${dayjs(maxDate).format(DEFAULT_DATE_FORMAT)}` : undefined,
    minDate: minDate ? `The date must be greater ${dayjs(minDate).format(DEFAULT_DATE_FORMAT)}` : undefined,
  };

  return errors[error];
};

export const validateMaxDate =
  (maxDate: string): Validator =>
  (value): ValidationErrorMessage => {
    if (dayjs(value)?.isAfter(maxDate)) {
      return datePickerErrors({ error: 'maxDate', maxDate, minDate: null });
    }
    return '';
  };

export const validateMinDate =
  (minDate: string): Validator =>
  (value): ValidationErrorMessage => {
    if (dayjs(value)?.isBefore(dayjs(minDate).subtract(1, 'd'))) {
      return datePickerErrors({ error: 'minDate', maxDate: null, minDate });
    }
    return '';
  };

export const validateEndDate = (endDate: string, { startDate }: { startDate: string }) => {
  if (dayjs(endDate)?.isBefore(dayjs(startDate))) {
    return datePickerErrors({ error: 'minDate', maxDate: null, minDate: startDate });
  }
  return '';
};
