import { List, ListProps, TextField } from 'react-admin';
import React from 'react';
import ActionsColumn from '../ActionsColumn';
import { ArchiveToggleButton } from '../Common/buttons/ArchiveToggleButton';
import { commonStyles } from '../CommonStyles';
import LinkField from '../LinkField';
import { PostPagination } from '../PostPagination';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import CustomDateField from '../Common/fields/CustomDateField';
import { ClientEdit } from './ClientEdit';
import { ClientShow } from './ClientShow';
import { ClientFilter } from './components/ClientFilter';

export const ClientList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Clients"
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      filterDefaultValues={{ isActive: true }}
      filters={<ClientFilter />}
      actions={<ListActions showAction={{ selectAutoSave: true }} />}
      perPage={50}
      pagination={<PostPagination />}
      sx={commonStyles.raFilterForm}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        editComponent={<ClientEdit expandView />}
        showComponent={<ClientShow expandView />}
        expandSingle
        bulkActionButtons={false}
      >
        <TextField source="id" label="Client ID" sortable />
        <TextField source="name" sortable />
        <LinkField phone source="address.phone" label="Phone" sortable={false}>
          <TextField source="address.phone" label="Phone" sortable={false} />
        </LinkField>
        <TextField source="address.addressLine1" label="Address" sortable />
        <TextField source="address.city" label="City/Town" sortable />
        <TextField source="address.state" label="State" sortable />
        <TextField source="address.country" label="Country" sortable />
        <CustomDateField source="updatedAt" label="Updated At" sortable />
        <TextField source="updatedBy" label="Updated By" sortable />
        <TextField source="updatedByName" label="Updated By Name" sortable />

        <ActionsColumn label="Actions" customDeleteButton={<ArchiveToggleButton />} />
      </DatagridConfigurableRbac>
    </List>
  );
};
