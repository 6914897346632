import React from 'react';
import { SimpleForm } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import { EditInDialogButton } from '@react-admin/ra-form-layout';
import { EditInDialogButtonProps } from '@react-admin/ra-form-layout/src/forms/dialog-form/EditInDialogButton';
import { RESOURCE_CLIENT } from '../../provider/dataProvider/constants';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { ClientContactForm } from './ClientContactForm';

export const ClientContactEditInDialog: React.FC<EditInDialogButtonProps> = (props) => {
  return (
    <EditInDialogButton
      label=""
      mutationMode="pessimistic"
      fullWidth
      maxWidth="lg"
      transform={(data) => {
        const sanitized = { ...data };
        if (data?.address) {
          if (isEmpty(data.address?.alternativeEmail)) {
            delete sanitized.address.alternativeEmail;
          }

          if (isEmpty(data.address?.email)) {
            delete sanitized.address.email;
          }
        }
        return sanitized;
      }}
    >
      <SimpleForm toolbar={<FooterToolbar cancelButtonResource={RESOURCE_CLIENT} />}>
        <ClientContactForm />
      </SimpleForm>
    </EditInDialogButton>
  );
};
