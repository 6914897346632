import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { email, maxLength, required, TextInput } from 'react-admin';
import { commonStyles } from '../CommonStyles';
import { CityInput } from '../Shared/AddressInputs/CityInput';
import { CountryInput } from '../Shared/AddressInputs/CountryInput';
import { StateInput } from '../Shared/AddressInputs/StateInput';

export const ProjectContactForm: FC = () => {
  return (
    <Box sx={commonStyles.flexVertical}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="clientContact.firstName"
          label="First Name"
          validate={[required(), maxLength(120)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="clientContact.lastName"
          label="Last Name"
          validate={[required(), maxLength(120)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="clientContact.jobTitle"
          label="Job Title"
          validate={[maxLength(120)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="clientContact.address.email"
          label="Email"
          validate={[email(), maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="clientContact.address.phone"
          label="Phone"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="clientContact.address.alternativeEmail"
          label="Alternative Email"
          validate={[email(), maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="clientContact.address.alternativePhone"
          label="Alternative Phone"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="clientContact.address.addressLine1"
          label="Address"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CountryInput sx={commonStyles.flexBoxItem} source="clientContact.address.country" />
        <StateInput
          sx={commonStyles.flexBoxItem}
          source="clientContact.address.state"
          countryFieldName="clientContact.address.country"
        />
        <CityInput
          sx={commonStyles.flexBoxItem}
          source="clientContact.address.city"
          stateFieldName="clientContact.address.state"
        />
      </Box>
    </Box>
  );
};
