import Box from '@mui/material/Box';
import { Identifier } from 'ra-core';
import React from 'react';
import {
  RESOURCE_CANDIDATE_SKILLS,
  RESOURCE_CANDIDATE_SUBINDUSTRIES,
  RESOURCE_LOOKUP_SKILL,
  RESOURCE_LOOKUP_SUBINDUSTRY,
} from '../../../provider/dataProvider/constants';
import { styles } from '../styles';
import { SimpleDatagrid } from '../../Common/SimpleDatagrid/SimpleDatagrid';

type SkillsIndustriesTabProps = {
  candidateId: Identifier;
  withActions?: boolean;
};

export const SkillsIndustriesTab: React.FC<SkillsIndustriesTabProps> = ({ withActions = false, candidateId }) => {
  return (
    <Box sx={styles.tabContentContainer}>
      <SimpleDatagrid
        resource={RESOURCE_CANDIDATE_SKILLS}
        lookupResource={RESOURCE_LOOKUP_SKILL}
        label="Skill"
        defaultValues={{ candidateId }}
        withActions={withActions}
      />
      <SimpleDatagrid
        resource={RESOURCE_CANDIDATE_SUBINDUSTRIES}
        lookupResource={RESOURCE_LOOKUP_SUBINDUSTRY}
        label="Industry"
        defaultValues={{ candidateId }}
        withActions={withActions}
      />
    </Box>
  );
};
