import { AuthProvider } from 'react-admin';
import { AxiosError } from 'axios';
import { LOCAL_STORE_VERSION, LOCAL_STORE_VERSION_KEY } from '../App';
import api from './api';
import { DEFAULT_AVATAR } from './constants';
import { getAPIErrorMessage, isEmpty, tryJsonParse } from './UtilityFunctions';
import { ROUTE_FIELD_MANAGER_RESET_PASSWORD } from './dataProvider/constants';

export const AUTH_KEY = 'userV1';
let isPostLoginCalled = false;
let cachedUser = undefined;

export function getUser() {
  if (cachedUser) return cachedUser;
  if (window.location?.hash.includes(ROUTE_FIELD_MANAGER_RESET_PASSWORD)) {
    localStorage.removeItem(AUTH_KEY);
    return undefined;
  }

  try {
    const userContextStr = localStorage.getItem(AUTH_KEY);
    const user = userContextStr ? JSON.parse(localStorage.getItem(AUTH_KEY)) : undefined;
    if (user) {
      cachedUser = user;
      return user;
    }
    // eslint-disable-next-line no-empty
  } catch (_) {
    cachedUser = undefined;
  }
  return undefined;
}

export function setUser(user) {
  localStorage.setItem(AUTH_KEY, JSON.stringify(user));
  cachedUser = undefined;
}

const onPostLoggedIn = async (userData) => {
  if (!isPostLoginCalled) {
    isPostLoginCalled = true;

    //Sync userSettings
    //console.log(" onPostLoggedIn userData",userData)
    if (userData && userData?.userSettings) {
      const userSettings = tryJsonParse(userData.userSettings);
      if (
        isEmpty(userSettings[LOCAL_STORE_VERSION_KEY]) ||
        userSettings[LOCAL_STORE_VERSION_KEY] === LOCAL_STORE_VERSION
      ) {
        Object.keys(userSettings).forEach((key) => {
          //console.log("Setting Local St ",key, userSettings[key], typeof userSettings[key]);
          if (typeof userSettings[key] !== 'string') {
            localStorage.setItem(key, JSON.stringify(userSettings[key]));
          } else {
            localStorage.setItem(key, userSettings[key]);
          }
        });
      } else {
        console.log(
          'User Settings version keys are different, discarded',
          userSettings[LOCAL_STORE_VERSION_KEY],
          LOCAL_STORE_VERSION
        );
      }
    }
  }
};

const onPostLoggedOut = async () => {
  localStorage.removeItem(AUTH_KEY);
  isPostLoginCalled = false;
  cachedUser = undefined;
};

export const updateProfilePicture = async (record) => {
  if (record.fieldManagerId && record.avatar) {
    const formData = new FormData();
    formData.append('File', record.avatar.rawFile);
    const response = await api.users.uploadImage(record.fieldManagerId, formData);
    if (response.status === 200) {
      const responseUserDetails = await api.auth.getCurrentUserDetails();
      if (responseUserDetails.status === 200) {
        localStorage.setItem(AUTH_KEY, JSON.stringify({ ...getUser(), ...responseUserDetails.data }));
        cachedUser = undefined;
        return true;
      }
    }
  }
  return false;
};

export const updateFieldManagerProfile = async (record) => {
  if (record.fieldManagerId) {
    const responsePhone = await api.auth.fieldManagerUpdateProfile(record);
    if (responsePhone.status === 200) {
      const responseUserDetails = await api.auth.getCurrentUserDetails();
      if (responseUserDetails.status === 200) {
        localStorage.setItem(AUTH_KEY, JSON.stringify({ ...getUser(), ...responseUserDetails.data }));
        cachedUser = undefined;
        return true;
      }
    }
  }
  return false;
};
export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const loginResponse = await api.auth.login({ email: username, password });
      const { data } = loginResponse;
      if (loginResponse.status === 200) {
        const responseUserDetails = await api.auth.getCurrentUserDetails();
        if (responseUserDetails.status === 200) {
          const userData = { ...data, ...responseUserDetails.data };
          localStorage.setItem(AUTH_KEY, JSON.stringify(userData));
          await onPostLoggedIn(userData);
          return Promise.resolve();
        }
      }
    } catch (e) {
      console.log(e);
      return getAPIErrorMessage(e, 'Invalid username or password.');
    }
  },
  logout: async () => {
    try {
      const response = await api.auth.logout();
      if (response.status === 200) {
        await onPostLoggedOut();
      }
    } catch (e) {
      console.error(e);
      await onPostLoggedOut();
    }
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: async (err) => {
    let isReject = false;

    // Check for HTTP 401 Unauthorized
    if (err?.status === 401 || err?.response?.status === 401) {
      isReject = true;
    }

    // Keep existing string error checks
    if (typeof err === 'string') {
      const errStr = err.toLowerCase();
      if (errStr.includes('forbidden') || errStr.includes('unauthorized')) {
        isReject = true;
      }
    }

    if (isReject) {
      await onPostLoggedOut();
      return Promise.reject('Please logout and login');
    }
    return Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const user = getUser();
    //FIXME check user is authenticated correctly
    // if (user && user.jwtToken) {
    if (user) {
      await onPostLoggedIn(undefined);
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const user = getUser();
    if (!user) {
      return Promise.reject('Login first, please!');
    }
    return Promise.resolve(user.effectivePermissions);
  },
  getRoles: () => Promise.reject('Not supported yet!'),
  getIdentity: () => {
    const user = getUser();
    if (user) {
      return Promise.resolve({
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        title: user.title,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        avatar: user.avatarUrl || DEFAULT_AVATAR,
        fieldManagerId: user.fieldManagerId,
      });
    }
    return Promise.reject("Couldn't get user details");
  },
};
