import Box from '@mui/material/Box';
import React from 'react';
import { EditProps, maxLength, NumberInput, required, TextInput, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { RESOURCE_PROJECT_COMMENTS, RESOURCE_PROJECT_DOCUMENTS } from '../../provider/dataProvider/constants';
import { CommentsTab } from '../Comment/CommentsTab';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import { UploadDocument } from '../Common/UploadDocument/UploadDocument';
import { commonStyles } from '../CommonStyles';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { CityInput } from '../Shared/AddressInputs/CityInput';
import { CountryInput } from '../Shared/AddressInputs/CountryInput';
import { StateInput } from '../Shared/AddressInputs/StateInput';
import { ClientSelect } from '../Shared/ClientSelect';
import { useCanAccess } from '../../hooks/useCanAccess';
import { actionPermissions } from '../Permissions/constants';
import { DocumentsTab } from '../Shared/DocumentsTab';
import CustomDateInputV3 from '../CustomDateInputV3';
import { ProjectContactList } from '../ProjectContacts/ProjectContactList';
import { JobsTab } from './tabs/JobsTab';
import { IndustriesTab } from './tabs/IndustriesTab';

export interface ProjectEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation?: boolean;
  expandView?: boolean;
}

export const ProjectEdit: React.FC<ProjectEditProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();
  const projectId = id || record?.id;
  const showActions = useCanAccess(actionPermissions.edit);

  return (
    <TabbedEditForm {...props}>
      <LazyFormTab label="Project">
        <Box sx={commonStyles.flexBox}>
          <NumberInput source="id" label="Project ID" sx={commonStyles.flexBoxItem} readOnly />
          <TextInput source="name" validate={[required()]} sx={commonStyles.flexBoxItem} />
          <ClientSelect />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="description"
            fullWidth
            validate={[required()]}
            multiline
            sx={commonStyles.flexBoxLongItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="address.addressLine1"
            label="Address"
            validate={[maxLength(255)]}
            fullWidth
            sx={commonStyles.flexBoxLongItem}
          />
          <TextInput source="address.zip" label="zip" validate={[maxLength(50)]} sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <CountryInput sx={commonStyles.flexBoxItem} source="address.country" />
          <StateInput sx={commonStyles.flexBoxItem} source="address.state" countryFieldName="address.country" />
          <CityInput sx={commonStyles.flexBoxItem} source="address.city" stateFieldName="address.state" />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <CustomDateInputV3 source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} disabled />
        </Box>
      </LazyFormTab>

      <LazyFormTab label="Jobs">
        <JobsTab projectId={projectId} withActions />
      </LazyFormTab>

      <LazyFormTab label="Contacts">
        {projectId && <ProjectContactList projectId={projectId} withActions={showActions} />}
      </LazyFormTab>

      <LazyFormTab label="Comments">
        <CommentsTab reference={RESOURCE_PROJECT_COMMENTS} withActions={showActions} />
      </LazyFormTab>

      <LazyFormTab label="Documents">
        <DocumentsTab
          reference={RESOURCE_PROJECT_DOCUMENTS}
          showMode={!showActions}
          perPage={MAX_RECORD_PER_PAGE}
          footerActions={
            <UploadDocument
              idField="projectId"
              idValue={projectId}
              resource={RESOURCE_PROJECT_DOCUMENTS}
              documentTypes={[{ id: 'general', name: 'General' }]}
            />
          }
        />
      </LazyFormTab>

      <LazyFormTab label="Industries">
        <Box sx={{ width: '40%' }}>
          <IndustriesTab projectId={projectId} withActions={showActions} />
        </Box>
      </LazyFormTab>
    </TabbedEditForm>
  );
};
