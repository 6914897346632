import DashboardIcon from '@mui/icons-material/Dashboard';
import React, { ReactElement } from 'react';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import BusinessIcon from '@mui/icons-material/Business';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import Work from '@mui/icons-material/Work';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListIcon from '@mui/icons-material/List';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  RESOURCE_CANDIDATE,
  RESOURCE_CLIENT,
  RESOURCE_JOB,
  RESOURCE_MESSAGING,
  RESOURCE_PLACEMENT,
  RESOURCE_PROJECT,
  RESOURCE_REPORT,
  RESOURCE_ROLE,
  RESOURCE_USER_FIELD_MANAGER,
  RESOURCE_PERMISSION,
  RESOURCE_FAVORITE,
} from '../../provider/dataProvider/constants';
import MessagingMenuBadge from '../Messaging/MessagingMenu';

export interface ResourceMenuItemData {
  primaryText: string;
  id: string;
  resource: string | string[];
  leftIcon?: ReactElement;
}

export interface ParentMenuItemData {
  id: string;
  name: string;
  label: string;
  icon: ReactElement;
  items: Array<ResourceMenuItemData>;
}

export const menuItems: Array<ResourceMenuItemData | ParentMenuItemData> = [
  {
    primaryText: 'Dashboard',
    id: 'dashboard_menu',
    resource: [RESOURCE_CANDIDATE, RESOURCE_PLACEMENT],
    leftIcon: <DashboardIcon />,
  },
  {
    primaryText: 'Messages',
    resource: RESOURCE_MESSAGING,
    id: 'messages_menu',
    leftIcon: <MessagingMenuBadge />,
  },
  {
    primaryText: 'Candidates',
    resource: RESOURCE_CANDIDATE,
    id: 'candidates_menu',
    leftIcon: <PeopleAlt />,
  },
  {
    primaryText: 'Clients',
    resource: RESOURCE_CLIENT,
    id: 'clients_menu',
    leftIcon: <BusinessIcon />,
  },
  {
    primaryText: 'Projects',
    resource: RESOURCE_PROJECT,
    id: 'projects_menu',
    leftIcon: <ShopTwoIcon />,
  },
  {
    primaryText: 'Jobs',
    resource: RESOURCE_JOB,
    id: 'jobs_menu',
    leftIcon: <Work />,
  },
  {
    primaryText: 'Placements',
    resource: RESOURCE_PLACEMENT,
    id: 'placements_menu',
    leftIcon: <AssignmentTurnedInIcon />,
  },
  {
    primaryText: 'Favorite Lists',
    resource: RESOURCE_FAVORITE,
    id: 'favorite_menu',
    leftIcon: <ListIcon />,
  },
  {
    primaryText: 'Reports',
    resource: RESOURCE_REPORT,
    id: 'reports_menu',
    leftIcon: <AssessmentOutlinedIcon />,
  },
  {
    name: 'global_settings_menu',
    label: 'Global Settings',
    icon: <ManageAccountsIcon />,
    id: 'global_settings_menu',
    items: [
      {
        primaryText: 'Field Managers',
        resource: RESOURCE_USER_FIELD_MANAGER,
        id: 'users_settings_menu',
      },
      {
        primaryText: 'Roles',
        resource: RESOURCE_ROLE,
        id: 'roles_settings_menu',
      },
      {
        primaryText: 'Role Permissions',
        resource: RESOURCE_PERMISSION,
        id: 'role_permissions_settings_menu',
      },
    ],
  },
];
