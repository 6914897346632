import Box from '@mui/material/Box';
import React from 'react';
import { BooleanInput, NumberInput, required, TextInput, useRecordContext } from 'react-admin';
import TextField from '@mui/material/TextField';
import InvertedBooleanInput from '../../Common/inputs/InvertedBooleanInput';
import { commonStyles } from '../../CommonStyles';
import { ClientContactInput } from '../components/ClientContactInput';
import { FieldManagerInput } from '../components/FieldManagerInput';
import { ProjectContactInput } from '../components/ProjectContactInput';
import { validateEndDate } from '../../../utils/validators';
import CustomDateInputV3 from '../../CustomDateInputV3';

export const HeaderTab = () => {
  const record = useRecordContext();

  return (
    <>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="id" label="Placement ID" sx={commonStyles.flexBoxItem} readOnly />
        <TextField
          label="Candidate"
          defaultValue={record?.candidate ? `${record.candidate.firstName} ${record.candidate.lastName}` : ''}
          disabled
          sx={commonStyles.flexBoxItem}
        />
        <TextInput source="job.jobTitle" label="Job Title" sx={commonStyles.flexBoxItem} disabled />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInputV3
          label="Start Date"
          source="startDate"
          sx={commonStyles.flexBoxItem}
          validate={[required()]}
        />
        <CustomDateInputV3
          label="End Date"
          source="endDate"
          sx={commonStyles.flexBoxItem}
          validate={[required(), validateEndDate]}
        />
        <NumberInput source="duration" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInputV3 label="Onboarding Complete" source="onboardingComplete" sx={commonStyles.flexBoxItem} />
        <CustomDateInputV3
          label="Background Check Complete"
          source="backgroundCheckComplete"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInputV3 label="Physical Complete" source="physicalComplete" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInputV3 label="Drug Screen Complete" source="drugScreenComplete" sx={commonStyles.flexBoxItem} />
        <CustomDateInputV3 label="Rev1 Training Complete" source="trainingComplete" sx={commonStyles.flexBoxItem} />
        <CustomDateInputV3
          label="Client Training Complete"
          source="clientTrainingComplete"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="straightTimeBillRate" sx={commonStyles.flexBoxItem} validate={[required()]} />
        <NumberInput source="overtimeBillRate" sx={commonStyles.flexBoxItem} />
        <NumberInput source="doubleTimeBillRate" label="Double time bill rate" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="straightTimePayRate" sx={commonStyles.flexBoxItem} validate={[required()]} />
        <NumberInput source="overtimePayRate" sx={commonStyles.flexBoxItem} />
        <NumberInput source="doubleTimePayRate" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <NumberInput source="perDiem" sx={commonStyles.flexBoxItem} />
        <TextInput source="rotationId" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="rotationStartDate" sx={commonStyles.flexBoxItem} disabled />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <BooleanInput label="Computer Required" source="computerRequired" sx={commonStyles.flexBoxItem} />
        <BooleanInput label="Email Required" source="emailRequired" sx={commonStyles.flexBoxItem} />
        <BooleanInput label="Bypass Client Training" source="bypassClientTraining" sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ClientContactInput sx={commonStyles.flexBoxItem} />
        <ProjectContactInput sx={commonStyles.flexBoxItem} />
        <FieldManagerInput sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInputV3 source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} disabled />
        <TextInput source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} disabled />
        <InvertedBooleanInput source="isActive" label="Archived" sx={commonStyles.flexBoxItem} />
      </Box>
    </>
  );
};
