import { FunctionField } from 'react-admin';
import moment from 'moment';
import { SxProps } from '@mui/material/styles';
import { DEFAULT_DATE_FORMAT } from '../../../provider/constants';

interface CustomDateFieldProps {
  source: string;
  label?: string;
  sortable?: boolean;
  sx?: SxProps;
}

const CustomDateField = (props: CustomDateFieldProps) => {
  const { source, label, sortable, sx } = props;

  return (
    <FunctionField
      source={source}
      label={label}
      sortable={sortable}
      render={(record) => {
        if (!record || !record[source]) return null;
        return moment(record[source]).format(DEFAULT_DATE_FORMAT);
      }}
      sx={sx}
    />
  );
};

export default CustomDateField;
