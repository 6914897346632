import React, { FC } from 'react';
import { Button, useNotify, useRecordContext } from 'react-admin';
import { EditPropsWithLocation } from '../types';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import api from '../../provider/api';
import { useInvalidateResourceQueryCache } from '../../provider/dataProvider/useInvalidateResourceQueryCache';
import { UserForm } from './UserForm';

const PasswordResetButton = () => {
  const notify = useNotify();
  const record = useRecordContext();

  return (
    <React.Fragment>
      <Button
        label="Send Pwd reset link"
        variant="text"
        style={{ marginLeft: '20px' }}
        onClick={async () => {
          try {
            const responseActivate = await api.auth.fieldManagerActivate(record?.id);

            const responseForgotPass = await api.auth.fieldManagerForgotPassword(record?.email);
            if (responseActivate.status != 200) {
              notify(`Account Activate Request failed with ${responseForgotPass.status}`, {
                type: 'warning',
                undoable: false,
              });
              return;
            }

            if (responseForgotPass.status === 200) {
              notify('Password reset link has been sent', {
                type: 'info',
                undoable: false,
              });
            } else {
              notify(`Request failed with ${responseForgotPass.status}`, {
                type: 'warning',
                undoable: false,
              });
            }
          } catch (err) {
            notify(err?.response?.data?.message || 'Request failed, try again!', {
              type: 'warning',
              undoable: false,
            });
          }
        }}
      />
    </React.Fragment>
  );
};

const ActivateButton = () => {
  const notify = useNotify();
  const invalidateQueries = useInvalidateResourceQueryCache(undefined, true);
  const record = useRecordContext();

  if (record?.isActive) {
    return null;
  }

  return (
    <Button
      label="Activate User"
      variant="text"
      style={{ marginLeft: '20px' }}
      onClick={async () => {
        try {
          const response = await api.auth.fieldManagerActivate(record?.id);

          if (response.status === 200) {
            notify('User has been activated', {
              type: 'success',
              undoable: false,
            });
            await invalidateQueries();
          } else {
            notify(`Activation failed with ${response.status}`, {
              type: 'warning',
              undoable: false,
            });
          }
        } catch (err) {
          notify(err?.response?.data?.message || 'Activation failed, try again!', {
            type: 'warning',
            undoable: false,
          });
        }
      }}
    />
  );
};

type UserEditProps = EditPropsWithLocation & {
  expandView?: boolean;
};

export const UserEdit: FC<UserEditProps> = (props) => {
  return (
    <TabbedEditForm
      {...props}
      footerToolbarProps={{
        children: (
          <>
            <PasswordResetButton />
            <ActivateButton />
          </>
        ),
      }}
    >
      <LazyFormTab label="User">
        <UserForm />
      </LazyFormTab>
    </TabbedEditForm>
  );
};
