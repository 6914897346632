import axios from 'axios';
import { Identifier } from 'ra-core/src/types';
import {
  RESOURCE_CANDIDATE_COMMENTS,
  RESOURCE_CLIENT_COMMENTS,
  RESOURCE_JOB_COMMENTS,
  RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_LOOKUP_SKILL,
  RESOURCE_PLACEMENT_COMMENTS,
  RESOURCE_PROJECT_COMMENTS,
  RESOURCE_REPORT,
  RESOURCE_CANDIDATE,
  RESOURCE_PLACEMENT_DOCUMENTS,
  RESOURCE_CLIENT_DOCUMENTS,
  RESOURCE_PROJECT_DOCUMENTS,
  RESOURCE_PLACEMENT,
  RESOURCE_V3_JOB_ADDRESS,
  RESOURCE_V3_CANDIDATE_ADDRESS,
  RESOURCE_CANDIDATE_CLEARANCES,
  RESOURCE_CANDIDATE_SKILLS,
  RESOURCE_USER_FIELD_MANAGER,
  RESOURCE_USER_CANDIDATE,
  RESOURCE_CANDIDATE_SUBINDUSTRIES,
  RESOURCE_CANDIDATE_CLIENT_EXPERIENCE,
  RESOURCE_V3_CLIENT_LIST,
  RESOURCE_V3_CLIENT,
  RESOURCE_V3_CLIENT_HIGH_LEVEL_INDUSTRIES,
  RESOURCE_CANDIDATE_MEMBERSHIPS,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE,
  RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE,
  RESOURCE_CANDIDATE_PROJECT_EXPERIENCE,
  RESOURCE_CANDIDATE_WORK_EXPERIENCE,
} from './dataProvider/constants';

type environmentType = 'local' | 'dev' | 'prod';
const environment: environmentType = setEnvironment();

function setEnvironment() {
  if (document.URL.includes('//hr.')) {
    return 'prod';
  } else if (document.URL.includes('//localhost') || document.URL.includes('//127.')) {
    return 'local';
  } else {
    return 'dev';
  }
}

function getAPIURL() {
  switch (environment) {
    case 'local':
      return 'http://localhost:5017/api';
    case 'dev':
      return 'https://api.hr-dev.trackersuite.co/api';
    case 'prod':
      return 'https://api.hr.trackersuite.co/api';
    default:
      throw `Unknown environment:${environment}`;
  }
}

function getSecurityAPIURL() {
  switch (environment) {
    case 'local':
      return 'http://localhost:5017/identity';
    case 'dev':
      return 'https://api.hr-dev.trackersuite.co/identity';
    case 'prod':
      return 'https://api.hr.trackersuite.co/identity';
    default:
      throw `Unknown environment:${environment}`;
  }
}

const APP_LINK_TO_ENVIRONMENT_MAP: Record<environmentType, string> = {
  local: 'https://hr-trackersuite.test-app.link',
  dev: 'https://hr-trackersuite.test-app.link',
  prod: 'https://hr-trackersuite.app.link',
};

export const REACT_APP_REVONE_API_URL = getAPIURL();
const REACT_APP_REVONE_API_IDENTITY_URL = getSecurityAPIURL();
export const CANDIDATE_APP_LINK_URL = APP_LINK_TO_ENVIRONMENT_MAP[environment];

export interface GetListV2Filter {
  fieldName: string;
  fieldValue: string;
  condition: string;
}

export interface GetListV2Params {
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
  sortDirection?: string;
  searchTerm?: string;
  fieldFilters?: GetListV2Filter[];
  sideFilters?: [];
  ids?: Identifier[];
  includeFields?: string[];
  useSmallModel?: boolean;
}

export const apiConfig = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

const api = {
  auth: {
    login: (data) => axios.post(`${REACT_APP_REVONE_API_IDENTITY_URL}/login?useCookies=true`, data, apiConfig),
    logout: () => axios.post(`${REACT_APP_REVONE_API_IDENTITY_URL}/logout`, {}, apiConfig),
    fieldManagerActivate: (fieldManagerId) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_USER_FIELD_MANAGER}/${fieldManagerId}/activate`,
        {},
        apiConfig
      ),
    fieldManagerForgotPassword: (email) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_USER_FIELD_MANAGER}/forgot-password`, { email }, apiConfig),
    fieldManagerUpdateProfile: (data) =>
      axios.put(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_USER_FIELD_MANAGER}/profile`, data, apiConfig),
    candidateVerifyEmail: (email, token) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_USER_CANDIDATE}/verify-email`, { email, token }, apiConfig),
    resetPassword: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/v3/user/reset-password`, data, apiConfig),
    getCurrentUserDetails: () =>
      axios
        .get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_USER_FIELD_MANAGER}/my-details`, apiConfig)
        .then((response) => ({
          ...response,
          data: { ...response.data, id: response?.data?.userId },
        })),
    getAblyToken: async (): Promise<string> => {
      return api.auth.getCurrentUserDetails().then((response) => response.data.ablyToken);
    },
    candidateForgotPassword: (email) => {
      return axios.post(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_USER_CANDIDATE}/forgot-password`,
        { email },
        apiConfig
      );
    },
    candidateActivate: (candidateId) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_USER_CANDIDATE}/${candidateId}/activate`, {}, apiConfig),
  },
  fieldManager: {
    updateUserSettings: (userSettings) => {
      return axios.post(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_USER_FIELD_MANAGER}/ui-settings`,
        userSettings,
        apiConfig
      );
    },
  },
  candidates: {
    getList: (params: GetListV2Params) => {
      const filter = {};
      if (params.ids) {
        filter['id'] = params.ids;
      }
      if (params.searchTerm) {
        filter['q'] = params.searchTerm;
      }
      let sort = ['id', 'asc'];
      if (params.sortBy && params.sortDirection) {
        sort = [params.sortBy, params.sortDirection];
      }

      // Calculate range based on pageNumber and pageSize
      const start = (params.pageNumber - 1) * params.pageSize;
      const end = start + params.pageSize - 1;
      const range = [start, end];

      return axios.get(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE}?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&range=${JSON.stringify(range)}`,
        apiConfig
      );
    },
    getCandidate: (id) => axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE}/${id}`, apiConfig),
    create: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE}`, data, apiConfig),
    getComments: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_COMMENTS}/${candidateId}`, apiConfig),
    deleteComment: (commentId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_COMMENTS}/${commentId}`, apiConfig),
    createComment: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_COMMENTS}`, data, apiConfig),
    getAddresses: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CANDIDATE_ADDRESS}/${candidateId}`, apiConfig),
    createAddress: (candidateId, data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CANDIDATE_ADDRESS}/${candidateId}`, data, apiConfig),
    updateAddress: (candidateId, data) =>
      axios.put(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CANDIDATE_ADDRESS}/${candidateId}`, data, apiConfig),
    deleteAddress: (candidateId, addressId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CANDIDATE_ADDRESS}/${candidateId}/${addressId}`,
        apiConfig
      ),
    setDefaultAddress: (candidateId, addressId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CANDIDATE_ADDRESS}-setdefault/${candidateId}/${addressId}`,
        apiConfig
      ),
    getMemberships: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_MEMBERSHIPS}/${candidateId}`, apiConfig),
    deleteMembership: (candidateId, membershipId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_MEMBERSHIPS}/${candidateId}/${membershipId}`,
        apiConfig
      ),
    createMembership: (candidateId, data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_MEMBERSHIPS}/${candidateId}`, data, apiConfig),
    getClearances: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_CLEARANCES}/${candidateId}`, apiConfig),
    deleteClearance: (candidateId, clearanceId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_CLEARANCES}/${candidateId}/${clearanceId}`,
        apiConfig
      ),
    createClearance: (candidateId, data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_CLEARANCES}/${candidateId}`, data, apiConfig),
    getSkills: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_SKILLS}/${candidateId}`, apiConfig),
    deleteSkill: (candidateId, skillId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_SKILLS}/${candidateId}/${skillId}`, apiConfig),
    createSkill: (candidateId, data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_SKILLS}/${candidateId}`, data, apiConfig),
    getSubindustries: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_SUBINDUSTRIES}/${candidateId}`, apiConfig),
    deleteSubindustry: (candidateId, subIndustryId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_SUBINDUSTRIES}/${candidateId}/${subIndustryId}`,
        apiConfig
      ),
    createSubindustry: (candidateId, data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_SUBINDUSTRIES}/${candidateId}`, data, apiConfig),
    getProjectExperiences: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_PROJECT_EXPERIENCE}/${candidateId}`, apiConfig),
    deleteProjectExperience: (candidateId, projectId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_PROJECT_EXPERIENCE}/${candidateId}/${projectId}`,
        apiConfig
      ),
    createProjectExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_PROJECT_EXPERIENCE}/${candidateId}`,
        data,
        apiConfig
      ),
    getClientExperiences: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_CLIENT_EXPERIENCE}/${candidateId}`, apiConfig),
    deleteClientExperience: (candidateId, clientId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_CLIENT_EXPERIENCE}/${candidateId}/${clientId}`,
        apiConfig
      ),
    createClientExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_CLIENT_EXPERIENCE}/${candidateId}`,
        data,
        apiConfig
      ),
    getOemExperience: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/${RESOURCE_CANDIDATE_OEM_EXPERIENCE}`,
        apiConfig
      ),
    deleteOemExperience: (candidateId, oemId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/${RESOURCE_CANDIDATE_OEM_EXPERIENCE}/${oemId}`,
        apiConfig
      ),
    createOemExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/${RESOURCE_CANDIDATE_OEM_EXPERIENCE}`,
        data,
        apiConfig
      ),
    getOemExperienceType: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE}/${candidateId}`, apiConfig),
    deleteOemExperienceType: (candidateId, oemId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE}/${candidateId}/${oemId}`,
        apiConfig
      ),
    createOemExperienceType: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE}/${candidateId}`,
        data,
        apiConfig
      ),
    getWorkExperiences: (candidateId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_WORK_EXPERIENCE}/${candidateId}`, apiConfig),
    deleteWorkExperience: (candidateId, workId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_WORK_EXPERIENCE}/${candidateId}/${workId}`,
        apiConfig
      ),
    createWorkExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_WORK_EXPERIENCE}/${candidateId}`,
        data,
        apiConfig
      ),
    getPrimaryWorkExperiences: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE}/${candidateId}`,
        apiConfig
      ),
    deletePrimaryWorkExperience: (candidateId, workId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE}/${candidateId}/${workId}`,
        apiConfig
      ),
    createPrimaryWorkExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE}/${candidateId}`,
        data,
        apiConfig
      ),
  },
  jobs: {
    getComments: (jobId) => axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_JOB_COMMENTS}/${jobId}`, apiConfig),
    deleteComment: (commentId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_JOB_COMMENTS}/${commentId}`, apiConfig),
    createComment: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_JOB_COMMENTS}`, data, apiConfig),
    getAddresses: (jobId) => axios.get(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_JOB_ADDRESS}/${jobId}`, apiConfig),
    createAddress: (jobId, data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_JOB_ADDRESS}/${jobId}`, data, apiConfig),
    updateAddress: (jobId, data) =>
      axios.put(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_JOB_ADDRESS}/${jobId}`, data, apiConfig),
    deleteAddress: (jobId, addressId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_JOB_ADDRESS}/${jobId}/${addressId}`, apiConfig),
  },
  placements: {
    getList: (params: GetListV2Params) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/placement-list`, params, apiConfig),
    getComments: (placementId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT_COMMENTS}/${placementId}`, apiConfig),
    getDocuments: (placementId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT_DOCUMENTS}/${placementId}`, apiConfig),
    deleteDocument: (documentId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT_DOCUMENTS}/${documentId}`, apiConfig),
    deleteComment: (placementId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT_COMMENTS}/${placementId}`, apiConfig),
    createComment: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT_COMMENTS}`, data, apiConfig),

    create: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT}`, data, apiConfig),
    update: (payload) =>
      axios.put(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT}/${payload.id}`, payload, apiConfig),
    delete: (id) => axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT}/${id}`, apiConfig),
    getPlacement: (id) => axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PLACEMENT}/${id}`, apiConfig),
  },
  lookup: {
    getData: (lookupType, parentId = undefined, parentName = undefined, keyword = undefined) => {
      if (parentId) {
        return axios.get(`${REACT_APP_REVONE_API_URL}/v3/${lookupType}?parentId=${parentId}`, apiConfig);
      } else if (parentName) {
        return axios.get(`${REACT_APP_REVONE_API_URL}/v3/${lookupType}?parentName=${parentName}`, apiConfig);
      } else if (keyword) {
        return axios.get(`${REACT_APP_REVONE_API_URL}/v3/${lookupType}?keyword=${keyword}`, apiConfig);
      } else {
        return axios.get(`${REACT_APP_REVONE_API_URL}/v3/${lookupType}`, apiConfig);
      }
    },
  },
  chat: {
    getChatRooms: (archived = false) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/chat?archived=${archived}`, apiConfig),
    getChatRoomMessages: (roomId) => axios.get(`${REACT_APP_REVONE_API_URL}/v3/chat/${roomId}`, apiConfig),
    getFieldManagerProfile: (fieldManagerId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/lookup-field-manager/${fieldManagerId}`, apiConfig),
    sendMessage: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/v3/ably/sendmessage`, data, apiConfig),
    resetUnReadMessages: (roomId) => axios.get(`${REACT_APP_REVONE_API_URL}/v3/chat/${roomId}/resetunread`, apiConfig),
    createChatRoom: (candidateId) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/candidate-chat/${candidateId}`, undefined, apiConfig),
    archiveChatRoom: (roomId) =>
      axios.put(`${REACT_APP_REVONE_API_URL}/v3/chat/${roomId}/archive`, undefined, apiConfig),
    unarchiveChatRoom: (roomId) =>
      axios.put(`${REACT_APP_REVONE_API_URL}/v3/chat/${roomId}/unarchive`, undefined, apiConfig),
  },
  documents: {
    getUrl: (documentId) => axios.get(`${REACT_APP_REVONE_API_URL}/v3/document-url/${documentId}`, apiConfig),
    getCandidateOfferLetterPreview: (placementId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v4/placement-document/preview-candidate-offer-letter/${placementId}`, {
        ...apiConfig,
        responseType: 'arraybuffer',
      }),
    sendCandidateOfferLetter: (placementId) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v4/placement-document/send-candidate-offer-letter/${placementId}`,
        undefined,
        apiConfig
      ),
    getEmployerPreview: (placementId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v4/placement-document/preview-exhibit-letter/${placementId}`, {
        ...apiConfig,
        responseType: 'arraybuffer',
      }),
    sendEmployerLetter: (placementId) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v4/placement-document/send-exhibit-letter/${placementId}`,
        undefined,
        apiConfig
      ),
    parseCv: (document) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/document-parse-cv/${document.id}`, document, apiConfig),
    mapSkills: (candidateId, skills) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/document-action-skill/${candidateId}`, skills, apiConfig),
  },
  clients: {
    getList: (params: GetListV2Params) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CLIENT_LIST}`, params, apiConfig),
    update: (payload) =>
      axios.put(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CLIENT}/${payload.id}`, payload.data, apiConfig),
    create: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CLIENT}`, data, apiConfig),
    getComments: (clientId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CLIENT_COMMENTS}/${clientId}`, apiConfig),
    deleteComment: (clientId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CLIENT_COMMENTS}/${clientId}`, apiConfig),
    createComment: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CLIENT_COMMENTS}`, data, apiConfig),
    getDocuments: (clientId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CLIENT_DOCUMENTS}/${clientId}`, apiConfig),
    uploadDocument: (formData) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CLIENT_DOCUMENTS}`, formData, apiConfig),
    deleteDocument: (documentId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_CLIENT_DOCUMENTS}/${documentId}`, apiConfig),
    getHighLevelIndustries: (clientId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CLIENT_HIGH_LEVEL_INDUSTRIES}/${clientId}`, apiConfig),
    createHighLevelIndustry: (clientId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CLIENT_HIGH_LEVEL_INDUSTRIES}/${clientId}`,
        data,
        apiConfig
      ),
    deleteHighLevelIndustry: (clientId, industryId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CLIENT_HIGH_LEVEL_INDUSTRIES}/${clientId}/${industryId}`,
        apiConfig
      ),
    getClient: (clientId) => axios.get(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CLIENT}/${clientId}`, apiConfig),
    delete: (clientId) => axios.delete(`${REACT_APP_REVONE_API_URL}/${RESOURCE_V3_CLIENT}/${clientId}`, apiConfig),
  },
  projects: {
    getDocuments: (projectId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PROJECT_DOCUMENTS}/${projectId}`, apiConfig),
    uploadDocument: (formData) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PROJECT_DOCUMENTS}`, formData, apiConfig),
    deleteDocument: (documentId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PROJECT_DOCUMENTS}/${documentId}`, apiConfig),
    getComments: (projectId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PROJECT_COMMENTS}/${projectId}`, apiConfig),
    createComment: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PROJECT_COMMENTS}`, data, apiConfig),
    deleteComment: (projectId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_PROJECT_COMMENTS}/${projectId}`, apiConfig),
  },
  users: {
    uploadImage: (fieldManagerId, formData) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v3/field-manager-image/${fieldManagerId}`, formData, apiConfig),
  },
  reports: {
    getList: () => axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_REPORT}`, apiConfig),
    getOne: (id) => axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_REPORT}/${id}`, apiConfig),
  },
  skills: {
    getAllSkills: () => axios.get(`${REACT_APP_REVONE_API_URL}/v3/${RESOURCE_LOOKUP_SKILL}`, apiConfig),
  },
  subIndustries: {
    getAllSubindustries: () =>
      axios.get(`${REACT_APP_REVONE_API_URL}/${RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE}`, apiConfig),
  },
};

export default api;
