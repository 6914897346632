import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { RESOURCE_PROJECT } from '../../../provider/dataProvider/constants';
import { PostPagination } from '../../PostPagination';
import ActionsColumn from '../../ActionsColumn';
import { Empty } from '../../Common/Empty/Empty';
import { useCanAccess } from '../../../hooks/useCanAccess';
import { actionPermissions } from '../../Permissions/constants';
import ListActions from '../../Common/ListActions/ListActions';

type ProjectsTabProps = {
  clientId: number | string;
  withActions?: boolean;
};

export const ProjectsTab: React.FC<ProjectsTabProps> = ({ withActions = false, clientId }) => {
  const canCreate = useCanAccess(actionPermissions.create, RESOURCE_PROJECT);
  const isRenderCreate = canCreate && withActions;

  return (
    <List
      title=" "
      resource={RESOURCE_PROJECT}
      disableSyncWithLocation
      perPage={100}
      pagination={<PostPagination />}
      filter={{ clientId, isActive: true }}
      sort={{ field: 'name', order: 'ASC' }}
      actions={
        <ListActions
          showAction={{ export: false }}
          showActionProps={{ create: { targetId: '' + clientId, targetField: 'clientId', label: 'Create Project' } }}
          sx={{ flexDirection: 'row-reverse' }}
        />
      }
      empty={isRenderCreate ? false : <Empty resourceName={RESOURCE_PROJECT} withWrapper />}
    >
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <TextField source="name" sortable />
        <TextField source="description" sortable />
        <TextField source="address.addressLine1" label="Address" />
        <TextField source="address.city" label="City/Town" />
        <TextField source="address.state" label="State" />
        <TextField source="address.country" label="Country" />
        {withActions && <ActionsColumn label="Actions" actionsSettings={{ show: false }} />}
      </Datagrid>
    </List>
  );
};
