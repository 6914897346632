import React from 'react';
import { Datagrid, EmailField, List, TextField, useDelete, useGetList, useRecordContext } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { RESOURCE_CANDIDATE, RESOURCE_FAVORITE_CANDIDATE } from '../../../provider/dataProvider/constants';
import { useInvalidateResourceQueryCache } from '../../../provider/dataProvider/useInvalidateResourceQueryCache';
import { candidateRowStyle } from '../../Candidate/CandidateCommon';
import { RemoveButton } from '../../Common/buttons/RemoveButton';
import CustomDateField from '../../Common/fields/CustomDateField';
import ListActions from '../../Common/ListActions/ListActions';
import { commonStyles } from '../../CommonStyles';
import LinkField from '../../LinkField';
import { CandidateLinkField } from './CandidateLinkField';
import { CandidatesListActions } from './CandidatesListActions';
import { CandidatesListBulkActionButtons } from './CandidatesListBulkActionButtons';

export const CandidatesList = () => {
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_FAVORITE_CANDIDATE, true);
  const record = useRecordContext();
  const { data, isLoading } = useGetList(RESOURCE_FAVORITE_CANDIDATE, {
    filter: { favoriteId: record.id },
    pagination: { page: 1, perPage: MAX_RECORD_PER_PAGE },
  });
  const [deleteOne] = useDelete(RESOURCE_FAVORITE_CANDIDATE);

  const handleRemove = async (id) => {
    const favoriteCandidate = data.find((fc) => fc.candidateId === id);
    if (favoriteCandidate) {
      await deleteOne(
        RESOURCE_FAVORITE_CANDIDATE,
        { id: favoriteCandidate.id },
        {
          onSuccess: async () => {
            await invalidateQueries();
          },
        }
      );
    }
  };

  return (
    <List
      title={false}
      resource={RESOURCE_CANDIDATE}
      perPage={50}
      sort={{ field: 'firstName', order: 'ASC' }}
      filter={{
        id: data && data.length > 0 ? data.map((fc) => fc.candidateId) : [0],
        isActive: true,
      }}
      sx={commonStyles.fullWidth}
      actions={
        <ListActions showAction={{ create: false, export: false }}>
          <CandidatesListActions />
        </ListActions>
      }
      empty={<CandidatesListActions />}
      disableSyncWithLocation
      queryOptions={{ enabled: data && data.length > 0 && !isLoading }}
    >
      <Datagrid rowClick={false} rowSx={candidateRowStyle} bulkActionButtons={<CandidatesListBulkActionButtons />}>
        <CandidateLinkField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="personalEmail" />
        <EmailField source="workEmail" />
        <LinkField phone source="homePhone">
          <TextField source="homePhone" label="Home Phone" />
        </LinkField>
        <LinkField phone source="mobile">
          <TextField source="mobile" />
        </LinkField>
        <CustomDateField source="availabilityDate" label="Availability" />
        <CustomDateField source="availabilityDateLastUpdated" label="Avail Last Updated" />
        <RemoveButton label="Actions" onRemove={handleRemove} />
      </Datagrid>
    </List>
  );
};
