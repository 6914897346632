import { FC } from 'react';
import { SidebarFilter } from '../../Common/filter/SidebarFilter';
import { ResourceInput } from './ResourceInput';
import { ActionInput } from './ActionInput';
import { PermissionInput } from './PermissionInput';
import { RoleInput } from './RoleInput';

export const PermissionSidebarFilters: FC = () => {
  return (
    <SidebarFilter>
      <RoleInput fullWidth alwaysOn />
      <PermissionInput fullWidth alwaysOn />
      <ResourceInput fullWidth alwaysOn />
      <ActionInput fullWidth alwaysOn />
    </SidebarFilter>
  );
};
