import * as React from 'react';
import { Datagrid, List, TextField, FunctionField } from 'react-admin';
import { RESOURCE_CANDIDATE } from '../../provider/dataProvider/constants';
import LinkField from '../LinkField';
import { Empty } from '../Common/Empty/Empty';
import CustomDateField from '../Common/fields/CustomDateField';
import { ALL_2099, DEFAULT_ROWS_PER_PAGE, featureChoices } from './constants';
import { FilterForm } from './components/FilterForm';

const defaultFilter = {
  availabilityDate_lte: ALL_2099,
  isActive: true,
};

export const AvailableCandidates = () => {
  return (
    <List
      title=" "
      resource={RESOURCE_CANDIDATE}
      storeKey="dashboard-avail-candidates"
      filterDefaultValues={defaultFilter}
      sort={{ field: 'availabilityDate', order: 'ASC' }}
      perPage={DEFAULT_ROWS_PER_PAGE}
      disableSyncWithLocation
      actions={
        <FilterForm
          title="Candidates Available Soon"
          label="Available within"
          source="creationDate"
          choices={featureChoices}
          valueSource="availabilityDate"
          filterParams={{
            fieldName: 'availabilityDate_lte',
          }}
        />
      }
    >
      <Datagrid
        rowClick={false}
        resource={RESOURCE_CANDIDATE}
        bulkActionButtons={false}
        empty={<Empty resourceName="candidates" />}
      >
        <LinkField source="id" label="Candidate" resource={RESOURCE_CANDIDATE}>
          <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} sortable={false} />
        </LinkField>
        <CustomDateField source="availabilityDate" sortable />
        <CustomDateField source="availabilityDateLastUpdated" label="Avail Last Updated" sortable />
      </Datagrid>
    </List>
  );
};
