import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';
import ActionsColumn from '../ActionsColumn';
import DownloadButton from '../Common/buttons/DownloadButton';
import { PreviewButton } from '../Common/buttons/PreviewButton';
import { Empty } from '../Common/Empty/Empty';
import { ReferenceManyField } from '../Common/fields/ReferenceManyField';
import { commonStyles } from '../CommonStyles';

type DocumentsTabProps = {
  reference: string;
  showMode: boolean;
  perPage?: number;
  actionColumnChildren?: ReactNode;
  footerActions?: ReactNode;
};

export const DocumentsTab: React.FC<DocumentsTabProps> = ({
  reference,
  showMode,
  actionColumnChildren,
  footerActions,
  perPage = 100,
}) => {
  return (
    <Box sx={{ display: 'inline-block' }}>
      <ReferenceManyField
        reference={reference}
        target="documents"
        perPage={perPage}
        sort={{ field: 'name', order: 'ASC' }}
        alwaysDisplayWrapper
      >
        <Datagrid
          rowClick={false}
          bulkActionButtons={false}
          empty={<Empty resourceName="documents" />}
          sx={commonStyles.subGridDefaultStyle}
        >
          <TextField source="documentType" label="Type" sx={{ textTransform: 'capitalize' }} />
          <TextField source="name" label="File Name" />
          <DateField source="creationDate" />
          <ActionsColumn actionsSettings={{ show: false, edit: false, delete: !showMode }} label="Actions">
            {actionColumnChildren}
            <PreviewButton />
            <DownloadButton source="url" label="" />
          </ActionsColumn>
        </Datagrid>
      </ReferenceManyField>
      {!showMode && footerActions}
    </Box>
  );
};
