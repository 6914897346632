import qs from 'query-string';
import React from 'react';
import { Create, CreateProps, SimpleForm } from 'react-admin';
import { useLocation } from 'react-router';
import { US_COUNTRY_NAME } from '../../provider/constants';
import { RESOURCE_CLIENT } from '../../provider/dataProvider/constants';
import { CLIENT_CONTACTS_TAB_IND } from '../Clients/ClientEdit';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { ClientContactForm } from './ClientContactForm';

export const ClientContactCreate: React.FC<CreateProps> = (props) => {
  const location = useLocation();
  const parsedSearch = qs.parse(location.search);
  const clientId = parsedSearch.clientId;

  return (
    <Create
      title="Add new Contact"
      actions={false}
      redirect={`/${RESOURCE_CLIENT}/${clientId}/${CLIENT_CONTACTS_TAB_IND}`}
      {...props}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        defaultValues={{ clientId, address: { country: US_COUNTRY_NAME } }}
        toolbar={<FooterToolbar cancelButtonResource={RESOURCE_CLIENT} />}
      >
        <ClientContactForm />
      </SimpleForm>
    </Create>
  );
};
