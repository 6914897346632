export const styles = {
  container: {
    display: 'flex',
    flex: 1,
  },
  datagrid: {
    width: '100%',
    flex: 1,
    '& .RaDatagrid-headerRow button': {
      margin: '0 12px',
    },
    '& .MuiTableRow-root th:not(:last-child)': {
      textAlign: 'left',
      paddingLeft: '15px',
    },
    '& .MuiTableRow-root td:last-of-type': {
      '& > *': {
        visibility: 'visible',
      },
    },
  },
  shrinkLastItem: {
    '& .MuiTableRow-root td:last-of-type': {
      width: '50px',
      padding: '0 12px',
    },
  },
  deleteButton: {
    '& span': {
      marginRight: 0,
    },
  },
  createButton: {
    display: 'flex',
    justifyContent: 'left',
  },
};
