import { FC } from 'react';
import { useParams } from 'react-router';
import { Box, Divider } from '@mui/material';
import {
  TabbedShowLayout,
  useRecordContext,
  TextField,
  Labeled,
  BooleanField,
  NumberField,
  RichTextField,
  FunctionField,
} from 'react-admin';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { commonStyles } from '../CommonStyles';
import { RESOURCE_CLIENT, RESOURCE_JOB_COMMENTS, RESOURCE_PROJECT } from '../../provider/dataProvider/constants';
import { CommentsTab } from '../Comment/CommentsTab';
import LinkField from '../LinkField';
import CustomDateField from '../Common/fields/CustomDateField';
import { AddressesTab } from './tabs/AddressesTab';
import { CandidatesTab } from './tabs/CandidatesTab';
import { NetBillRateFields } from './components/NetBillRateFields';
import { PayRateFields } from './components/PayRateFields';

type JobShowProps = Omit<ShowLayoutProps, 'children'>;

export const JobShow: FC<JobShowProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();
  const jobId = id || record?.id;

  return (
    <ShowLayout {...props}>
      <TabbedShowLayout.Tab label="Job" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="id" label="Job ID" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <LinkField
                source="client.id"
                resource={RESOURCE_CLIENT}
                label="Client"
                showMode
                sx={commonStyles.flexBoxItem}
              >
                <TextField source="client.name" />
              </LinkField>
            </Labeled>
            <Labeled>
              <LinkField
                source="project.id"
                resource={RESOURCE_PROJECT}
                label="Project"
                showMode
                sx={commonStyles.flexBoxItem}
              >
                <TextField source="project.name" />
              </LinkField>
            </Labeled>
          </Box>

          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="fieldManagerAssignedTo.name" label="Field Manager" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="jobTitle" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="numberOfPositions" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>

          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField label="Start Date" source="startDate" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <CustomDateField label="End Date" source="endDate" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <CustomDateField label="Date Added" source="dateAdded" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>

          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>

          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="region" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <BooleanField label="Computer Required" source="computerRequired" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <BooleanField label="Email Required" source="emailRequired" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>

          <Divider style={{ minWidth: '100%' }} />

          <h3 style={{ minWidth: '100%', marginBottom: '3rem', height: 0 }}>Payments & Billing</h3>

          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="perDiem" label="Per Diem" emptyText="0" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <NumberField
                emptyText="0"
                source="msp"
                label="MSP (%)"
                options={{ minimumFractionDigits: 3 }}
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
          </Box>

          <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="straightTimeBillRate" emptyText="0" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="overtimeBillRate" emptyText="0" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="doubleTimeBillRate" emptyText="0" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>

          <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

          <NetBillRateFields />

          <Divider
            style={{
              minWidth: '100%',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          />

          <PayRateFields />

          <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <RichTextField source="description" sx={commonStyles.flexBoxLongItem} />
            </Labeled>
          </Box>
        </Box>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Addresses" sx={commonStyles.formTabHeader}>
        <AddressesTab jobId={jobId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Comments" sx={commonStyles.formTabHeader}>
        <CommentsTab reference={RESOURCE_JOB_COMMENTS} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Candidates" sx={commonStyles.formTabHeader}>
        <CandidatesTab jobId={jobId} />
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};
