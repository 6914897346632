import React, { useState } from 'react';
import { Identifier } from 'ra-core';
import { Datagrid, Filter, FilterProps, ListContextProvider, ListView, TextField, useGetList } from 'react-admin';
import Box from '@mui/material/Box';
import { RESOURCE_CANDIDATE } from '../../provider/dataProvider/constants';
import FreeTextSearchInput from '../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import { Empty } from '../Common/Empty/Empty';
import SendNotificationButton from './SendNotificationButton';
import AddProspectSentNotificationButton from './AddProspectSendNotificationButton';
import AddProspectButton, { AddProspectProps } from './AddProspectButton';

const CandidateFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props}>
      <FreeTextSearchInput alwaysOn />
      <FreeTextSearchInput source="firstName" label="First Name" alwaysOn InputProps={false} />
      <FreeTextSearchInput source="lastName" label="Last Name" alwaysOn InputProps={false} />
    </Filter>
  );
};

const CandidateBulkActionButtons = (props: AddProspectProps) => {
  return (
    <>
      <SendNotificationButton {...props} resource={RESOURCE_CANDIDATE} />
      <AddProspectSentNotificationButton {...props} />
      <AddProspectButton {...props} />
    </>
  );
};

export const CandidateList = ({ jobId, withActions = false }) => {
  const [selectedIds, setSelectedIds] = useState<Identifier[]>([]);
  const [filter, setFilter] = useState({ isActive: true });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const sort = { field: 'firstName', order: 'ASC' as const };
  const { data, total } = useGetList(RESOURCE_CANDIDATE, {
    filter,
    pagination: { page, perPage },
    sort,
  });

  const setFilters = (newFilters) => {
    setFilter({ ...newFilters });
  };

  const onSelect = (val) => {
    setSelectedIds(val);
  };

  const onToggleItem = (item) => {
    if (selectedIds.includes(item)) {
      //Remove
      setSelectedIds(selectedIds.filter((id) => id !== item));
    } else {
      //Insert
      setSelectedIds([...selectedIds, item]);
    }
  };
  const onUnselectItems = () => {
    setSelectedIds([]);
  };

  // @ts-ignore
  const listCtx: ListControllerResult = {
    data,
    total,
    page,
    perPage,
    setPerPage,
    setPage,
    filterValues: filter,
    setFilters,
    sort,
    selectedIds,
    onSelect,
    onToggleItem,
    onUnselectItems,
  };

  return (
    <Box
      sx={{
        minWidth: '800px',
        '&.list-page': {
          width: '100%',
        },
      }}
    >
      <ListContextProvider value={listCtx}>
        <ListView
          title=" "
          actions={<></>}
          filters={<CandidateFilter />}
          sx={{
            '& .RaFilter-form': {
              width: '100%',
              display: 'contents',
            },
          }}
        >
          <Datagrid
            bulkActionButtons={withActions && <CandidateBulkActionButtons jobId={jobId} selectedIds={selectedIds} />}
            empty={<Empty resourceName="candidates" />}
          >
            <TextField source="firstName" sortable={false} />
            <TextField source="lastName" sortable={false} />
            <TextField source="personalEmail" sortable={false} />
            <TextField source="homePhone" sortable={false} />
          </Datagrid>
        </ListView>
      </ListContextProvider>
    </Box>
  );
};
