import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useCanAccess } from '@react-admin/ra-rbac';
import { Title } from 'react-admin';
import { Fragment } from 'react';
import { RESOURCE_CANDIDATE, RESOURCE_PLACEMENT } from '../../provider/dataProvider/constants';
import { AvailableCandidates } from './AvailableCandidates';
import { PlacementEnding } from './PlacementEnding';
import { PlacementOnGoing } from './PlacementOnGoing';
import { NewCandidates } from './NewCandidates';

export const Dashboard = () => {
  const { canAccess: canAccessCandidate } = useCanAccess({
    action: 'list',
    resource: RESOURCE_CANDIDATE,
  });

  const { canAccess: canAccessPlacement } = useCanAccess({
    action: 'list',
    resource: RESOURCE_PLACEMENT,
  });

  return (
    <>
      <Title title="Dashboard" />

      <Box display="flex" flexWrap="wrap" style={{ overflow: 'hidden' }}>
        {canAccessCandidate && (
          <Fragment>
            <Box flex={1} mr="0.5em">
              <NewCandidates />
            </Box>

            <Box flex={1} ml="0.5em" style={{ overflow: 'hidden' }}>
              <AvailableCandidates />
            </Box>

            <Divider style={{ minWidth: '100%', margin: '15px', height: 0 }} />
          </Fragment>
        )}

        {canAccessPlacement && (
          <Fragment>
            <Box flex={1} mr="0.5em" style={{ overflow: 'hidden' }}>
              <PlacementEnding />
            </Box>
            <Box flex={1} ml="0.5em" style={{ overflow: 'hidden' }}>
              <PlacementOnGoing />
            </Box>{' '}
          </Fragment>
        )}
      </Box>
    </>
  );
};
