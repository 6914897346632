import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useResourceContext } from 'ra-core';
import React, { Fragment, MouseEventHandler } from 'react';
import {
  DeleteWithConfirmButton,
  Button,
  useRecordContext,
  useUpdate,
  RecordRepresentation,
  useNotify,
} from 'react-admin';
import { useInvalidateResourceQueryCache } from '../../../provider/dataProvider/useInvalidateResourceQueryCache';
import { capitalizeFirstLetter } from '../../../provider/UtilityFunctions';

export const ArchiveToggleButton = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const [update] = useUpdate();
  const invalidateQueries = useInvalidateResourceQueryCache(resource, true);
  const notify = useNotify();

  const handleUnarchiveClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    await update(
      resource,
      { id: record.id, data: { ...record, isActive: true } },
      {
        onSuccess: async () => {
          await invalidateQueries();
        },
        onError: async (err) => {
          notify(`${err.message ? err.message : ''} Try again, please!`, {
            type: 'error',
            undoable: false,
          });
        },
      }
    );
  };

  return record.isActive ? (
    <DeleteWithConfirmButton
      label=""
      confirmTitle={`Archive ${capitalizeFirstLetter(resource)}`}
      confirmContent={
        <Fragment>
          Are you sure to archive : <RecordRepresentation />
        </Fragment>
      }
      icon={<ArchiveIcon />}
      mutationOptions={{
        onSuccess: async () => {
          await invalidateQueries();
        },
      }}
    />
  ) : (
    <Button onClick={handleUnarchiveClick}>
      <UnarchiveIcon />
    </Button>
  );
};
