import { SxProps } from '@mui/material/styles';
import React, { FC, ReactElement } from 'react';
import Icon from '@mui/material/Icon';
import { styles } from './styles';

type EmptyIconButtonPlaceholderProps = {
  sx?: SxProps;
};

export const EmptyIconButtonPlaceholder: FC<EmptyIconButtonPlaceholderProps> = ({ sx }): ReactElement => (
  <Icon sx={[styles.emptyIconButtonPlaceholder, ...(Array.isArray(sx) ? sx : [sx])]} />
);
