export const RESOURCE_CANDIDATE = 'candidate';
export const RESOURCE_CANDIDATE_CLEARANCES = 'candidate-clearances';
export const RESOURCE_CANDIDATE_CONTACT = 'candidate-client-contact';
export const RESOURCE_CLIENT_CONTACT = 'client-contact';
export const RESOURCE_CLIENT = 'client';
export const RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES = 'clients/highlevelindustry';
export const RESOURCE_FAVORITE = 'favorite';
export const RESOURCE_FAVORITE_CANDIDATE = 'favorite-candidate';
export const RESOURCE_JOB = 'job';
export const RESOURCE_CANDIDATE_MEMBERSHIPS = 'candidate-affiliationmemberships';
export const RESOURCE_MESSAGING = 'messaging';
export const RESOURCE_PERMISSION = 'permission-role';
export const RESOURCE_PLACEMENT = 'placement';
export const RESOURCE_PROJECT_SUB_INDUSTRY = 'project-subindustry';
export const RESOURCE_PROJECT_CONTACT = 'project-client-contact';
export const RESOURCE_PROJECT = 'project';
export const RESOURCE_REPORT = 'report';
export const RESOURCE_ROLE = 'role';
export const RESOURCE_CANDIDATE_SKILLS = 'candidate-skills';
export const RESOURCE_CANDIDATE_SUBINDUSTRIES = 'candidate-subindustries';
export const RESOURCE_USER_FIELD_MANAGER = 'user-field-manager';
export const RESOURCE_USER_CANDIDATE = 'user-candidate';

/*********************************** EXPERIENCE ***********************************/
export const RESOURCE_CANDIDATE_PROJECT_EXPERIENCE = 'candidate-candidateprimaryprojectphases';
export const RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE = 'candidate-oemexperiencetype';
export const RESOURCE_CANDIDATE_OEM_EXPERIENCE = 'oemexperience';
export const RESOURCE_CANDIDATE_WORK_EXPERIENCE = 'candidate-candidateworkexperiencelevel';
export const RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE = 'candidate-candidateprimaryworkscopeexperience';
export const RESOURCE_CANDIDATE_CLIENT_EXPERIENCE = 'candidate-clientexperiences';

/*********************************** ADDRESSES ***********************************/
export const RESOURCE_V3_CANDIDATE_ADDRESS = 'v3/candidate-address';
export const RESOURCE_V3_JOB_ADDRESS = 'v3/job-address';

/*********************************** DOCUMENTS ***********************************/
export const RESOURCE_CANDIDATE_DOCUMENTS = 'candidate-document';
export const RESOURCE_CLIENT_DOCUMENTS = 'client-document';
export const RESOURCE_PROJECT_DOCUMENTS = 'project-document';
export const RESOURCE_PLACEMENT_DOCUMENTS = 'placement-document';

/*********************************** COMMENTS ***********************************/
export const RESOURCE_CANDIDATE_COMMENTS = 'comments-candidate';
export const RESOURCE_CLIENT_COMMENTS = 'comments-client';
export const RESOURCE_PROJECT_COMMENTS = 'comments-project';
export const RESOURCE_JOB_COMMENTS = 'comments-job';
export const RESOURCE_PLACEMENT_COMMENTS = 'comments-placement';

/*********************************** LOOKUP ***********************************/
export const RESOURCE_LOOKUP_FIELDMANAGER = 'lookup-field-manager';
export const RESOURCE_LOOKUP_MEMBERSHIPS = 'lookup/affiliationmembership';
export const RESOURCE_LOOKUP_CLEARANCE = 'lookup/clearance';
export const RESOURCE_LOOKUP_SKILL = 'lookup/skill';
export const RESOURCE_LOOKUP_SUBINDUSTRY = 'lookup/subindustry';
export const RESOURCE_LOOKUP_JOBTITLE = 'lookup/jobtitle';
export const RESOURCE_LOOKUP_CLIENT_EXPERIENCE = 'lookup/client';
export const RESOURCE_LOOKUP_WORK_EXPERIENCE = 'lookup/workexperiencelevel';
export const RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE = 'lookup/primaryworkscopeexperience';
export const RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE = 'lookup/oemexperiencetype';
export const RESOURCE_LOOKUP_OEM_EXPERIENCE = 'lookup/oemexperience';
export const RESOURCE_LOOKUP_PROJECT_EXPERIENCE = 'lookup/primaryprojectexperience';
export const RESOURCE_LOOKUP_COUNTRY = 'lookup/country';
export const RESOURCE_LOOKUP_STATE = 'lookup/state';
export const RESOURCE_LOOKUP_CITY = 'lookup/city';
export const RESOURCE_HIGH_LEVEL_INDUSTRY = 'lookup/highlevelindustry';

/*********************************** JOBRESPONSE ***********************************/
export const RESOURCE_JOB_RESPONSE = 'job-response';

/*********************************** CLIENT ***********************************/
export const RESOURCE_V3_CLIENT_LIST = 'v3/client-list';
export const RESOURCE_V3_CLIENT = 'v3/client';
export const RESOURCE_V3_CLIENT_HIGH_LEVEL_INDUSTRIES = 'v3/client-highlevelindustries';

/*********************************** V2 ***********************************/
export const RESOURCE_V2_CLIENT_CONTACTS = 'v2/clientcontactspaged';
export const RESOURCE_V3_JOBS = 'v3/job-list';

/*********************************** ROUTES ***********************************/
export const ROUTE_USER_PROFILE = '/my-profile';
export const ROUTE_FIELD_MANAGER_RESET_PASSWORD = '/field-manager-reset-password';
export const ROUTE_FIELD_MANAGER_RESET_PASSWORD_CONFIRMATION = '/field-manager-password-reset-confirmation';
export const ROUTE_CANDIDATE_RESET_PASSWORD = '/candidate-reset-password';
export const ROUTE_CANDIDATE_RESET_PASSWORD_CONFIRMATION = '/candidate-password-reset-confirmation';
export const ROUTE_CANDIDATE_VERIFY_EMAIL = '/candidate-verify-email';
export const ROUTE_ENTER_EMAIL = '/enter-email';
export const ROUTE_SENT_EMAIL = '/email-sent';
