import { Badge, Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { useResourceContext } from 'ra-core';
import React from 'react';
import { useListContext, useStore } from 'react-admin';

export const SidebarFilterToggleButton: React.FC = () => {
  const { filterValues, setFilters } = useListContext();
  const resource = useResourceContext();
  const [sideFilters, setSideFilters] = useStore(`${resource}.sideFilters`, false);

  const appliedFiltersCount = Object.keys(filterValues).filter((key) => key !== 'q').length;

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filterValues });
    setSideFilters(e.target.checked);
  };

  return (
    <FormControlLabel
      control={<Switch size="small" checked={sideFilters} onChange={handleSwitchChange} />}
      label={
        <Badge badgeContent={appliedFiltersCount} color="secondary">
          <Tooltip
            title={appliedFiltersCount > 0 ? `Filters Enabled (${appliedFiltersCount} applied)` : 'Filters Disabled'}
          >
            <Box sx={{ marginRight: '10px' }}>Show Filters</Box>
          </Tooltip>
        </Badge>
      }
    />
  );
};
