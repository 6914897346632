import React from 'react';
import { Box } from '@mui/material';
import { Datagrid, RichTextField, TextField } from 'react-admin';
import ActionsColumn from '../ActionsColumn';
import { ReferenceManyField } from '../Common/fields/ReferenceManyField';
import { commonStyles } from '../CommonStyles';
import { Empty } from '../Common/Empty/Empty';
import { CommentCreate } from './CommentCreate';

type CommentsTabProps = {
  reference: string;
  withActions?: boolean;
};

export const CommentsTab: React.FC<CommentsTabProps> = ({ withActions = false, reference }) => {
  return (
    <Box sx={{ maxWidth: '60%' }}>
      <ReferenceManyField
        reference={reference}
        target="comments"
        sort={{ field: 'lastModifiedDate', order: 'DESC' }}
        alwaysDisplayWrapper
      >
        <Datagrid
          bulkActionButtons={false}
          empty={<Empty resourceName="comments" />}
          sx={commonStyles.subGridDefaultStyle}
          rowClick={false}
        >
          <RichTextField source="text" label="Comment" sortable={false} />
          <TextField source="fieldManagerName" label="Comment Author" sortable={false} />
          <TextField source="lastModifiedDate" sortable={false} />
          {withActions && <ActionsColumn label="Actions" actionsSettings={{ edit: false, show: false }} />}
        </Datagrid>
      </ReferenceManyField>
      {withActions && <CommentCreate resource={reference} />}
    </Box>
  );
};
