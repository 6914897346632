import { Datagrid, EmailField, TextField } from 'react-admin';
import React, { Fragment } from 'react';
import { RESOURCE_CLIENT_CONTACT } from '../../provider/dataProvider/constants';
import { PostPagination } from '../PostPagination';
import LinkField from '../LinkField';
import ActionsColumn from '../ActionsColumn';
import { ReferenceManyField } from '../Common/fields/ReferenceManyField';
import { useCanAccess } from '../../hooks/useCanAccess';
import { actionPermissions } from '../Permissions/constants';
import { FavoriteCandidateButton } from './FavoriteCandidateButton';
import { ClientContactEditInDialog } from './ClientContactEditInDialog';
import { ClientContactCreateInDialog } from './ClientContactCreateInDialog';

interface Props {
  clientId?: string | number;
  projectId?: string | number;
  withActions?: boolean;
}

export const ClientContactList: React.FC<Props> = ({ clientId, withActions = false }) => {
  const canCreate = useCanAccess(actionPermissions.create, RESOURCE_CLIENT_CONTACT);
  const canEdit = useCanAccess(actionPermissions.edit, RESOURCE_CLIENT_CONTACT);
  const isRenderEdit = canEdit && withActions;
  const isRenderCreate = canCreate && withActions;

  return (
    <ReferenceManyField
      reference={RESOURCE_CLIENT_CONTACT}
      target="clientId"
      sort={{ field: 'firstName', order: 'ASC' }}
      pagination={<PostPagination />}
      perPage={100}
      filter={{ isActive: true }}
    >
      {isRenderCreate && <ClientContactCreateInDialog clientId={+clientId} />}
      <Datagrid rowClick={false} bulkActionButtons={false} empty={<Fragment />}>
        <TextField source="firstName" label="First Name" sortable />
        <TextField source="lastName" label="Last Name" sortable />
        <TextField source="jobTitle" label="Job Title" sortable />
        <EmailField source="address.email" label="Email" sortable />
        <LinkField phone source="address.phone" label="Phone" sortable>
          <TextField source="address.phone" label="Phone" sortable />
        </LinkField>
        <EmailField source="address.alternativeEmail" label="Alt Email" sortable />
        <TextField source="address.alternativePhone" label="Alt Phone" sortable />
        <TextField source="address.addressLine1" label="Address" sortable={false} />
        <TextField source="address.country" label="Country" sortable />
        <TextField source="address.state" label="State" sortable />
        <TextField source="address.city" label="City" sortable />
        <TextField source="updatedByName" label="Updated By" sortable />
        {withActions && (
          <ActionsColumn
            resource={RESOURCE_CLIENT_CONTACT}
            label="Actions"
            actionsSettings={{ show: false, edit: false, delete: true }}
          >
            <FavoriteCandidateButton />
            {isRenderEdit && <ClientContactEditInDialog />}
          </ActionsColumn>
        )}
      </Datagrid>
    </ReferenceManyField>
  );
};
