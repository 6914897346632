import React from 'react';
import './ChatMessage.css';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { DEFAULT_AVATAR } from '../../provider/constants';

export interface ChatMessageProps {
  message: any;
  candidate: any;
  fieldManager: any;
  isMyMessage: boolean;
}

const ChatMessage = React.forwardRef<any, ChatMessageProps>(
  ({ message, candidate, fieldManager, isMyMessage }, ref) => {
    let avatarUrl = DEFAULT_AVATAR;
    let name = 'Unknown';

    if (candidate) {
      name = `${candidate.firstName} ${candidate.lastName}`;
      avatarUrl = candidate.avatarUrl || DEFAULT_AVATAR;
    }
    if (fieldManager) {
      name = fieldManager.name;
      avatarUrl = fieldManager.avatarUrl || DEFAULT_AVATAR;
    }

    // @ts-ignore
    return (
      <li className={`MessageItemContainer${isMyMessage ? ' AlignRight' : ''}`} ref={ref}>
        <Avatar alt={name} src={avatarUrl} style={{ width: 30, height: 30 }} />

        <div className="MessageItemBody">
          <div className="MessageItemHeader">
            <Typography variant="subtitle2" color="textSecondary" align="left">
              {`${name}   ${moment(message.dateSent).local().format('HH:mm')}`}
            </Typography>
          </div>

          <Typography variant="body2" color="textPrimary" align="left">
            {message.messageText}
          </Typography>
        </div>
      </li>
    );
  }
);

export default ChatMessage;
