import React, { FC } from 'react';
import { BooleanInput, Filter, FilterProps } from 'react-admin';
import FreeTextSearchInput from '../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import InvertedBooleanInput from '../Common/inputs/InvertedBooleanInput';
import CustomDateInputV3 from '../CustomDateInputV3';

type PlacementFilterProps = Omit<FilterProps, 'children'>;

export const PlacementFilter: FC<PlacementFilterProps> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput source="q" alwaysOn fullTextSearch />
      <InvertedBooleanInput source="isActive" label="Archived" alwaysOn />
      <BooleanInput source="isPending" label="Pending" alwaysOn />
      <CustomDateInputV3 source="startDate_gte" label="Start Date" />
      <CustomDateInputV3 source="endDate_lte" label="End Date" />
    </Filter>
  );
};
