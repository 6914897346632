import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { RESOURCE_LOOKUP_FIELDMANAGER } from '../../../provider/dataProvider/constants';

type FieldManagerInputProps = {
  sx?: SxProps<Theme>;
};

export const FieldManagerInput: React.FC<FieldManagerInputProps> = ({ sx }) => {
  return (
    <ReferenceInput
      label="Field Manager"
      source="fieldManagerId"
      reference={RESOURCE_LOOKUP_FIELDMANAGER}
      link={false}
      sortable={false}
    >
      <AutocompleteInput sx={sx} optionText="name" />
    </ReferenceInput>
  );
};
