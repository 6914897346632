import { GetListParams } from 'ra-core/src/types';
import { GetOneParams, UpdateParams } from 'react-admin';
import {
  billingInformation,
  generalTermsAndConditions,
  mobilizationAndDemobilization,
  toggleStorageName,
} from '../constants';
import { getDefaultDataProvider } from './defaultDataProvider';

const defaultProvider = getDefaultDataProvider('/v4');

const additionalInfoFields = { ...generalTermsAndConditions, ...mobilizationAndDemobilization, ...billingInformation };
const transformToToggleInfo = (placementData) =>
  Object.keys(additionalInfoFields).reduce((acc, fieldName) => {
    acc[fieldName] = placementData[fieldName] !== null;
    return acc;
  }, {});

const transformToApiRequest = (placementData) => {
  Object.keys(additionalInfoFields).forEach((key) => {
    if (placementData[toggleStorageName + '.' + key] === false) {
      placementData[key] = null;
    }
  });
  delete placementData[toggleStorageName];

  return placementData;
};

export const placementProvider = {
  update: async (resource: string, params: UpdateParams) => {
    params.data = transformToApiRequest(params.data);
    return await defaultProvider.update(resource, params);
  },
  getOne: async (resource: string, params: GetOneParams) => {
    const result = await defaultProvider.getOne(resource, params);
    return {
      data: {
        ...result.data,
        [toggleStorageName]: transformToToggleInfo(result.data),
      },
    };
  },
  getList: async (resource: string, params: GetListParams) => {
    const result = await defaultProvider.getList(resource, params);
    return {
      total: result.total,
      data: result.data.map((item) => {
        return {
          ...item,
          [toggleStorageName]: transformToToggleInfo(item),
        };
      }),
    };
  },
};
