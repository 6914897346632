import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import React from 'react';
import { Button, Identifier, useRecordContext } from 'react-admin';

interface RemoveButtonProps {
  onRemove: (id: Identifier) => void;
  label?: string;
}

export const RemoveButton: React.FC<RemoveButtonProps> = ({ onRemove }) => {
  const record = useRecordContext();

  const handleRemove = () => {
    if (record?.id) {
      onRemove(record.id);
    }
  };

  return (
    <Button onClick={handleRemove}>
      <RemoveCircleIcon fontSize="small" />
    </Button>
  );
};
