import React, { FC } from 'react';
import { CreateProps } from 'react-admin';
import { SimpleCreateForm } from '../Common/forms/SimpleCreateForm';
import { PermissionForm } from './PermissionForm';

type PermissionCreateProps = Omit<CreateProps, 'children'>;

export const PermissionCreate: FC<PermissionCreateProps> = (props) => {
  return (
    <SimpleCreateForm {...props} title="Role Permission Create">
      <PermissionForm />
    </SimpleCreateForm>
  );
};
