import grey from '@mui/material/colors/grey';

export const styles = {
  helpIconContainer: {
    color: grey[600],
    display: 'flex',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContainer: {
    width: '350px',
    padding: '5px',
  },
  textBottomSpacing: {
    paddingBottom: '10px',
  },
  input: {
    marginBottom: '5px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
};
