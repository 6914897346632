import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { Labeled, ReferenceField, TabbedShowLayout, TextField } from 'react-admin';
import { RESOURCE_ROLE } from '../../provider/dataProvider/constants';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { commonStyles } from '../CommonStyles';
import { PermissionRoleTitle } from './components/PermissionRoleTitle';
import { ResourceField } from './components/ResourceField';

type PermissionShowProps = Omit<ShowLayoutProps, 'children'>;

export const PermissionShow: FC<PermissionShowProps> = ({ title, ...props }) => {
  return (
    <ShowLayout {...props} title={<PermissionRoleTitle />}>
      <TabbedShowLayout.Tab label="Role Permission" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="id" label="ID" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <ReferenceField source="hrRoleId" reference={RESOURCE_ROLE} label="Role" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="permissionType" label="Permission Type" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <ResourceField source="resource" label="Resource" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="action" label="Role Action" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
        </Box>
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};
