import React from 'react';
import { Identifier } from 'ra-core';
import { EditableDatagrid, EditRowButton } from '@react-admin/ra-editable-datagrid';
import { Button, TextField, useNotify, useRecordContext, useRefresh } from 'react-admin';
import api from '../../../provider/api';
import { US_COUNTRY_NAME } from '../../../provider/constants';
import { RESOURCE_V3_CANDIDATE_ADDRESS } from '../../../provider/dataProvider/constants';
import ActionsColumn from '../../ActionsColumn';
import { StandaloneCreateButton } from '../../Common/buttons/StandaloneCreateButton';
import { ReferenceManyField } from '../../Common/fields/ReferenceManyField';
import { commonStyles } from '../../CommonStyles';
import AddressRowForm from '../AddressRowForm';
import { Empty } from '../../Common/Empty/Empty';

const addressRowStyle = (record) => {
  if (record && record.isDefault) {
    return {
      borderLeftColor: 'black',
      borderLeftWidth: 8,
      borderLeftStyle: 'solid',
    };
  } else {
    return {
      borderLeftWidth: 8,
    };
  }
};

const SetDefaultAddress = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Button
      label="Default"
      variant="text"
      disabled={record?.isDefault}
      onClick={() => {
        api.candidates
          .setDefaultAddress(record.candidateId, record.id)
          .then((response) => {
            if (response.status === 200) {
              refresh();
            } else {
              notify('Try again, please', { type: 'warning', undoable: false });
            }
          })
          .catch(() => {
            notify('Try again, please', { type: 'warning', undoable: false });
          });
      }}
    />
  );
};

type AddressesTabProps = {
  candidateId: Identifier;
  withActions?: boolean;
};

export const AddressesTab: React.FC<AddressesTabProps> = ({ candidateId, withActions = false }) => {
  return (
    <ReferenceManyField reference={RESOURCE_V3_CANDIDATE_ADDRESS} target="addresses" sx={commonStyles.fullWidth}>
      <EditableDatagrid
        empty={
          withActions ? (
            <StandaloneCreateButton label="Create Address" />
          ) : (
            <Empty resourceName="addresses" withWrapper />
          )
        }
        rowClick={false}
        bulkActionButtons={false}
        mutationMode="pessimistic"
        sx={commonStyles.formTabDatagrid}
        rowSx={addressRowStyle}
        actions={
          withActions && (
            <ActionsColumn actionsSettings={{ show: false }} customEditButton={<EditRowButton />}>
              {withActions && <SetDefaultAddress />}
            </ActionsColumn>
          )
        }
        editForm={withActions && <AddressRowForm hasAddressType hasUnitNumber />}
        createForm={
          withActions && (
            <AddressRowForm hasAddressType hasUnitNumber defaultValues={{ candidateId, country: US_COUNTRY_NAME }} />
          )
        }
      >
        <TextField source="addressType" sortable={false} />
        <TextField source="address" sortable={false} />
        <TextField source="unitNumber" sortable={false} />
        <TextField source="country" sortable={false} />
        <TextField source="state" label="State/Province" sortable={false} />
        <TextField source="city" label="City/Town" sortable={false} />
        <TextField source="zip" sortable={false} />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};
