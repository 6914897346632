import { MenuItemLink } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import { ResourceMenuItemData } from './menuItems';

export const ResourceMenuItem = ({ resource, primaryText, id, leftIcon }: ResourceMenuItemData) => (
  <MenuItemLink
    key={id}
    primaryText={primaryText}
    id={id}
    to={!isEmpty(resource) && typeof resource === 'string' ? `/${resource}` : ''}
    leftIcon={leftIcon}
  ></MenuItemLink>
);
