export const styles = {
  card: {
    order: -1,
    marginRight: 1,
    marginTop: 2,
    marginBottom: 6.5,
    minWidth: 300,
  },
  cardContent: {
    '& form.RaFilter-form': {
      display: 'block',
      '& > div': { display: 'block' },
    },
  },
  form: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  box: {
    '&.MuiBox-root': {
      width: '100%',
      paddingBottom: '10px',
      borderBottom: '2px solid grey',
      marginBottom: '20px',
    },
  },
};
