import React, { useState } from 'react';
import { Button, useRecordContext, useResourceContext } from 'react-admin';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { RESOURCE_CLIENT_CONTACT, RESOURCE_PROJECT_CONTACT } from '../../provider/dataProvider/constants';
import FavoriteCandidatesDialog from './FavoriteCandidatesDialog';

export const FavoriteCandidateButton: React.FC = () => {
  const record = useRecordContext();
  const [isOpen, setIsOpen] = useState(false);

  const resource = useResourceContext();
  let clientContactId;
  let title;
  if (resource == RESOURCE_CLIENT_CONTACT) {
    clientContactId = record.id;
    title = `${record?.firstName} ${record?.lastName}'s favorite candidates`;
  } else if (resource == RESOURCE_PROJECT_CONTACT) {
    clientContactId = record?.clientContact?.id;
    title = `${record?.clientContact?.firstName} ${record?.clientContact?.lastName}'s favorite candidates`;
  }

  return (
    <>
      <Button label="" onClick={() => setIsOpen(true)}>
        <FavoriteBorderIcon />
      </Button>
      {isOpen && (
        <FavoriteCandidatesDialog clientContactId={clientContactId} title={title} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};
