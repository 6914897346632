import Box from '@mui/material/Box';
import { FC } from 'react';
import { BooleanField, Labeled, TabbedShowLayout, TextField, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { commonStyles } from '../CommonStyles';
import LinkField from '../LinkField';
import { CommentsTab } from '../Comment/CommentsTab';
import { RESOURCE_CANDIDATE_COMMENTS } from '../../provider/dataProvider/constants';
import CustomDateField from '../Common/fields/CustomDateField';
import { DocumentsTab } from './tabs/DocumentsTab';
import { AddressesTab } from './tabs/AddressesTab';
import { ExperienceTab } from './tabs/ExperienceTab';
import { ClearanceMembershipsTab } from './tabs/ClearanceMembershipsTab';
import { SkillsIndustriesTab } from './tabs/SkillsIndustriesTab';
import { PlacementsTab } from './tabs/PlacementsTab';

type CandidateShowProps = Omit<ShowLayoutProps, 'children'>;

export const CandidateShow: FC<CandidateShowProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();
  const candidateId = id || record?.id;

  return (
    <ShowLayout {...props}>
      <TabbedShowLayout.Tab label="Candidate" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="id" label="Candidate ID" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="title" label="Title" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="firstName" label="First name" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="lastName" label="Last name" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="nickName" label="Nick name" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <LinkField label="Personal email" email sx={commonStyles.flexBoxItem}>
                <TextField source="personalEmail" />
              </LinkField>
            </Labeled>
            <Labeled>
              <LinkField label="Work email" email sx={commonStyles.flexBoxItem}>
                <TextField source="workEmail" />
              </LinkField>
            </Labeled>
            <Labeled>
              <LinkField label="Mobile" phone sx={commonStyles.flexBoxItem}>
                <TextField source="mobile" />
              </LinkField>
            </Labeled>
            <Labeled>
              <LinkField label="Home Phone" phone sx={commonStyles.flexBoxItem}>
                <TextField source="homePhone" />
              </LinkField>
            </Labeled>
            <Labeled>
              <LinkField label="Other Phone" phone sx={commonStyles.flexBoxItem}>
                <TextField source="otherPhone" />
              </LinkField>
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="birthday" label="Birthday" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <BooleanField source="doNotHire" label="Do not hire" looseValue sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <BooleanField
                source="hasMilitaryExperience"
                label="Has military experience"
                looseValue
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled>
              <BooleanField
                source="completedWizard"
                label="Completed wizard"
                looseValue
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled>
              <TextField source="referredBy" label="Referred by" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField source="availabilityDate" label="Availability date" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <CustomDateField
                source="availabilityDateLastUpdated"
                label="Availability last updated"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled>
              <CustomDateField
                source="lastLoginOnMobileAppDate"
                label="Last login in mobile app"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
        </Box>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Comments">
        <CommentsTab reference={RESOURCE_CANDIDATE_COMMENTS} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Documents">
        <DocumentsTab candidateId={candidateId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Addresses">
        <AddressesTab candidateId={candidateId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Experience">
        <ExperienceTab candidateId={candidateId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Clearance/Memberships">
        <ClearanceMembershipsTab candidateId={candidateId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Skills/Industries">
        <SkillsIndustriesTab candidateId={candidateId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Placements">
        <PlacementsTab />
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};
