import moment from 'moment';
import { FC } from 'react';
import { EmailField, List, ListProps, RaRecord, TextField } from 'react-admin';
import { DEFAULT_DATE_FORMAT, PROSPECT_CANDIDATE_SUBMITTED } from '../../provider/constants';
import { colors } from '../../theme';
import ActionsColumn from '../ActionsColumn';
import AvatarColumnField from '../AvatarColumnField';
import CustomBooleanField from '../CustomBooleanField';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import LinkField from '../LinkField';
import { PostPagination } from '../PostPagination';
import CustomDateField from '../Common/fields/CustomDateField';
import { CandidateEdit } from './CandidateEdit';
import { CandidateShow } from './CandidateShow';
import { CandidateSidebarFilter } from './CandidateSidebarFilter';
import { CandidateBulkActionButtons } from './components/CandidateBulkActionButtons';
import { CandidateFilter } from './components/CandidateFilter';
import { ListActions } from './components/ListActions';

const getRowBorderColor = (record: RaRecord) => {
  const jobResponses = Array.isArray(record.jobResponses) ? record.jobResponses : [];
  const submittedJob = jobResponses.find((jr) => jr.prospectStatusId === PROSPECT_CANDIDATE_SUBMITTED);

  if (record.doNotHire) {
    return colors.indicator.black;
  } else if (submittedJob) {
    return colors.indicator.purple;
  } else if (record['availabilityDate']) {
    const availMoment = moment(record['availabilityDate'], DEFAULT_DATE_FORMAT);
    const nowMoment = moment();
    const months = availMoment.diff(nowMoment, 'months');

    if (months < -1) {
      return colors.indicator.red;
    } else if (months < 2) {
      return colors.indicator.green;
    } else if (months < 5) {
      return colors.indicator.yellow;
    } else {
      return colors.indicator.red;
    }
  }

  return colors.indicator.grey;
};

export const candidateRowStyle = (record: RaRecord) => {
  return {
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

export const CandidateList: FC<ListProps> = (props) => {
  return (
    <List
      title="Candidates"
      {...props}
      sort={{ field: 'firstName', order: 'ASC' }}
      filters={<CandidateFilter />}
      filter={{ isActive: true }}
      queryOptions={{ meta: { includeFields: ['jobResponses', 'address'] } }}
      actions={<ListActions />}
      perPage={100}
      pagination={<PostPagination />}
      aside={<CandidateSidebarFilter />}
    >
      <DatagridConfigurableRbac
        bulkActionButtons={<CandidateBulkActionButtons />}
        rowClick="expand"
        editComponent={<CandidateEdit expandView />}
        showComponent={<CandidateShow expandView />}
        rowSx={candidateRowStyle}
        omit={[
          'homePhone',
          'otherPhone',
          'birthday',
          'referredBy',
          'doNotHire',
          'hasMilitaryExperience',
          'createdByName',
          'updatedByName',
          'completedWizard',
          'creationDate',
          'lastModifiedDate',
          'lastLoginOnMobileAppDate',
          'availabilityDateLastUpdated',
          'addresses[0].address',
          'addresses[0].unitNumber',
          'addresses[0].city',
          'addresses[0].state',
          'addresses[0].zip',
          'addresses[0].country',
        ]}
      >
        <AvatarColumnField source="avatarUrl" size={25} label="Avatar" sortable={false} />
        <TextField source="id" label="Candidate ID" sortable />
        <TextField source="firstName" sortable />
        <TextField source="lastName" sortable />
        <TextField source="nickName" sortable />
        <TextField source="title" sortable />
        <EmailField source="workEmail" sortable />
        <EmailField source="personalEmail" sortable />
        <LinkField phone source="homePhone">
          <TextField source="homePhone" label="Home Phone" sortable />
        </LinkField>
        <LinkField phone source="mobile">
          <TextField source="mobile" sortable />
        </LinkField>
        <LinkField phone source="otherPhone">
          <TextField source="otherPhone" sortable />
        </LinkField>
        <TextField source="birthday" sortable />
        <TextField source="referredBy" sortable />

        <CustomDateField source="availabilityDate" label="Availability" sortable />
        <CustomDateField source="lastLoginOnMobileAppDate" label="Last login in mobile app" sortable />
        <CustomDateField source="availabilityDateLastUpdated" label="Avail Last Updated" sortable />
        <CustomBooleanField label="Do Not Hire" source="doNotHire" sortable />
        <CustomBooleanField label="Military Exp" source="hasMilitaryExperience" sortable />
        <CustomBooleanField label="Comp. Wizard" source="completedWizard" sortable />
        <TextField source="addresses[0].address" label="Address" sortable={false} />
        <TextField source="addresses[0].unitNumber" label="Unit" sortable={false} />
        <TextField source="addresses[0].city" label="City" sortable={false} />
        <TextField source="addresses[0].state" label="State" sortable={false} />
        <TextField source="addresses[0].country" label="Country" sortable={false} />
        <TextField source="addresses[0].zip" label="Zip" sortable={false} />

        <CustomDateField source="updatedAt" label="Updated At" sortable />
        <TextField source="updatedBy" label="Updated By" sortable />
        <TextField source="updatedByName" label="Updated By Name" sortable />
        <ActionsColumn label="Actions" />
      </DatagridConfigurableRbac>
    </List>
  );
};
