import React from 'react';
import { EditProps, SaveButton } from 'react-admin';
import { RESOURCE_PLACEMENT_COMMENTS } from '../../provider/dataProvider/constants';
import { CommentsTab } from '../Comment/CommentsTab';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { useCanAccess } from '../../hooks/useCanAccess';
import { actionPermissions } from '../Permissions/constants';
import { AdditionalInfoTab } from './tabs/AdditionalInfoTab';
import { DocumentsTab } from './tabs/DocumentsTab/DocumentsTab';
import { HeaderTab } from './tabs/HeaderTab';
import { JobInfoTab } from './tabs/JobInfoTab';

export interface PlacementEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation?: boolean;
  expandView?: boolean;
}

export const PlacementEdit: React.FC<PlacementEditProps> = (props) => {
  const showActions = useCanAccess(actionPermissions.edit);

  return (
    <TabbedEditForm {...props} footerToolbarProps={{ SaveButtonComponent: <SaveButton label="Save" variant="text" /> }}>
      <LazyFormTab label="Placement">
        <HeaderTab />
      </LazyFormTab>

      <LazyFormTab label="Comments">
        <CommentsTab reference={RESOURCE_PLACEMENT_COMMENTS} withActions={showActions} />
      </LazyFormTab>

      <LazyFormTab label="Job Info">
        <JobInfoTab editMode />
      </LazyFormTab>
      <LazyFormTab label="Additional Info">
        <AdditionalInfoTab withActions={showActions} />
      </LazyFormTab>
      <LazyFormTab label="Documents">
        <DocumentsTab withActions={showActions} />
      </LazyFormTab>
    </TabbedEditForm>
  );
};
