import React from 'react';
import { Filter, FilterProps } from 'react-admin';
import FreeTextSearchInput from '../../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import InvertedBooleanInput from '../../Common/inputs/InvertedBooleanInput';

export const ClientFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props} variant="outlined">
    <FreeTextSearchInput source="q" alwaysOn fullTextSearch />
    <InvertedBooleanInput source="isActive" label="Archived" alwaysOn />
  </Filter>
);
