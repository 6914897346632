import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { Button, Identifier } from 'react-admin';
import { useAddManyToFavoriteList } from '../../Shared/favoriteList/useAddManyToFavoriteList';
import FavoriteListSelectionDialog from './FavoriteListSelectionDialog';

export type AddToFavoriteListButtonProps = {
  selectedIds: Identifier[];
  onSuccess?: () => void;
};

const AddToFavoriteListButton: React.FC<AddToFavoriteListButtonProps> = ({ selectedIds = [], onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const addManyToFavoriteList = useAddManyToFavoriteList();

  const onClicked = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button label="Add to Favorite List" onClick={onClicked} disabled={selectedIds.length === 0}>
        <PlaylistAddIcon />
      </Button>

      {isOpen && (
        <FavoriteListSelectionDialog
          onClose={() => setIsOpen(false)}
          onSelect={async (record) => {
            await addManyToFavoriteList(record.id, record.name);
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

AddToFavoriteListButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddToFavoriteListButton;
