import { Create, CreateProps, maxLength, required, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';

export const FavoriteListCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} actions={false} title="Create Favorite List">
      <SimpleForm warnWhenUnsavedChanges toolbar={<FooterToolbar />} style={commonStyles.flexBox}>
        <TextInput source="name" validate={[required(), maxLength(50)]} sx={commonStyles.flexBoxLongItem} />
      </SimpleForm>
    </Create>
  );
};
