import React from 'react';
import { TabbedShowLayout } from 'react-admin';
import { ShowPropsWithLocation } from '../../types';
import Show from '../ShowComponent/ShowComponent';

export type ShowLayoutProps = ShowPropsWithLocation & {
  expandView?: boolean;
};

export const ShowLayout: React.FC<ShowLayoutProps> = ({
  expandView,
  children,
  syncWithLocation: syncWithLocationFromProps,
  ...props
}) => {
  const title = expandView ? false : props.title;
  const syncWithLocation = expandView ? false : syncWithLocationFromProps;

  return (
    <Show {...props} title={title} withoutActions={expandView}>
      <TabbedShowLayout syncWithLocation={syncWithLocation}>{children}</TabbedShowLayout>
    </Show>
  );
};
