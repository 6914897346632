const styles = {
  imageField: {
    backgroundColor: '#fafafa',
    borderRadius: 2,
  },
  imageInput: {
    '& .RaFileInput-dropZone': {
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    },
  },
};

export default styles;
