import { SxProps, Theme } from '@mui/material/styles';
import React, { FC } from 'react';
import { Validator } from 'react-admin';
import { FreeSoloAutocompleteInput } from '../../Common/inputs/FreeSoloAutocompleteInput';
import { commonStyles } from '../../CommonStyles';
import { resourceChoices } from '../constants';
import { Option } from '../types';

type ResourceInputProps = {
  validate?: Validator | Validator[];
  sx?: SxProps<Theme>;
  alwaysOn?: boolean;
  fullWidth?: boolean;
};

export const ResourceInput: FC<ResourceInputProps> = (props) => {
  return (
    <FreeSoloAutocompleteInput<Option, false, false>
      source="resource"
      options={resourceChoices}
      defaultValue=""
      getInputValue={(idOrText: string) => resourceChoices.find((item) => item.id === idOrText)?.name ?? idOrText}
      getSelectedValue={(option) => option.id}
      getOptionLabel={(option) => (option as Option)?.name || (option as string)}
      sx={commonStyles.formComponentDefaultMargins}
      {...props}
    />
  );
};
