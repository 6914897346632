import { Identifier } from 'ra-core';
import React from 'react';
import { RESOURCE_CANDIDATE_DOCUMENTS } from '../../../provider/dataProvider/constants';
import ParseCVButton from '../../Common/buttons/ParseCVButton';
import ImportSkillsDialog from '../../Skills/ImportSkillsDialog';
import { DocumentsTabV4 } from '../../Shared/DocumentsTabV4';
import { UploadDocumentV4 } from '../../Common/UploadDocument/UploadDocumentV4';

type DocumentsTabProps = {
  candidateId: Identifier;
  withActions?: boolean;
};

export const DocumentsTab: React.FC<DocumentsTabProps> = ({ withActions = false, candidateId }) => {
  const [skillsDialogOpen, setSkillsDialogOpen] = React.useState(false);
  const [document, setDocument] = React.useState(false);

  const setDocumentId = (document) => {
    setDocument(document);
    setSkillsDialogOpen(true);
  };

  const closeDialog = () => {
    setSkillsDialogOpen(!skillsDialogOpen);
    setDocument(false);
  };

  return (
    <>
      <DocumentsTabV4
        reference={RESOURCE_CANDIDATE_DOCUMENTS}
        showMode={!withActions}
        actionColumnChildren={<ParseCVButton label="Parse CV" source="id" onClick={setDocumentId} />}
        footerActions={
          <UploadDocumentV4 idField="candidateId" idValue={candidateId} resource={RESOURCE_CANDIDATE_DOCUMENTS} />
        }
      />
      <ImportSkillsDialog isOpen={skillsDialogOpen} onClose={closeDialog} document={document} />
    </>
  );
};
