import types from '../actions/types';
import genericResponseReducer from './genericResponseReducer';

export const ROOM_CHAT_MESSAGES_INITIAL_STATE = {
  chatMessages: {
    loading: false,
    loaded: false,
    error: '',
    messages: [],
  },
};

const INITIAL_STATE = {
  rooms: {}, //Rooms map object.<Key:RoomId, Value: Room data>
  /** Each value on rooms object's structure will be
   * {
   *     id  room-id
   *     candidateId
   *     candidateName
   * 	   numberOfMessages
   * 	   numberOfUnReadMessages
   * 	   dateStarted
   * 	   lastMessageDate
   * 	   isArchived
   * 	   chatMessages : { see ROOM_CHAT_MESSAGES_INITIAL_STATE
   * 	       loading : false,
   * 	       loaded: false,
   * 	       error: "",
   * 	       messages:[] //Initially will be empty array
   * 	   }
   * }
   */
  selectedRoomId: undefined,
  archived: false,
  loading: false,
  loaded: false,
  error: '',
  candidates: {}, // Candidates map object. <Key: candidateId, Value: Candidate data returned by /api/candidate/${id}
  fieldManagers: {}, // FieldManagers map object. <Key: fieldManagerId, Value: FieldManager data returned by /api/lookup/fieldmanager/${id}
  receivedMessageCounter: 0, // Indicates how many messages received. Used to play a sound when new message received. See NavBarChatMessage component
  totalUnReadMessages: 0, // Indicates total number of UnRead messages at all rooms
};

const getTotalUnReadMessages = (rooms) => {
  let counter = 0;
  for (let roomId in rooms) {
    if (rooms[roomId].unreadMessages > 0) {
      counter += rooms[roomId].unreadMessages;
    }
  }
  return counter;
};

const chatKitReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return { ...INITIAL_STATE };
  }

  switch (action.type) {
    case types.chatkit.CHAT_ROOMS_LOADING:
    case types.chatkit.CHAT_ROOMS_LOADED:
    case types.chatkit.CHAT_ROOMS_ERROR: {
      const updatedState = genericResponseReducer(
        state,
        action,
        types.chatkit.CHAT_ROOMS_LOADING,
        types.chatkit.CHAT_ROOMS_LOADED,
        types.chatkit.CHAT_ROOMS_ERROR,
        (actionData) => {
          return { rooms: actionData };
        }
      );
      updatedState.totalUnReadMessages = getTotalUnReadMessages(updatedState.rooms);
      return updatedState;
    }
    case types.chatkit.CHAT_ROOMS_ADD_ROOM: {
      const rooms = { ...state.rooms, [action.room.id]: action.room };
      return {
        ...state,
        rooms,
        totalUnReadMessages: getTotalUnReadMessages(rooms),
      };
    }
    case types.chatkit.CHAT_ROOM_SELECT: {
      if (state.rooms[action.selectedRoomId]) {
        const room = state.rooms[action.selectedRoomId];
        const updatedRoom = {
          ...room,
          unreadMessages: 0,
        };
        const rooms = { ...state.rooms, [action.selectedRoomId]: updatedRoom };
        return {
          ...state,
          selectedRoomId: action.selectedRoomId,
          rooms,
          totalUnReadMessages: getTotalUnReadMessages(rooms),
        };
      } else {
        return state;
      }
    }

    case types.chatkit.CHAT_ROOM_ARCHIVE: {
      return {
        ...state,
        archived: !state.archived,
      };
    }

    case types.chatkit.CHAT_ROOM_LOADING:
    case types.chatkit.CHAT_ROOM_LOADED:
    case types.chatkit.CHAT_ROOM_ERROR: {
      const room = state.rooms[state.selectedRoomId];
      const newChatMessagesState = genericResponseReducer(
        room.chatMessages,
        action,
        types.chatkit.CHAT_ROOM_LOADING,
        types.chatkit.CHAT_ROOM_LOADED,
        types.chatkit.CHAT_ROOM_ERROR,
        (actionData) => {
          return { messages: actionData.chatMessages };
        }
      );
      const rooms = {
        ...state.rooms,
        [state.selectedRoomId]: { ...room, chatMessages: newChatMessagesState },
      };
      return {
        ...state,
        rooms,
        totalUnReadMessages: getTotalUnReadMessages(rooms),
      };
    }
    case types.chatkit.CHAT_ROOMS_CANDIDATES_UPDATE: {
      return {
        ...state,
        candidates: {
          ...state.candidates,
          ...action.data,
        },
      };
    }
    case types.chatkit.CHAT_ROOMS_FIELDMANAGERS_UPDATE: {
      return {
        ...state,
        fieldManagers: {
          ...state.fieldManagers,
          ...action.data,
        },
      };
    }

    case types.chatkit.CHAT_ROOM_MESSAGE_RECEIVED: {
      const roomId = '' + action.message.chatSessionId;

      if (roomId && state.rooms[roomId]) {
        const room = state.rooms[roomId];
        const updatedRoom = {
          ...room,
          numberOfMessages: room.numberOfMessages + 1,
          lastMessageDate: action.message.dateSent,
          chatMessages: {
            ...room.chatMessages,
            messages: [...room.chatMessages.messages, action.message],
          },
        };
        const receivedMessageCounter = action.isEchoMessage
          ? state.receivedMessageCounter
          : state.receivedMessageCounter + 1;

        if (!action.dontUpdateUnReadMessages) {
          if (updatedRoom.unreadMessages) {
            updatedRoom.unreadMessages += 1;
          } else {
            updatedRoom.unreadMessages = 1;
          }
        }
        const rooms = { ...state.rooms, [roomId]: updatedRoom };

        return {
          ...state,
          rooms,
          receivedMessageCounter,
          totalUnReadMessages: getTotalUnReadMessages(rooms),
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export default chatKitReducer;
