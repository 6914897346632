import { FC } from 'react';
import { ListActionsProps } from 'react-admin';
import { colors } from '../../../theme';
import { SidebarFilterToggleButton } from '../../Common/filter/SidebarFilterToggleButton';
import CommonListActions from '../../Common/ListActions/ListActions';
import LegendButton from '../../LegendButton';
import CandidateBasketButton from './CandidateBasketButton';
import { CandidateFilter } from './CandidateFilter';

export const ListActions: FC<ListActionsProps> = () => {
  return (
    <CommonListActions showAction={{ selectAutoSave: true }}>
      <SidebarFilterToggleButton />
      <CandidateFilter context="button" />
      <CandidateBasketButton />
      <LegendButton
        legends={[
          {
            label: 'Do not hire',
            color: colors.indicator.black,
          },
          {
            label: 'Submitted to Job(s)',
            color: colors.indicator.purple,
          },
          {
            label: 'Not available',
            color: colors.indicator.red,
          },
          {
            label: 'Available within 5 months',
            color: colors.indicator.yellow,
          },
          {
            label: 'Available [Last month - Next 2 months]',
            color: colors.indicator.green,
          },
          {
            label: 'No Availability Date',
            color: colors.indicator.grey,
          },
        ]}
      />
    </CommonListActions>
  );
};
