import { FC } from 'react';
import { SimpleDatagrid } from '../../Common/SimpleDatagrid/SimpleDatagrid';
import {
  RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES,
  RESOURCE_HIGH_LEVEL_INDUSTRY,
} from '../../../provider/dataProvider/constants';

type IndustriesTabProps = {
  clientId: string | number;
  withActions?: boolean;
};

export const IndustriesTab: FC<IndustriesTabProps> = ({ withActions = false, clientId }) => {
  return (
    <SimpleDatagrid
      resource={RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES}
      target="highlevelindustry"
      lookupResource={RESOURCE_HIGH_LEVEL_INDUSTRY}
      label="Industry"
      defaultValues={{ clientId }}
      withActions={withActions}
    />
  );
};
