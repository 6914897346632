import { FC } from 'react';
import { Datagrid, NumberField, TextField } from 'react-admin';
import { RESOURCE_JOB } from '../../../provider/dataProvider/constants';
import { ReferenceManyField } from '../../Common/fields/ReferenceManyField';
import { commonStyles } from '../../CommonStyles';
import LinkField from '../../LinkField';
import ActionsColumn from '../../ActionsColumn';
import { MAX_RECORD_PER_PAGE } from '../../../provider/constants';
import { Empty } from '../../Common/Empty/Empty';
import CustomDateField from '../../Common/fields/CustomDateField';

type JobsTabProps = {
  projectId: string | number;
  withActions?: boolean;
};

export const JobsTab: FC<JobsTabProps> = ({ withActions = false, projectId }) => {
  return (
    <ReferenceManyField
      reference={RESOURCE_JOB}
      target="projectId"
      perPage={MAX_RECORD_PER_PAGE}
      sort={{ field: 'jobTitle', order: 'ASC' }}
      sx={commonStyles.fullWidth}
      alwaysDisplayWrapper
      filter={{
        projectId,
        isActive: true,
      }}
    >
      <Datagrid rowClick={false} bulkActionButtons={false} empty={<Empty resourceName="jobs" />}>
        <LinkField source="id" resource={RESOURCE_JOB} showMode={!withActions}>
          <TextField source="id" label="Job ID" sortable />
        </LinkField>
        <TextField source="jobTitle" sortable />
        <NumberField source="numberOfPositions" label="Number Of Pos" sortable />
        <CustomDateField source="startDate" label="Start" sortable />
        <CustomDateField source="endDate" label="End" sortable />
        <TextField source="region" sortable />
        <TextField source="client.name" label="Client" />
        {withActions && <ActionsColumn label="Actions" />}
      </Datagrid>
    </ReferenceManyField>
  );
};
