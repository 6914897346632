import { Filter, FilterProps, List, ListProps, TextField } from 'react-admin';
import React from 'react';
import ActionsColumn from '../ActionsColumn';
import FreeTextSearchInput from '../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import InvertedBooleanInput from '../Common/inputs/InvertedBooleanInput';
import { commonStyles } from '../CommonStyles';
import { PostPagination } from '../PostPagination';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import CustomDateField from '../Common/fields/CustomDateField';
import { ArchiveToggleButton } from '../Common/buttons/ArchiveToggleButton';
import { JobEdit } from './JobEdit';
import { JobShow } from './JobShow';

const rowStyle = (record) => {
  const style = {};
  if (!record) {
    return style;
  }
  return {
    ...style,
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

const JobFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput source="q" alwaysOn fullTextSearch />
      <InvertedBooleanInput source="isActive" label="Archived" alwaysOn />
    </Filter>
  );
};

const JobActions = () => (
  <ListActions showAction={{ selectAutoSave: true }}>
    <JobFilter context="button" />
  </ListActions>
);

export const JobsList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Jobs"
      {...props}
      sort={{ field: 'jobTitle', order: 'ASC' }}
      filters={<JobFilter />}
      actions={<JobActions />}
      pagination={<PostPagination />}
      perPage={50}
      filterDefaultValues={{ isActive: true }}
      sx={commonStyles.raFilterForm}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        expandSingle
        editComponent={<JobEdit expandView />}
        showComponent={<JobShow expandView />}
        rowSx={rowStyle}
        bulkActionButtons={false}
        omit={[
          'perDiem',
          'msp',
          'straightTimeBillRate',
          'overtimeBillRate',
          'doubleTimeBillRate',
          'straightTimePayRate',
          'overtimePayRate',
          'doubleTimePayRate',
          'createdByName',
          'updatedByName',
        ]}
      >
        <TextField key="id" source="id" label="Job ID" sortable />
        <TextField key="client.name" source="client.name" label="Client" sortable />
        <TextField key="jobTitle" source="jobTitle" sortable />
        <TextField key="numberOfPositions" source="numberOfPositions" label="Number of Pos." sortable />
        <CustomDateField key="startDate" source="startDate" label="Start" sortable />
        <CustomDateField key="endDate" source="endDate" label="End" sortable />
        <CustomDateField key="dateAdded" source="dateAdded" sortable />
        <TextField key="region" source="region" sortable />
        <TextField key="straightTimeBillRate" source="straightTimeBillRate" sortable />
        <TextField key="overtimeBillRate" source="overtimeBillRate" sortable />
        <TextField key="perDiem" source="perDiem" sortable />
        <TextField key="msp" source="msp" sortable={false} />
        <TextField key="doubleTimeBillRate" source="doubleTimeBillRate" sortable />
        <TextField key="straightTimePayRate" source="straightTimePayRate" sortable />
        <TextField key="overtimePayRate" source="overtimePayRate" sortable />
        <TextField key="doubleTimePayRate" source="doubleTimePayRate" sortable />
        <TextField key="assignedToName" source="fieldManagerAssignedTo.name" label="Assigned To" sortable />
        <CustomDateField source="updatedAt" label="Updated At" sortable />
        <TextField source="updatedBy" label="Updated By" sortable />
        <TextField source="updatedByName" label="Updated By Name" sortable />

        <ActionsColumn label="Actions" customDeleteButton={<ArchiveToggleButton />} />
      </DatagridConfigurableRbac>
    </List>
  );
};
