import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { ChangeEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import { TextInputProps, useInput, useListContext } from 'react-admin';
import { HelpInfo } from './HelpInfo';
import { styles } from './styles';

type FreeTextSearchInputProps = Omit<Partial<TextInputProps>, 'InputProps'> & {
  fullTextSearch?: boolean;
  InputProps?: false | TextInputProps['InputProps'];
};

const FreeTextSearchInput: React.FC<FreeTextSearchInputProps> = ({
  label = 'Free Text Search',
  source = 'q',
  fullTextSearch = false,
  InputProps,
  ...rest
}) => {
  const inputProps = InputProps === false ? undefined : defaultInputProps;

  const { field } = useInput({ source });
  const [value, setValue] = useState(field.value || '');
  const { filterValues } = useListContext();

  useEffect(() => {
    if (isEmpty(filterValues[source])) {
      setValue('');
    }
  }, [filterValues]);

  const handleEnterClick: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter') {
      field.onChange(value.trim());
    }
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    setValue(e.target.value);
    if (!e.target.value) {
      field.onChange('');
    }
  };

  return (
    <Box sx={styles.container}>
      <TextField
        {...rest}
        label={label}
        value={value}
        InputProps={inputProps}
        onChange={handleChange}
        onKeyUp={handleEnterClick}
        sx={styles.input}
      />
      {fullTextSearch && <HelpInfo />}
    </Box>
  );
};

const defaultInputProps = {
  endAdornment: (
    <InputAdornment position="end">
      <IconButton>
        <SearchIcon />
      </IconButton>
    </InputAdornment>
  ),
};

export default FreeTextSearchInput;
