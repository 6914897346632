import * as React from 'react';
import PropTypes from 'prop-types';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Button, Identifier, useNotify, useRefresh, useUnselectAll } from 'react-admin';
import { RESOURCE_JOB_RESPONSE } from '../../provider/dataProvider/constants';
import { useDataProvider } from '../../provider/dataProvider/useDataProvider';

export type AddProspectProps = {
  selectedIds: Identifier[];
  jobId: string;
  resource?: string;
  isRenderContentOnly?: boolean;
  onSuccess?: () => void;
  responseInfo?: any;
};

const AddProspectButton: React.FC<AddProspectProps> = ({ selectedIds = [], jobId, resource }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(resource);

  const handleAddProspect = async () => {
    let successCount = 0;
    let errorCount = 0;

    for (const candidateId of selectedIds) {
      await dataProvider
        .create(RESOURCE_JOB_RESPONSE, {
          data: {
            jobId,
            candidateId: candidateId,
            prospectStatusId: 1,
          },
        })
        .then(({ data }) => {
          successCount++;
        })
        .catch((error) => {
          console.log('Error', error);
          errorCount++;
        });
    }

    // Show summary notification
    if (successCount > 0) {
      notify(`Successfully added ${successCount} candidate(s) as prospect`, { type: 'info', undoable: false });
    }

    if (errorCount > 0) {
      notify(`Failed to add ${errorCount} candidate(s)`, { type: 'warning', undoable: false });
    }

    if (successCount > 0) {
      unselectAll();
      refresh();
    }
  };

  return (
    <Button label="Add prospects" onClick={handleAddProspect} disabled={false}>
      <AssignmentTurnedInIcon />
    </Button>
  );
};

AddProspectButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddProspectButton;
