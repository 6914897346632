import React, { useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import RemoveMultipleButton from '../RemoveMultipleButton';
import SendNotificationButton from '../SendNotificationButton';
import { RESOURCE_CANDIDATE } from '../../../provider/dataProvider/constants';

type BulkActionButtonsProps = {
  jobId: string;
};

export const BulkActionButtons: React.FC<BulkActionButtonsProps> = ({ jobId }) => {
  const { data: jobResponses, onUnselectItems, selectedIds } = useListContext();
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);

  useEffect(() => {
    const _selectedCandidateIds = selectedIds.reduce((acc, selectedId) => {
      const jobResponse = jobResponses.find((response) => response.id === selectedId);
      if (jobResponse) {
        acc.push(jobResponse.candidateId);
      }
      return acc;
    }, []);
    setSelectedCandidateIds(_selectedCandidateIds);
  }, [JSON.stringify(jobResponses), JSON.stringify(selectedIds)]);

  if (jobId && selectedIds && jobResponses) {
    return (
      <>
        <RemoveMultipleButton jobId={jobId} selectedIds={selectedIds} clearSelection={() => onUnselectItems()} />

        <SendNotificationButton resource={RESOURCE_CANDIDATE} jobId={jobId} selectedIds={selectedCandidateIds} />
      </>
    );
  } else {
    return <></>;
  }
};
