import React from 'react';
import { useListContext } from 'react-admin';
import AddToContactFavoriteButton from '../../Common/buttons/AddToContactFavoriteButton';
import AddProspectNotifyWithJobSelectButton from '../../Job/AddProspectNotifyWithJobSelectButton';
import SendNotificationButton from '../../Job/SendNotificationButton';

export const CandidatesListBulkActionButtons: React.FC = () => {
  const { selectedIds } = useListContext();

  return (
    <>
      <AddToContactFavoriteButton selectedIds={selectedIds} />
      <SendNotificationButton selectedIds={selectedIds} />
      <AddProspectNotifyWithJobSelectButton selectedIds={selectedIds} />
    </>
  );
};
