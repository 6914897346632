import Box from '@mui/material/Box';
import React from 'react';
import { EditProps, maxLength, NumberInput, required, TextInput, useRecordContext } from 'react-admin';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import { commonStyles } from '../CommonStyles';
import CustomDateInputV3 from '../CustomDateInputV3';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { CandidatesList } from './components/CandidatesList';

export interface FavoriteListEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

const FavoriteListEditTitle = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return <span>Favorite List {record.name}</span>;
};

export const FavoriteListEdit: React.FC<FavoriteListEditProps> = (props) => {
  return (
    <TabbedEditForm {...props} title={props.title === undefined ? <FavoriteListEditTitle /> : false}>
      <LazyFormTab label="Favorite List">
        <Box sx={commonStyles.flexBox}>
          <NumberInput source="id" label="ID" sx={commonStyles.flexBoxItem} readOnly />
          <TextInput source="name" validate={[required(), maxLength(50)]} sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <CustomDateInputV3 source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} disabled />
        </Box>
        <CandidatesList />
      </LazyFormTab>
    </TabbedEditForm>
  );
};
