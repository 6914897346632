import { usePermissions, useResourceContext } from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';

export function useCanAccess(action: string, resourceName?: string) {
  const resourceContext = useResourceContext();
  const resource = resourceName || resourceContext;
  const { permissions } = usePermissions();

  return canAccess({
    action: action,
    permissions,
    resource,
  });
}
