import { Identifier, useDataProvider } from 'react-admin';
import { RESOURCE_FAVORITE_CANDIDATE } from '../../../provider/dataProvider/constants';

export const useAddToFavoriteList = () => {
  // We can't use the useCreate() hook because it invalidates queries after each request,
  // which we don't need to do when we call it multiple times
  const dataProvider = useDataProvider();

  return async (favoriteId: Identifier, candidateId: Identifier) =>
    dataProvider.create(RESOURCE_FAVORITE_CANDIDATE, {
      data: { FavoriteId: favoriteId, CandidateId: candidateId },
    });
};
