import { FC } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import { actionChoices } from '../constants';

type ActionInputProps = Omit<SelectInputProps, 'source' | 'choices' | 'format'>;

export const ActionInput: FC<ActionInputProps> = (props) => {
  return (
    <SelectInput
      source="action"
      label="Role Action"
      choices={actionChoices}
      sx={commonStyles.formComponentDefaultMargins}
      {...props}
    />
  );
};
