import { FC } from 'react';
import { SimpleDatagrid } from '../../Common/SimpleDatagrid/SimpleDatagrid';
import { RESOURCE_LOOKUP_SUBINDUSTRY, RESOURCE_PROJECT_SUB_INDUSTRY } from '../../../provider/dataProvider/constants';

type IndustriesTabProps = {
  projectId: string | number;
  withActions?: boolean;
};

export const IndustriesTab: FC<IndustriesTabProps> = ({ withActions = false, projectId }) => {
  return (
    <SimpleDatagrid
      label="Industry"
      resource={RESOURCE_PROJECT_SUB_INDUSTRY}
      target="projectId"
      lookupResource={RESOURCE_LOOKUP_SUBINDUSTRY}
      textSource="subIndustry.name"
      selectSource="subIndustryId"
      defaultValues={{ projectId }}
      withActions={withActions}
    />
  );
};
