import { FC } from 'react';
import { Filter, FilterProps } from 'react-admin';
import FreeTextSearchInput from '../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import InvertedBooleanInput from '../Common/inputs/InvertedBooleanInput';

type UserFilterProps = Omit<FilterProps, 'children'>;

export const UserFilter: FC<UserFilterProps> = (props) => {
  return (
    <Filter {...props}>
      <FreeTextSearchInput alwaysOn />
      <InvertedBooleanInput source="isActive" label="Show Inactive Field Managers" alwaysOn />
    </Filter>
  );
};
