import { Datagrid, EmailField, TextField } from 'react-admin';
import React from 'react';
import { RESOURCE_PROJECT, RESOURCE_PROJECT_CONTACT } from '../../provider/dataProvider/constants';
import ActionsColumn from '../ActionsColumn';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { ReferenceManyField } from '../Common/fields/ReferenceManyField';
import LinkField from '../LinkField';
import { Empty } from '../Common/Empty/Empty';
import { FavoriteCandidateButton } from '../ClientContacts/FavoriteCandidateButton';
import { useCanAccess } from '../../hooks/useCanAccess';
import { actionPermissions } from '../Permissions/constants';
import { AddProjectContacts } from './AddProjectContacts';
import { ProjectContactEditInDialog } from './ProjectContactEditInDialog';

interface Props {
  clientId?: string | number;
  projectId?: string | number;
  withActions?: boolean;
}

export const ProjectContactList: React.FC<Props> = ({ projectId, withActions = false }) => {
  const canCreate = useCanAccess(actionPermissions.create, RESOURCE_PROJECT); //It should be RESOURCE_PROJECT_CONTACT
  const canEdit = useCanAccess(actionPermissions.edit, RESOURCE_PROJECT);
  const isRenderEdit = canEdit && withActions;
  const isRenderCreate = canCreate && withActions;

  return (
    <ReferenceManyField
      reference={RESOURCE_PROJECT_CONTACT}
      target="projectId"
      perPage={MAX_RECORD_PER_PAGE}
      sort={{ field: 'clientContact.firstName', order: 'ASC' }}
    >
      {isRenderCreate && <AddProjectContacts projectId={+projectId} />}
      <Datagrid
        rowClick={false}
        bulkActionButtons={false}
        empty={!withActions && <Empty resourceName={RESOURCE_PROJECT_CONTACT} withWrapper />}
      >
        <TextField source="clientContact.firstName" label="First Name" sortable />
        <TextField source="clientContact.lastName" label="Last Name" sortable />
        <TextField source="clientContact.jobTitle" label="Job Title" sortable />
        <EmailField source="clientContact.address.email" label="Email" sortable={false} />
        <LinkField phone source="clientContact.address.phone" label="Phone" sortable={false}>
          <TextField source="clientContact.address.phone" label="Phone" sortable={false} />
        </LinkField>
        <EmailField source="clientContact.address.alternativeEmail" label="Alt Email" sortable={false} />
        <TextField source="clientContact.address.alternativePhone" label="Alt Phone" sortable={false} />
        <TextField source="clientContact.address.addressLine1" label="Address" sortable={false} />
        <TextField source="clientContact.address.country" label="Country" sortable={false} />
        <TextField source="clientContact.address.state" label="State" sortable={false} />
        <TextField source="clientContact.address.city" label="City" sortable={false} />

        {withActions && (
          <ActionsColumn label="Actions" actionsSettings={{ show: false, edit: false }}>
            <FavoriteCandidateButton />
            {isRenderEdit && <ProjectContactEditInDialog />}
          </ActionsColumn>
        )}
      </Datagrid>
    </ReferenceManyField>
  );
};
