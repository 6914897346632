import * as React from 'react';
import { Datagrid, FunctionField, List, TextField } from 'react-admin';
import moment from 'moment/moment';
import { RESOURCE_CANDIDATE, RESOURCE_PLACEMENT } from '../../provider/dataProvider/constants';
import LinkField from '../LinkField';
import { Empty } from '../Common/Empty/Empty';
import CustomDateField from '../Common/fields/CustomDateField';
import { ISO_DATE_FORMAT } from '../../provider/constants';
import { ALL_2099, DEFAULT_ROWS_PER_PAGE, featureChoices } from './constants';
import { FilterForm } from './components/FilterForm';

export const PlacementEnding: React.FC = () => {
  const defaultFilter = {
    startDate_lt: moment().format(ISO_DATE_FORMAT),
    endDate_gte: moment().format(ISO_DATE_FORMAT),
    endDate_lte: ALL_2099,
    isActive: true,
  };

  return (
    <List
      title=" "
      storeKey="dashboard-placement-ending"
      resource={RESOURCE_PLACEMENT}
      filterDefaultValues={defaultFilter}
      sort={{ field: 'endDate', order: 'ASC' }}
      perPage={DEFAULT_ROWS_PER_PAGE}
      disableSyncWithLocation
      actions={
        <FilterForm
          title="Placements Ending Soon"
          label="Ending within"
          source="endDate"
          choices={featureChoices}
          fieldName="endDate_lte"
        />
      }
    >
      <Datagrid
        rowClick={false}
        resource={RESOURCE_CANDIDATE}
        bulkActionButtons={false}
        empty={<Empty resourceName="placements" />}
      >
        <LinkField source="candidateId" label="Candidate" resource={RESOURCE_CANDIDATE} sortable={false}>
          <FunctionField
            render={(record) => `${record.candidate.firstName} ${record.candidate.lastName}`}
            sortable={false}
          />
        </LinkField>
        <LinkField source="id" label="Job Title" resource={RESOURCE_PLACEMENT}>
          <TextField source="job.jobTitle" sortable />
        </LinkField>
        <CustomDateField source="startDate" sortable />
        <CustomDateField source="endDate" sortable />
      </Datagrid>
    </List>
  );
};
