import { IconButton } from '@mui/material';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as PDFIcon } from '../../../assets/PDF.svg';
import { PreviewDialog } from '../dialogs/PreviewDialog/PreviewDialog';

export const PreviewButton = () => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);

  // Return empty if URL is empty or fileName doesn't end with .pdf (case insensitive)
  if (isEmpty(record.url) || !record.fileName?.toLowerCase().endsWith('.pdf')) return <></>;

  const openDialog = () => setShowDialog(true);
  const closeDialog = () => setShowDialog(false);

  return (
    <>
      <IconButton size="small" title="Preview" onClick={openDialog}>
        <PDFIcon />
      </IconButton>
      <PreviewDialog title={record.fileName} open={showDialog} onClose={closeDialog}>
        <PDFViewer
          url={record.url}
          tools={['pager', 'spacer', 'zoomInOut', 'zoom', 'selection', 'spacer', 'search']}
          defaultZoom={1}
        />
      </PreviewDialog>
    </>
  );
};
