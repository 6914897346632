import {
  BooleanField,
  DeleteWithConfirmButton,
  FunctionField,
  Link,
  List,
  ListProps,
  RaRecord,
  TextField,
  useRecordContext,
} from 'react-admin';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import { FC } from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import moment from 'moment';
import * as React from 'react';
import { isEmpty } from '../../provider/UtilityFunctions';
import { commonStyles } from '../CommonStyles';
import LegendButton from '../LegendButton';
import { PostPagination } from '../PostPagination';
import ActionsColumn from '../ActionsColumn';
import { RESOURCE_CANDIDATE, RESOURCE_CLIENT, RESOURCE_PROJECT } from '../../provider/dataProvider/constants';
import { colors } from '../../theme';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import ListActions from '../Common/ListActions/ListActions';
import CustomDateField from '../Common/fields/CustomDateField';
import { ArchiveToggleButton } from '../Common/buttons/ArchiveToggleButton';
import LinkField from '../LinkField';
import { PlacementEdit } from './PlacementEdit';
import { PlacementFilter } from './PlacementFilter';
import { PlacementShow } from './PlacementShow';

const CandidateLinkField: FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();

  return (
    <Link to={`/${RESOURCE_CANDIDATE}/${record?.candidateId}`}>
      <TextField source={source} sortable />
    </Link>
  );
};

const getBackGroundColor = (record: RaRecord) => {
  if (moment(record.endDate).isBefore(moment()) && record.isActive) {
    return colors.indicator.red;
  }
  if (
    isEmpty(record['onboardingComplete']) ||
    isEmpty(record['drugScreenComplete']) ||
    isEmpty(record['physicalComplete']) ||
    isEmpty(record['trainingComplete']) ||
    isEmpty(record['backgroundCheckComplete']) ||
    isEmpty(record['clientTrainingComplete']) === !record['bypassClientTraining'] //only one should be populated
  ) {
    return colors.indicator.peach;
  }

  return undefined;
};

export const placementRowStyle = (record: RaRecord) => {
  return {
    backgroundColor: getBackGroundColor(record),
  };
};

const PlacementActions: FC = () => {
  const excludedColumns = [
    'candidateId',
    'placementGuid',
    'drugScreenComplete',
    'backgroundCheckComplete',
    'physicalComplete',
    'trainingComplete',
    'onboardingComplete',
    'fieldManagerId',
    'isActive',
    'perDiem',
    'msp',
  ];
  return (
    <ListActions showAction={{ selectAutoSave: true, create: false }} showActionProps={{ export: { excludedColumns } }}>
      <LegendButton
        legends={[
          { label: 'Approved', color: colors.surface.white },
          { label: 'Pending', color: colors.indicator.peach },
          { label: 'Expired', color: colors.indicator.red },
        ]}
      />
      <PlacementFilter context="button" />
    </ListActions>
  );
};

export const PlacementsList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<PlacementFilter />}
      perPage={50}
      pagination={<PostPagination />}
      filterDefaultValues={{
        isActive: true,
      }}
      actions={<PlacementActions />}
      sort={{ field: 'endDate', order: 'DESC' }}
      sx={commonStyles.raFilterForm}
    >
      <DatagridConfigurableRbac
        bulkActionButtons={false}
        rowClick="expand"
        editComponent={<PlacementEdit expandView />}
        showComponent={<PlacementShow expandView />}
        expandSingle
        rowSx={placementRowStyle}
        omit={[
          'clientContactName',
          'duration',
          'onboardingComplete',
          'backgroundCheckComplete',
          'physicalComplete',
          'drugScreenComplete',
          'trainingComplete',
          'clientTrainingComplete',
          'bypassClientTraining',
          'perDiem',
          'msp',
          'straightTimeBillRate',
          'overtimeBillRate',
          'doubleTimeBillRate',
          'straightTimePayRate',
          'overtimePayRate',
          'doubleTimePayRate',
          'rotationId',
          'rotationStartDate',
          'updatedAt',
          'updatedBy',
          'updatedByName',
        ]}
      >
        <TextField source="id" label="Placement ID" sortable />
        <CandidateLinkField source="candidate.firstName" label="First name" sortable />
        <CandidateLinkField source="candidate.lastName" label="Last name" sortable />
        <TextField source="job.jobTitle" label="Job Title" sortable />
        <CustomDateField source="startDate" label="Start" sortable />
        <CustomDateField source="endDate" label="End" sortable />
        <TextField source="duration" sortable />
        <CustomDateField source="onboardingComplete" sortable />
        <CustomDateField source="backgroundCheckComplete" sortable />
        <CustomDateField source="physicalComplete" sortable />
        <CustomDateField source="drugScreenComplete" sortable />
        <CustomDateField source="trainingComplete" sortable />
        <CustomDateField source="clientTrainingComplete" sortable />
        <BooleanField source="bypassClientTraining" sortable />
        <TextField source="perDiem" sortable />
        <LinkField resource={RESOURCE_PROJECT} source="projectId">
          <TextField source="project.name" label="Project" sortable />
        </LinkField>
        <LinkField resource={RESOURCE_CLIENT} source="clientId">
          <TextField source="client.name" label="Client" sortable />
        </LinkField>
        <TextField source="straightTimeBillRate" sortable />
        <TextField source="overtimeBillRate" sortable />
        <TextField source="doubleTimeBillRate" sortable />
        <TextField source="straightTimePayRate" sortable />
        <TextField source="overtimePayRate" sortable />
        <TextField source="doubleTimePayRate" sortable />
        <TextField source="rotationId" sortable />
        <TextField source="rotationStartDate" sortable />
        <FunctionField
          label="Client Contact"
          render={(record) =>
            record.clientContact ? `${record.clientContact?.firstName} ${record.clientContact?.lastName}` : ''
          }
          sortable={false}
        />
        <TextField source="fieldManager.name" label="Field Manager" sortable />
        <CustomDateField source="updatedAt" label="Updated At" sortable />
        <TextField source="updatedBy" label="Updated By" sortable />
        <TextField source="updatedByName" label="Updated By Name" sortable />

        <ActionsColumn label="Actions" customDeleteButton={<ArchiveToggleButton />} />
      </DatagridConfigurableRbac>
    </List>
  );
};
