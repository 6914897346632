import { useResourceContext } from 'ra-core';
import React, { ReactNode } from 'react';
import { Button, Filter, SavedQueriesList, useListContext, useStore } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import { styles } from './styles';

type SidebarFilterProps = {
  children: ReactNode;
  showQueries?: boolean;
};

export const SidebarFilter: React.FC<SidebarFilterProps> = ({ children, showQueries }) => {
  const { filterValues, setFilters } = useListContext();
  const resource = useResourceContext();
  const [showSideFilter] = useStore(`${resource}.sideFilters`, false);

  const handleClearFilters = () => {
    setFilters({ q: filterValues.q });
  };

  const isResetFiltersButtonDisabled = Object.keys(filterValues).filter((filter) => filter !== 'q').length === 0;

  return (
    showSideFilter && (
      <Card sx={styles.card}>
        <CardContent sx={styles.cardContent}>
          {showQueries && (
            <Box sx={styles.box}>
              <SavedQueriesList />
            </Box>
          )}
          <Filter>{children}</Filter>
          <Button
            variant="contained"
            label="Clear filters"
            disabled={isResetFiltersButtonDisabled}
            fullWidth
            onClick={handleClearFilters}
          />
        </CardContent>
      </Card>
    )
  );
};
