import { FC } from 'react';
import { ToolbarProps } from 'react-admin';
import { SidebarFilterToggleButton } from '../../Common/filter/SidebarFilterToggleButton';
import ListActions from '../../Common/ListActions/ListActions';

type PermissionListActionsProps = Omit<ToolbarProps, 'children'>;

export const PermissionListActions: FC<PermissionListActionsProps> = (props) => {
  return (
    <ListActions {...props} showAction={{ select: true, export: false }}>
      <SidebarFilterToggleButton />
    </ListActions>
  );
};
