import React from 'react';
import { Datagrid, FunctionField, ReferenceManyField, TextField, useRecordContext } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { RESOURCE_CANDIDATE, RESOURCE_JOB_RESPONSE } from '../../provider/dataProvider/constants';
import ActionsColumn from '../ActionsColumn';
import { candidateRowStyle } from '../Candidate/CandidateCommon';
import CandidateMessageButton from '../Common/buttons/CandidateMessageButton';
import DownloadButton from '../Common/buttons/DownloadButton';
import { Empty } from '../Common/Empty/Empty';
import LinkField from '../LinkField';
import { BulkActionButtons } from './components/BulkActionButtons';
import { JobResponseUpdateButton } from './JobResponseUpdateButton';

const JobCandidates = ({ withActions = false, jobId }) => {
  const jobRecord = useRecordContext();

  return (
    <ReferenceManyField
      reference={RESOURCE_JOB_RESPONSE}
      target="jobId"
      sort={{ field: 'id', order: 'ASC' as const }}
      perPage={MAX_RECORD_PER_PAGE}
      storeKey="job-job-response"
    >
      <Datagrid
        rowClick={false}
        rowSx={candidateRowStyle}
        bulkActionButtons={withActions && <BulkActionButtons jobId={jobId} />}
        empty={<Empty resourceName={RESOURCE_JOB_RESPONSE} withWrapper />}
      >
        <LinkField
          source="candidateId"
          resource={RESOURCE_CANDIDATE}
          label="Candidate name"
          showMode={!withActions}
          sortable={false}
        >
          <FunctionField
            render={(record) => `${record.candidate.firstName} ${record.candidate.lastName}`}
            sortable={false}
          />
        </LinkField>
        <TextField source="status" sortable />
        <TextField source="job.jobTitle" label="Job Title" sortable={false} />
        <FunctionField
          label="Contact"
          render={(record) =>
            record.clientContact ? `${record.clientContact?.firstName} ${record.clientContact?.lastName}` : ''
          }
          sortable={false}
        />
        <ActionsColumn label="Actions" actionsSettings={{ show: false, edit: false, delete: false }}>
          <DownloadButton source="rtrDocumentUrl" label="" />
          {withActions && <CandidateMessageButton />}
          {withActions && <JobResponseUpdateButton jobRecord={jobRecord} />}
        </ActionsColumn>
      </Datagrid>
    </ReferenceManyField>
  );
};

export default JobCandidates;
