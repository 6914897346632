import { FC, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
  Button,
  Confirm,
  Datagrid,
  Identifier,
  TextField,
  useDelete,
  useGetMany,
  useGetManyReference,
  useListContext,
  useNotify,
  useRecordContext,
  useUnselectAll,
} from 'react-admin';
import IconClose from '@mui/icons-material/Close';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch } from 'react-redux';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import {
  RESOURCE_CANDIDATE,
  RESOURCE_CANDIDATE_CONTACT,
  RESOURCE_CLIENT_CONTACT,
} from '../../provider/dataProvider/constants';
import { addToBasket } from '../../redux/actions/basketActions';

export interface FavoriteCandidatesDialogProps {
  clientContactId: Identifier;
  title: string;
  onClose: () => void;
}

interface RemoveButtonProps {
  clientContactId: Identifier;
  refetch: () => void;
}

const RemoveButton: FC<RemoveButtonProps> = ({ refetch, clientContactId }) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const record = useRecordContext();
  const name = record ? record.firstName : '';
  const [deleteOne] = useDelete();

  const handleConfirm = async () => {
    await deleteOne(
      RESOURCE_CANDIDATE_CONTACT,
      { id: record.id },
      {
        onSuccess: async () => {
          setOpen(false);
          refetch();
        },
        onError: async (e) => {
          console.log(e);
          notify("Couldn't remove candidate from list", {
            type: 'warning',
            undoable: false,
          });
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick}>
        <RemoveCircleIcon fontSize="small" />
      </Button>
      <Confirm
        isOpen={open}
        title={`Remove ${name} confirmation`}
        content="Are you sure you want to remove this candidate from the list?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

interface AddToBasketButtonProps {
  ids: Identifier[];
}

const AddToBasketButton: FC<AddToBasketButtonProps> = ({ ids }) => {
  const { data = [], isSuccess } = useGetMany(RESOURCE_CANDIDATE, { ids });
  const { selectedIds = [] } = useListContext();
  const dispatch = useDispatch();
  const notify = useNotify();

  const handleClick = () => {
    const selectedIdsSet = new Set(selectedIds);
    const selectedData = data.filter((contact) => selectedIdsSet.has(contact.id));

    dispatch(addToBasket(RESOURCE_CANDIDATE, selectedData));
    notify('Added to Basket', { type: 'info', undoable: false });
  };

  return (
    <Button label="Add to basket" onClick={handleClick} disabled={!isSuccess || selectedIds.length === 0}>
      <ShoppingBasketIcon />
    </Button>
  );
};

const FavoriteCandidatesDialog: FC<FavoriteCandidatesDialogProps> = ({ clientContactId, title, onClose }) => {
  const {
    data = [],
    total,
    refetch,
  } = useGetManyReference(RESOURCE_CANDIDATE_CONTACT, {
    target: 'clientContactId',
    id: clientContactId,
    sort: { field: 'candidate.firstName', order: 'ASC' },
  });
  const unselectAll = useUnselectAll(RESOURCE_CLIENT_CONTACT);

  function handleClose() {
    unselectAll();
    onClose();
  }

  return (
    <Dialog maxWidth="lg" onClose={handleClose} open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ '.MuiToolbar-root': { display: 'none' } }}>
        <Datagrid data={data} total={total} optimized empty={<div> No candidate found </div>} rowClick={false}>
          <TextField source="candidate.firstName" label="First Name" sortable />
          <TextField source="candidate.lastName" label="Last Name" sortable />
          <RemoveButton clientContactId={clientContactId} refetch={refetch} />
        </Datagrid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <AddToBasketButton ids={data.map((x) => x.candidateId)} />
        <Button onClick={handleClose} color="primary" label="Close">
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FavoriteCandidatesDialog;
