import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { commonStyles } from '../CommonStyles';

type RoleShowProps = Omit<ShowLayoutProps, 'children'>;

export const RoleShow: React.FC<RoleShowProps> = (props): ReactElement => {
  return (
    <ShowLayout {...props}>
      <TabbedShowLayout.Tab label="Role" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <Labeled label="Role">
              <TextField source="name" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
        </Box>
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};
