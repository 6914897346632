import React, { createContext, useContext, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  Button,
  ImageField,
  ImageInput,
  maxLength,
  required,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  Title,
  Toolbar,
  useGetIdentity,
  useNotify,
} from 'react-admin';
import LockIcon from '@mui/icons-material/Lock';
import { getUser, updateFieldManagerProfile, updateProfilePicture } from '../../provider/authProvider';
import { commonStyles } from '../CommonStyles';
import api from '../../provider/api';
import styles from './styles';

interface profileVersionType {
  profileVersion: number;
  refreshProfile: () => void;
}

const ProfileContext = createContext<profileVersionType>({
  profileVersion: 0,
  refreshProfile: () => {},
});

const PasswordResetButton = ({ email }) => {
  const notify = useNotify();

  return (
    <React.Fragment>
      <Button
        style={{ marginLeft: '20px' }}
        label="Send Pwd reset link"
        variant="text"
        disabled={!email}
        onClick={async () => {
          try {
            const response = await api.auth.fieldManagerForgotPassword(email);

            if (response.status === 200) {
              notify('Password reset link has been sent', {
                type: 'info',
                undoable: false,
              });
            } else {
              notify(`Request failed with ${response.status}`, {
                type: 'warning',
                undoable: false,
              });
            }
          } catch (e) {
            notify('Request failed, try again!', {
              type: 'warning',
              undoable: false,
            });
          }
        }}
      >
        <LockIcon />
      </Button>
    </React.Fragment>
  );
};

export const ProfileProvider = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () => setProfileVersion((currentVersion) => currentVersion + 1),
    }),
    [profileVersion]
  );

  return <ProfileContext.Provider value={context}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => useContext(ProfileContext);

const ACCEPTED_FILE_FORMATS = ['.jpg', '.jpeg', '.png', '.bmp'];

export const ProfileEdit = (props) => {
  const user = getUser();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const { refreshProfile } = useProfile();
  const { isLoading, identity, refetch } = useGetIdentity();

  const onSuccess = () => {
    setSaving(false);
    notify('Your profile has been updated', { type: 'success' });
    refetch().then();
    refreshProfile();
  };

  const onFail = () => {
    setSaving(false);
    notify('Failed. Please try again!', { type: 'error' });
  };

  const handleSave = (values) => {
    setSaving(true);
    if (values.avatar.rawFile) {
      updateProfilePicture(values)
        .then((response) => {
          if (!response) {
            onFail();
          }
        })
        .catch(() => onFail());
    }
    updateFieldManagerProfile(values)
      .then((response) => {
        if (response) {
          onSuccess();
        } else {
          onFail();
        }
      })
      .catch(() => onFail());
  };

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  if (!user || isLoading) {
    return <></>;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <Title title="User Profile" />
      <SimpleForm
        record={identity ? identity : {}}
        toolbar={
          <Toolbar {...props}>
            <SaveButton label="Save" variant="text" />
            <PasswordResetButton email={identity.email} />
          </Toolbar>
        }
      >
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <TextInput source="title" sx={commonStyles.flexBoxItem} />
            <TextInput source="firstName" validate={required()} sx={commonStyles.flexBoxItem} />
            <TextInput source="lastName" validate={required()} sx={commonStyles.flexBoxItem} />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput source="email" type="email" disabled sx={commonStyles.flexBoxItem} />
            <TextInput source="phone" validate={[required(), maxLength(100)]} sx={commonStyles.flexBoxItem} />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <ImageInput
              source="avatar"
              label="Avatar"
              accept={{ 'image/*': ACCEPTED_FILE_FORMATS }}
              sx={{ ...commonStyles.flexBoxLongItem, ...styles.imageInput }}
            >
              <ImageField source="src" title="Avatar preview" sx={styles.imageField} />
            </ImageInput>
          </Box>
        </Box>
      </SimpleForm>
    </SaveContextProvider>
  );
};
