import { ChannelDetails, InboundMessage, RealtimeClient } from 'ably';
import { OnMessageReceivesCallback } from '../types';
import { ChatMessage } from '../../../redux/reducers/initialState';
import { CANDIDATE_CHANNEL_PREFIX, SERVICE_CHANNEL_NAME } from './constants';

export const subscribeToCandidateChannel = (
  client: RealtimeClient,
  channelId: string,
  onMessageReceived: OnMessageReceivesCallback
) => {
  console.log("Subscribing to a candidate's channel: ", channelId);
  const candidateChannel = client.channels.get(channelId);
  candidateChannel.subscribe(function (message: InboundMessage) {
    console.log(`${channelId} channel received a message`, message);
    const chatMessage = convertToChatMessage(message);
    if (chatMessage) {
      onMessageReceived(chatMessage);
    }
  });
};

export const getAllActiveChannels = async (client: RealtimeClient): Promise<Set<string>> => {
  const response = await client.request('get', '/channels', 1.1, null);
  let allChannels: Array<ChannelDetails> = [...response.items];
  while (response.hasNext?.()) {
    const result = await response.next();
    allChannels = [...allChannels, ...result.items];
  }
  return allChannels.reduce((acc, { channelId, status }) => {
    if (channelId && channelId.startsWith(CANDIDATE_CHANNEL_PREFIX) && status.isActive) {
      acc.add(channelId);
    }
    return acc;
  }, new Set<string>());
};

export const subscribeToServiceChannel = (
  client: RealtimeClient,
  onServiceMessageReceived: (candidateChannelId: string) => void
) => {
  client.channels.get(SERVICE_CHANNEL_NAME).subscribe(function (message: InboundMessage) {
    const { data: channelId } = message || {};
    console.log('Ably service channel message received', message);
    if (channelId && channelId.startsWith(CANDIDATE_CHANNEL_PREFIX)) {
      onServiceMessageReceived(channelId);
    }
  });
};

const convertToChatMessage = (message: InboundMessage): ChatMessage | undefined => {
  try {
    const { id, data } = message;
    const dataObj = JSON.parse(data);
    return {
      id,
      ...dataObj,
    };
  } catch (error) {
    console.error('Error during ably message conversion ', error);
  }
};

export const getCandidateChannelId = (candidateId: number) => `${CANDIDATE_CHANNEL_PREFIX}${candidateId}`;
