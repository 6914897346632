import React, { ReactElement } from 'react';
import { CreateProps, SaveButton } from 'react-admin';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { SimpleCreateForm } from '../Common/forms/SimpleCreateForm';
import { RoleForm } from './RoleForm';

export const RoleCreate: React.FC<CreateProps> = (props): ReactElement => (
  <SimpleCreateForm
    {...props}
    toolbar={
      <FooterToolbar
        {...props}
        SaveButtonComponent={
          <SaveButton label="Save" variant="text" transform={(data) => ({ ...data, isActive: true })} />
        }
      />
    }
  >
    <RoleForm />
  </SimpleCreateForm>
);
