import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import LockIcon from '@mui/icons-material/Lock';
import React, { useEffect } from 'react';
import { Button, EditProps, useNotify, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import useClipboard from 'react-use-clipboard';
import { useCanAccess } from '../../hooks/useCanAccess';
import api from '../../provider/api';
import { RESOURCE_CANDIDATE_COMMENTS } from '../../provider/dataProvider/constants';
import { isEmpty } from '../../provider/UtilityFunctions';
import { CommentsTab } from '../Comment/CommentsTab';
import CandidateMessageButton from '../Common/buttons/CandidateMessageButton';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { actionPermissions } from '../Permissions/constants';
import { AddressesTab } from './tabs/AddressesTab';
import { ClearanceMembershipsTab } from './tabs/ClearanceMembershipsTab';
import { DocumentsTab } from './tabs/DocumentsTab';
import { ExperienceTab } from './tabs/ExperienceTab';
import { HeaderTab } from './tabs/HeaderTab';
import { PlacementsTab } from './tabs/PlacementsTab';
import { SkillsIndustriesTab } from './tabs/SkillsIndustriesTab';
import '../CommonCss.css';

const PasswordResetButton = () => {
  const notify = useNotify();
  const [pwdResetUrl, setPwdResetUrl] = React.useState('');
  const [copied, setCopied] = useClipboard(pwdResetUrl);
  const record = useRecordContext();
  const [isDisabled, setIsDisabled] = React.useState(true);

  useEffect(() => {
    setIsDisabled(!record?.personalEmail);
  }, [record]);

  useEffect(() => {
    if (copied) {
      notify('Password reset link copied to clipboard', {
        type: 'info',
        undoable: false,
      });
    }
  }, [copied]);

  return (
    <React.Fragment>
      <Button
        style={{ marginLeft: '20px' }}
        label="Send Pwd reset link"
        variant="text"
        disabled={isDisabled}
        onClick={async () => {
          try {
            const responseActivate = await api.auth.candidateActivate(record.id);
            if (responseActivate.status != 200) {
              notify(`Candidate Account activation request failed with ${responseActivate.status}`, {
                type: 'warning',
                undoable: false,
              });
              return;
            }
            const responseForgotPassword = await api.auth.candidateForgotPassword(record?.personalEmail);

            if (responseForgotPassword.status === 200) {
              const {
                data: { message, link },
              } = responseForgotPassword;
              if (link) {
                setPwdResetUrl(link);
                notify('Password reset link has been sent', {
                  type: 'info',
                  undoable: false,
                });
              } else {
                notify('Could not generate reset link! Candidate needs to register!', {
                  type: 'info',
                  undoable: false,
                });
              }
            } else {
              notify(`Request failed with ${responseForgotPassword.status}`, {
                type: 'warning',
                undoable: false,
              });
            }
          } catch (e) {
            notify('Request failed, try again!', {
              type: 'warning',
              undoable: false,
            });
          }
        }}
      >
        <LockIcon />
      </Button>
      <Button
        style={{ marginLeft: '20px' }}
        label="Copy link"
        variant="text"
        disabled={isEmpty(pwdResetUrl)}
        onClick={setCopied}
      >
        <FileCopyOutlinedIcon />
      </Button>
    </React.Fragment>
  );
};

export interface CandidateEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation?: boolean;
  expandView?: boolean;
}

export const CandidateEdit: React.FC<CandidateEditProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();
  const candidateId = id || record?.id;
  const showActions = useCanAccess(actionPermissions.edit);

  return (
    <TabbedEditForm
      {...props}
      footerToolbarProps={{
        children: (
          <>
            <CandidateMessageButton label="Start chat" />
            <PasswordResetButton />
          </>
        ),
      }}
    >
      <LazyFormTab label="Candidate">
        <HeaderTab />
      </LazyFormTab>

      <LazyFormTab label="Comments">
        <CommentsTab reference={RESOURCE_CANDIDATE_COMMENTS} withActions={showActions} />
      </LazyFormTab>

      <LazyFormTab label="Documents" sx={{ display: 'inline-block' }}>
        <DocumentsTab candidateId={candidateId} withActions={showActions} />
      </LazyFormTab>

      <LazyFormTab label="Addresses">
        <AddressesTab candidateId={candidateId} withActions={showActions} />
      </LazyFormTab>

      <LazyFormTab label="Experience">
        <ExperienceTab candidateId={candidateId} withActions />
      </LazyFormTab>

      <LazyFormTab label="Clearance/Memberships">
        <ClearanceMembershipsTab candidateId={candidateId} withActions />
      </LazyFormTab>

      <LazyFormTab label="Skills/Industries">
        <SkillsIndustriesTab candidateId={candidateId} withActions />
      </LazyFormTab>

      <LazyFormTab label="Placements">
        <PlacementsTab withActions />
      </LazyFormTab>
    </TabbedEditForm>
  );
};
