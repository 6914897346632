import { FC } from 'react';
import { FunctionField, Labeled, TextField } from 'react-admin';
import { Box } from '@mui/material';
import { commonStyles } from '../../CommonStyles';

export const PayRateFields: FC = () => {
  return (
    <Box sx={commonStyles.flexBox}>
      <Labeled>
        <TextField source="straightTimePayRate" sx={commonStyles.flexBoxItem} />
      </Labeled>
      <Labeled>
        <FunctionField
          source="overtimePayRate"
          render={(record) => record.straightTimePayRate * 1.5}
          sx={commonStyles.flexBoxItem}
        />
      </Labeled>
      <Labeled>
        <FunctionField
          source="doubleTimePayRate"
          render={(record) => record.straightTimePayRate * 2}
          sx={commonStyles.flexBoxItem}
        />
      </Labeled>
    </Box>
  );
};
