import IconClose from '@mui/icons-material/Close';
import { DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { Button, Datagrid, List, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_FAVORITE } from '../../../provider/dataProvider/constants';

export interface FavoriteListSelectionDialogProps {
  onClose: () => void;
  onSelect: (record: any) => void;
}

interface SelectButtonProps {
  onSelect: (record: any) => void;
}

const SelectButton: React.FC<SelectButtonProps> = ({ onSelect }) => {
  const record = useRecordContext();
  return <Button onClick={() => onSelect(record)} label="Select" />;
};

const FavoriteListSelectionDialog: React.FC<FavoriteListSelectionDialogProps> = ({ onClose, onSelect }) => {
  return (
    <Dialog maxWidth="lg" onClose={onClose} open>
      <DialogTitle>Select List</DialogTitle>
      <DialogContent>
        <List
          title=""
          resource={RESOURCE_FAVORITE}
          perPage={100}
          pagination={false}
          exporter={false}
          disableSyncWithLocation
        >
          <Datagrid rowClick={false} optimized bulkActionButtons={false}>
            <TextField source="name" sortable={false} />
            <SelectButton onSelect={onSelect} />
          </Datagrid>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" label="Close">
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FavoriteListSelectionDialog;
