import React, { FC, Fragment } from 'react';
import {
  BooleanInput,
  NumberInput,
  ReferenceInput,
  required,
  TextInput,
  maxLength,
  AutocompleteInput,
} from 'react-admin';
import { Box, Divider } from '@mui/material';
import { validateEndDate } from '../../utils/validators';
import { commonStyles } from '../CommonStyles';
import { RESOURCE_LOOKUP_FIELDMANAGER } from '../../provider/dataProvider/constants';
import CustomNumberInput from '../Common/inputs/CustomNumberInput';
import { ClientSelect } from '../Shared/ClientSelect';
import { ProjectSelect } from '../Shared/ProjectSelect';
import CustomDateInputV3 from '../CustomDateInputV3';
import { NetBillRateInputs } from './components/NetBillRateInputs';
import { PayRateInputs } from './components/PayRateInputs';

type JobFormProps = {
  createMode?: boolean;
};

export const JobForm: FC<JobFormProps> = ({ createMode = false }) => {
  return (
    <>
      <Box sx={commonStyles.flexBox}>
        {!createMode && <NumberInput source="id" label="Job ID" sx={commonStyles.flexBoxItem} readOnly />}
        <ClientSelect />
        <ProjectSelect />
      </Box>

      <Box sx={commonStyles.flexBox}>
        <ReferenceInput source="fieldManagerAssignedToId" reference={RESOURCE_LOOKUP_FIELDMANAGER}>
          <AutocompleteInput label="Field Manager" validate={[required()]} sx={commonStyles.flexBoxItem} />
        </ReferenceInput>
        <TextInput source="jobTitle" validate={[required(), maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <CustomNumberInput source="numberOfPositions" validate={[required()]} sx={commonStyles.flexBoxItem} />
      </Box>

      <Box sx={commonStyles.flexBox}>
        <CustomDateInputV3
          label="Start Date"
          source="startDate"
          validate={[required()]}
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInputV3
          label="End Date"
          source="endDate"
          validate={[required(), validateEndDate]}
          sx={commonStyles.flexBoxItem}
        />
        {!createMode && <CustomDateInputV3 label="Date Added" source="dateAdded" sx={commonStyles.flexBoxItem} />}
      </Box>

      {!createMode && (
        <Box sx={commonStyles.flexBox}>
          <CustomDateInputV3 source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} disabled />
          <TextInput source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} disabled />
        </Box>
      )}

      <Box sx={commonStyles.flexBox}>
        <TextInput source="region" validate={[required(), maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <BooleanInput label="Computer Required" source="computerRequired" sx={commonStyles.flexBoxItem} />
        <BooleanInput label="Email Required" source="emailRequired" sx={commonStyles.flexBoxItem} />
      </Box>

      <Divider style={{ minWidth: '100%' }} />

      <h3 style={{ minWidth: '100%', marginBottom: '3rem', height: 0 }}>Payments & Billing</h3>

      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput source="perDiem" label="Per Diem" sx={commonStyles.flexBoxItem} />
        <CustomNumberInput
          source="msp"
          max={100}
          label="MSP (%)"
          sx={commonStyles.flexBoxItem}
          fractionDigits={3}
          step={0.001}
          defaultValue={0}
        />
      </Box>

      <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput source="straightTimeBillRate" validate={[required()]} sx={commonStyles.flexBoxItem} />
        <CustomNumberInput source="overtimeBillRate" validate={[required()]} sx={commonStyles.flexBoxItem} />
        <CustomNumberInput source="doubleTimeBillRate" sx={commonStyles.flexBoxItem} />
      </Box>

      <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

      <Box sx={commonStyles.flexBox}>
        <NetBillRateInputs />
      </Box>

      <Divider
        style={{
          minWidth: '100%',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      />

      <Box sx={commonStyles.flexBox}>
        <PayRateInputs />
      </Box>

      <Divider style={{ minWidth: '100%', marginBottom: '15px' }} />

      <Box sx={commonStyles.flexBox3LongItem}>
        <TextInput multiline source="description" validate={[required()]} sx={commonStyles.flexBox3LongItem} />
      </Box>
    </>
  );
};
