import React from 'react';
import { List as RaList, ListProps as RaListProps } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import { PostPagination } from '../../PostPagination';

type ListProps = RaListProps;

export const List: React.FC<ListProps> = ({
  children,
  title = false,
  perPage = 100,
  pagination = defaultPagination,
  sx = commonStyles.raFilterForm,
  ...rest
}) => {
  return (
    <RaList {...rest} title={title} pagination={pagination} perPage={perPage} sx={sx}>
      {children}
    </RaList>
  );
};

const defaultPagination = <PostPagination />;
