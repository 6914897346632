import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { BooleanInput, required, TextInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { toggleStorageName } from '../../../provider/constants';
import { FreeSoloAutocompleteInput } from './FreeSoloAutocompleteInput';

type InputProps = {
  source: string;
  toggleSource: string;
  optionsOrSingleValue?: Array<string> | string;
};

const ToggleableInput: React.FC<InputProps> = ({ source, toggleSource, optionsOrSingleValue }) => {
  const { resetField, getFieldState } = useFormContext();
  const enabled: boolean = useWatch({ name: toggleSource, exact: true });
  const hasChoices = typeof optionsOrSingleValue === 'object';
  const validate = enabled ? [required()] : [];

  useEffect(() => {
    if (enabled === false && getFieldState(toggleSource).isTouched) {
      resetField(source);
    }
  }, [enabled]);

  return hasChoices ? (
    <FreeSoloAutocompleteInput
      source={source}
      options={optionsOrSingleValue}
      defaultValue={optionsOrSingleValue[0]}
      disabled={!enabled}
      validate={validate}
    />
  ) : (
    <TextInput source={source} disabled={!enabled} validate={validate} />
  );
};

type ToggleableTextInputProps = {
  fieldName: string;
  choices?: Array<string> | string;
};

/**
  ToggleableTextInput is a component that renders a `Switch` and its dependent `Input`,
  which can be a simple `TextInput` or freeSolo `Autocomplete` with predefined options.

  * When the user turns the `Switch` off - the corresponding input is reset to the default value.
  * If the `Switch` is off - the corresponding input is disabled.
  * If the `Switch` is on - the corresponding input is editable and required.
*/
export const ToggleableTextInput: React.FC<ToggleableTextInputProps> = ({ fieldName, choices }) => {
  const toggleSource = `${toggleStorageName}.${fieldName}`;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', '& div:last-child': { width: '100%' } }}>
      <BooleanInput source={toggleSource} label={false} />
      <ToggleableInput source={fieldName} optionsOrSingleValue={choices} toggleSource={toggleSource} />
    </Box>
  );
};
