import IconClose from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  DateField,
  FunctionField,
  Loading,
  TextField,
  useNotify,
  useRecordContext,
} from 'react-admin';
import api from '../../../../provider/api';
import { RESOURCE_PLACEMENT_DOCUMENTS } from '../../../../provider/dataProvider/constants';
import { useInvalidateResourceQueryCache } from '../../../../provider/dataProvider/useInvalidateResourceQueryCache';
import DownloadButton from '../../../Common/buttons/DownloadButton';
import { Empty } from '../../../Common/Empty/Empty';
import { ReferenceManyField } from '../../../Common/fields/ReferenceManyField';
import { commonStyles } from '../../../CommonStyles';
import { styles } from './styles';

const showPdf = (pdf) => {
  const newBlob = new Blob([pdf], { type: 'application/pdf;base64' });
  const pdfUrl = URL.createObjectURL(newBlob);
  const newWindow = window.open(pdfUrl, '_blank');
  if (newWindow) {
    newWindow.focus();
  }
};

type DocumentsTabProps = {
  withActions?: boolean;
};

export const DocumentsTab: React.FC<DocumentsTabProps> = ({ withActions = false }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_PLACEMENT_DOCUMENTS, true);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [letterLink, setLetterLink] = useState('');

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    notify('Copied to clipboard!');
  };

  const getPreviewLetter = async (type, id) => {
    let request;
    switch (type) {
      case 'candidate':
        request = api.documents.getCandidateOfferLetterPreview;
        break;
      case 'employer':
        request = api.documents.getEmployerPreview;
    }
    try {
      const response = await request(id);
      if (response.status === 200) {
        showPdf(response.data);
      } else {
        notify(response.message);
      }
    } catch (e) {
      notify(JSON.parse(new TextDecoder().decode(e.response.data)).message || e);
    }
  };

  const sendLetter = async (type, id) => {
    setIsLoading(true);
    setDialogOpen(true);
    setConfirmDialog({ open: false, type: '' });
    let request;
    switch (type) {
      case 'candidate':
        request = api.documents.sendCandidateOfferLetter;
        break;
      case 'employer':
        request = api.documents.sendEmployerLetter;
    }
    try {
      const response = await request(id);
      if (response.status === 200) {
        setLetterLink(response.data);
        await invalidateQueries();
      } else {
        setDialogOpen(false);
        notify(response.message);
      }
    } catch (e) {
      setDialogOpen(false);
      notify(e.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Box sx={{ ...styles.lettersInfoContainer, borderRight: '1px solid grey' }}>
          <h2>Candidate Letter</h2>
          <p>Letter sent to candidate upon offer of placement</p>
          <Box sx={styles.buttonsContainer}>
            <Button label="Preview" onClick={() => getPreviewLetter('candidate', record.id)} />
            {withActions && (
              <Button
                label="Send"
                onClick={() => setConfirmDialog({ open: true, type: 'candidate' })}
                color="primary"
              />
            )}
          </Box>
        </Box>
        <Box sx={styles.lettersInfoContainer}>
          <h2>Employer Letter</h2>
          <p>Letter sent to employer upon offer of placement</p>
          <Box sx={styles.buttonsContainer}>
            <Button label="Preview" onClick={() => getPreviewLetter('employer', record.id)} />
            {withActions && (
              <Button label="Send" onClick={() => setConfirmDialog({ open: true, type: 'employer' })} color="primary" />
            )}
          </Box>
        </Box>
        <Box sx={styles.previousLettersContainer}>
          <h2>Previously sent documents</h2>
          <p>Any unsigned documents for this placement will appear here</p>
          <ReferenceManyField
            reference={RESOURCE_PLACEMENT_DOCUMENTS}
            target="documents"
            sort={{ field: 'name', order: 'ASC' }}
            sx={commonStyles.fullWidth}
            alwaysDisplayWrapper
          >
            <Datagrid
              rowClick={false}
              bulkActionButtons={false}
              rowSx={() => ({ '& button': { backgroundColor: 'red' } })}
              empty={<Empty resourceName="documents" />}
              sx={styles.datagrid}
            >
              <TextField source="documentType" label="Type" sx={{ textTransform: 'capitalize' }} />
              <TextField source="name" label="File Name" />
              <DateField source="creationDate" />
              <FunctionField
                label="Sign link"
                render={(record) =>
                  record.signatureURL ? (
                    <Button label="Copy link to sign" onClick={() => copyLink(record.signatureURL)} />
                  ) : (
                    'No signature link available'
                  )
                }
              />
              <DownloadButton source="url" label="Download" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Box>
      </Box>
      <Dialog open={dialogOpen}>
        <DialogTitle>{isLoading ? 'Document Sending' : 'Document Sent'}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <p>Letter Successfully sent! Click below to copy a link to sign the document.</p>
              <Button label="Copy Link" onClick={() => copyLink(letterLink)} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} label="Close" startIcon={<IconClose />} />
        </DialogActions>
      </Dialog>
      <Confirm
        isOpen={confirmDialog.open}
        title="Send Letter?"
        content={`Sending this letter is irreversible. Emails will be sent to both the ${confirmDialog.type} and the field manager.`}
        onConfirm={() => sendLetter(confirmDialog.type, record.id)}
        onClose={() => setConfirmDialog({ open: false, type: '' })}
        confirm="Send"
      />
    </Box>
  );
};
