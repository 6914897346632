import {
  RESOURCE_CANDIDATE,
  RESOURCE_CLIENT_CONTACT,
  RESOURCE_V2_CLIENT_CONTACTS,
  RESOURCE_V3_CLIENT_LIST,
  RESOURCE_CLIENT,
  RESOURCE_JOB,
  RESOURCE_LOOKUP_FIELDMANAGER,
  RESOURCE_FAVORITE,
  RESOURCE_PERMISSION,
  RESOURCE_PLACEMENT,
  RESOURCE_ROLE,
  RESOURCE_USER_FIELD_MANAGER,
  RESOURCE_FAVORITE_CANDIDATE,
  RESOURCE_PROJECT,
} from './constants';

/**
  `Key` - Updated resource

  `Value` - Array of dependent resources that will be removed from cache,
          hence re-fetched on the next call
 */
export const DEPENDENT_RESOURCE_MATRIX = {
  [RESOURCE_CANDIDATE]: [RESOURCE_FAVORITE, RESOURCE_FAVORITE_CANDIDATE, RESOURCE_PLACEMENT],
  [RESOURCE_V3_CLIENT_LIST]: [RESOURCE_JOB, RESOURCE_PROJECT],
  [RESOURCE_CLIENT]: [RESOURCE_USER_FIELD_MANAGER],
  [RESOURCE_CLIENT_CONTACT]: [RESOURCE_PLACEMENT, RESOURCE_PROJECT],
  [RESOURCE_V2_CLIENT_CONTACTS]: [RESOURCE_PLACEMENT, RESOURCE_V3_CLIENT_LIST],
  [RESOURCE_JOB]: [RESOURCE_PROJECT],
  [RESOURCE_LOOKUP_FIELDMANAGER]: [RESOURCE_PLACEMENT, RESOURCE_JOB],
  [RESOURCE_PROJECT]: [RESOURCE_JOB, RESOURCE_V3_CLIENT_LIST],
  [RESOURCE_ROLE]: [RESOURCE_PERMISSION, RESOURCE_USER_FIELD_MANAGER],
};
