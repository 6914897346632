import React from 'react';
import { Button, RaRecord, useRecordContext } from 'react-admin';

type SelectButtonProps = {
  onSelect: (record: RaRecord) => void;
};

export const SelectButton: React.FC<SelectButtonProps> = ({ onSelect }) => {
  const record = useRecordContext();
  return <Button onClick={() => onSelect(record)} label="Select" />;
};
