import { DataProvider } from 'ra-core/src/types';
import { combineDataProviders } from 'react-admin';
import { getDefaultDataProvider } from '../providers/defaultDataProvider';
import { placementProvider } from '../providers/placementProvider';
import { oldDataProvider } from '../restProvider';
import { documentProvider } from '../providers/documentProvider';
import {
  RESOURCE_CANDIDATE,
  RESOURCE_CANDIDATE_CONTACT,
  RESOURCE_CANDIDATE_DOCUMENTS,
  RESOURCE_CLIENT,
  RESOURCE_CLIENT_CONTACT,
  RESOURCE_FAVORITE,
  RESOURCE_FAVORITE_CANDIDATE,
  RESOURCE_JOB,
  RESOURCE_JOB_RESPONSE,
  RESOURCE_LOOKUP_FIELDMANAGER,
  RESOURCE_PERMISSION,
  RESOURCE_PLACEMENT,
  RESOURCE_PROJECT,
  RESOURCE_PROJECT_CONTACT,
  RESOURCE_PROJECT_SUB_INDUSTRY,
  RESOURCE_ROLE,
  RESOURCE_USER_FIELD_MANAGER,
} from './constants';

const dataProviderV3 = getDefaultDataProvider('/v3');
const dataProviderV4 = getDefaultDataProvider('/v4');

const dataProviderMatcher = (resource: string): DataProvider => {
  switch (resource) {
    case RESOURCE_ROLE:
    case RESOURCE_PERMISSION:
    case RESOURCE_USER_FIELD_MANAGER:
    case RESOURCE_LOOKUP_FIELDMANAGER:
    case RESOURCE_CANDIDATE:
    case RESOURCE_FAVORITE:
    case RESOURCE_FAVORITE_CANDIDATE:
    case RESOURCE_CLIENT_CONTACT:
    case RESOURCE_PROJECT_CONTACT:
    case RESOURCE_CANDIDATE_CONTACT:
      return dataProviderV3;

    case RESOURCE_PROJECT:
    case RESOURCE_JOB:
    case RESOURCE_JOB_RESPONSE:
    case RESOURCE_CLIENT:
    case RESOURCE_PROJECT_SUB_INDUSTRY:
      return dataProviderV4;

    case RESOURCE_CANDIDATE_DOCUMENTS:
      return {
        ...dataProviderV4,
        ...documentProvider,
      };
    case RESOURCE_PLACEMENT:
      return {
        ...dataProviderV4,
        ...placementProvider,
      };
    default:
      return oldDataProvider;
  }
};

export const useDataProvider = (): DataProvider => {
  return combineDataProviders(dataProviderMatcher);
};
