import { SimpleShowLayout, useGetOne, useRecordContext } from 'react-admin';
import React, { useEffect, useState } from 'react';
import { models, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { RESOURCE_REPORT } from '../../provider/dataProvider/constants';
import Show, { ShowComponentProps } from '../Common/ShowComponent/ShowComponent';

const ReportTitle = () => {
  const record = useRecordContext();

  if (!record) return null;
  return <span>Report &quot;{record.reportName}&quot;</span>;
};

const EmbeddedPowerBI = () => {
  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  });
  const record = useRecordContext();
  const { refetch } = useGetOne(RESOURCE_REPORT, { id: record?.reportId });

  useEffect(() => {
    refetch().then((resp) => {
      setReportConfig({
        ...reportConfig,
        embedUrl: resp.data.embedUrl,
        accessToken: resp.data.accessToken,
      });
    });
  }, []);

  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      function () {
        console.log('Report has rendered');
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  return <PowerBIEmbed embedConfig={reportConfig} eventHandlers={eventHandlersMap} cssClassName="power-bi-component" />;
};

export const ReportView: React.FC<ShowComponentProps> = (props) => {
  return (
    <Show title={<ReportTitle />} {...props} actions={false}>
      <SimpleShowLayout>
        <EmbeddedPowerBI />
      </SimpleShowLayout>
    </Show>
  );
};
