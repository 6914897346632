import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { Labeled, RichTextField, TabbedShowLayout, TextField, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import { RESOURCE_CLIENT_COMMENTS, RESOURCE_CLIENT_DOCUMENTS } from '../../provider/dataProvider/constants';
import { CommentsTab } from '../Comment/CommentsTab';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { UploadDocument } from '../Common/UploadDocument/UploadDocument';
import { commonStyles } from '../CommonStyles';
import { ClientContactList } from '../ClientContacts/ClientContactList';
import { DocumentsTab } from '../Shared/DocumentsTab';
import LinkField from '../LinkField';
import CustomDateField from '../Common/fields/CustomDateField';
import { ProjectsTab } from './tabs/ProjectsTab';
import { IndustriesTab } from './tabs/IndustriesTab';

type ClientShowProps = Omit<ShowLayoutProps, 'children'>;

export const ClientShow: FC<ClientShowProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();
  const clientId = id || record?.id;

  return (
    <ShowLayout {...props}>
      <TabbedShowLayout.Tab label="Client" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="id" label="Client ID" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="name" label="Name" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <LinkField label="Phone" phone source="address.phone" sx={commonStyles.flexBoxItem}>
                <TextField source="address.phone" />
              </LinkField>
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <RichTextField source="address.address" label="Address" sx={commonStyles.flexBoxLongItem} />
            </Labeled>
            <Labeled>
              <TextField source="address.zip" label="zip" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="address.country" label="Country" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="address.state" label="State/Province" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="address.city" label="City/Town" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
        </Box>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Projects" sx={commonStyles.formTabHeader}>
        <ProjectsTab clientId={clientId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Contacts" sx={commonStyles.formTabHeader}>
        <ClientContactList clientId={clientId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Comments" sx={commonStyles.formTabHeader}>
        <CommentsTab reference={RESOURCE_CLIENT_COMMENTS} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Documents" sx={commonStyles.formTabHeader}>
        <DocumentsTab
          reference={RESOURCE_CLIENT_DOCUMENTS}
          showMode
          footerActions={
            <UploadDocument
              idField="clientId"
              idValue={clientId}
              resource={RESOURCE_CLIENT_DOCUMENTS}
              documentTypes={[{ id: 'general', name: 'General' }]}
            />
          }
        />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Industries" sx={commonStyles.formTabHeader}>
        <Box width="40%">
          <IndustriesTab clientId={clientId} />
        </Box>
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};
