import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { ButtonProps } from 'ra-ui-materialui/src/button/Button';
import * as React from 'react';
import { Button, useRecordContext } from 'react-admin';
import { styles } from '../../Candidate/styles';
import { EmptyIconButtonPlaceholder } from '../EmptyIconButtonPlaceholder/EmptyIconButtonPlaceholder';

type ParseCVButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClick: Function;
  source: string;
};

const ParseCVButton: React.FC<ParseCVButtonProps> = ({ source, onClick, ...rest }) => {
  const record = useRecordContext();

  return record && record[source] && record.documentType === 'cv' ? (
    <Button {...rest} onClick={() => onClick({ ...record, fileType: 1 })} sx={styles.parseCvButton}>
      <DocumentScannerIcon />
    </Button>
  ) : (
    <EmptyIconButtonPlaceholder sx={styles.parseCvButton} />
  );
};

export default ParseCVButton;
