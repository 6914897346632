import Autocomplete from '@mui/material/Autocomplete';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
} from '@mui/material/useAutocomplete/useAutocomplete';
import { TextInputProps } from 'ra-ui-materialui/src/input/TextInput';
import React, { SyntheticEvent } from 'react';
import { TextInput, useInput, Validator } from 'react-admin';

export type FreeSoloAutocompleteInputProps<Value, Multiple extends boolean, DisableClearable extends boolean> = Omit<
  AutocompleteProps<Value, Multiple, DisableClearable, true>,
  'renderInput' | 'value' | 'freeSolo' | 'onChange'
> & {
  source: string;
  emptyValue?: string;
  validate?: Validator | Validator[];
  label?: TextInputProps['label'];
  alwaysOn?: boolean;
  getSelectedValue?: (option: Value, value: AutocompleteValue<Value, Multiple, DisableClearable, true>) => string;
  getInputValue?: (value: string) => string;
};

export const FreeSoloAutocompleteInput = <Value, Multiple extends boolean, DisableClearable extends boolean>({
  source,
  emptyValue = '',
  validate,
  label,
  options,
  defaultValue,
  getSelectedValue,
  getInputValue,
  ...rest
}: FreeSoloAutocompleteInputProps<Value, Multiple, DisableClearable>) => {
  const input = useInput<Value | string>({ source, defaultValue });

  const value = getInputValue?.(input.field.value) ?? input.field.value;

  const handleChange = (
    _event: SyntheticEvent,
    value: AutocompleteValue<Value, Multiple, DisableClearable, true>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Value>
  ) => {
    if (reason === 'clear') {
      input.field.onChange(emptyValue);
    } else if (reason === 'selectOption') {
      const newValue = getSelectedValue?.(details.option, value) ?? value;
      input.field.onChange(newValue);
    }
  };

  return (
    <Autocomplete<Value, Multiple, DisableClearable, true>
      {...rest}
      freeSolo
      options={options}
      value={value}
      onChange={handleChange}
      renderInput={(params) => <TextInput {...params} source={source} label={label} validate={validate} />}
    />
  );
};
