import { Card } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import Typography from '@mui/material/Typography';
import { styles } from './styles';

type EmptyProps = {
  resourceName: string;
  withWrapper?: boolean;
  wrapperSx?: SxProps<Theme>;
};

export const Empty: React.FC<EmptyProps> = ({ resourceName, withWrapper, wrapperSx = styles.card }) => {
  const Content = (
    <div style={styles.emptyResultContainer}>
      <Typography variant="h5">No {resourceName} found</Typography>
    </div>
  );

  if (!withWrapper) {
    return Content;
  }

  return <Card sx={wrapperSx}>{Content}</Card>;
};
