import {
  AutocompleteInput,
  AutocompleteInputProps,
  useCreateSuggestionContext,
  useGetList,
  useRecordContext,
} from 'react-admin';
import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';

const MANUAL_DUMMY_ID = -9999;
const NOT_EXIST_ID = -9998;

export interface CustomAutoCompleteProps extends AutocompleteInputProps {
  parentId?: number;
  parentName?: string;
  lookupResource: string;
}

interface CreateItemProps {
  onItemCreate: (item: string) => void;
}
export const CreateItem: React.FC<CreateItemProps> = ({ onItemCreate }) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onItemCreate(value);
    setValue('');
    onCreate(value);
  };

  return (
    <Dialog open onClose={onCancel}>
      <form>
        <DialogContent>
          <TextField label="New Item" value={value} onChange={(event) => setValue(event.target.value)} autoFocus />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CustomAutoCompleteLookup: FC<CustomAutoCompleteProps> = ({ parentName, lookupResource, source, ...rest }) => {
  const [finalData, setFinalData] = useState([]);
  const record = useRecordContext();
  const { data, isLoading, refetch } = useGetList(lookupResource, {
    pagination: { page: 1, perPage: MAX_RECORD_PER_PAGE },
    filter: { parentName: parentName || 'NoParent' },
  });

  useEffect(() => {
    refetch();
  }, [parentName]);

  useEffect(() => {
    if (!isLoading && data) {
      const existingFieldValue = get(record, source, undefined);

      const _finalData = [...data];

      if (existingFieldValue) {
        const foundExistingValue = data.find((dataItem) => dataItem.name === existingFieldValue);
        if (!foundExistingValue) {
          _finalData.push({ id: NOT_EXIST_ID, name: existingFieldValue });
        }
      }

      setFinalData(_finalData);
    }
  }, [isLoading, data]);

  return (
    <AutocompleteInput
      optionValue="name"
      optionText="name"
      source={source}
      choices={finalData}
      onCreate={(newItemName) => {
        const newItem = { id: MANUAL_DUMMY_ID, name: newItemName };
        const newChoices = [...finalData.filter((item) => item.id !== newItem.id), newItem];
        setFinalData(newChoices);
        return newItem;
      }}
      {...rest}
    />
  );
};

export default CustomAutoCompleteLookup;
