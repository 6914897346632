import Box from '@mui/material/Box';
import { Create, CreateProps, maxLength, required, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import { US_COUNTRY_NAME } from '../../provider/constants';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';
import { CityInput } from '../Shared/AddressInputs/CityInput';
import { CountryInput } from '../Shared/AddressInputs/CountryInput';
import { StateInput } from '../Shared/AddressInputs/StateInput';

export const ClientCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} actions={false}>
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={<FooterToolbar />}
        sx={commonStyles.flexVertical}
        defaultValues={{ isActive: true, addresses: [{ country: US_COUNTRY_NAME }] }}
      >
        <Box sx={commonStyles.flexBox}>
          <TextInput source="name" validate={[required(), maxLength(120)]} sx={commonStyles.flexBoxItem} />
          <TextInput
            source="addresses[0].phone"
            label="Phone"
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxItem}
          />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="addresses[0].address"
            label="Address"
            validate={[maxLength(255)]}
            sx={commonStyles.flexBoxLongItem}
          />
          <TextInput source="addresses[0].zip" label="zip" validate={[maxLength(50)]} sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <CountryInput sx={commonStyles.flexBoxItem} />
          <StateInput sx={commonStyles.flexBoxItem} />
          <CityInput sx={commonStyles.flexBoxItem} />
        </Box>
      </SimpleForm>
    </Create>
  );
};
