import * as React from 'react';
import { ReactElement } from 'react';
import { Link, useRecordContext } from 'react-admin';
import { Link as LinkMaterial, SxProps, Theme } from '@mui/material';
import get from 'lodash/get';
import { useCanAccess } from '../hooks/useCanAccess';
import { actionPermissions } from './Permissions/constants';

interface LinkFieldProps {
  children: ReactElement;
  resource?: string;
  phone?: boolean;
  email?: boolean;
  source?: string;
  label?: string;
  sortable?: boolean;
  sortBy?: string;
  showMode?: boolean;
  sx?: SxProps<Theme>;
}

const LinkField: React.FC<LinkFieldProps> = ({
  showMode = false,
  children,
  resource,
  phone,
  email,
  source,
  sortBy,
  sortable,
  ...rest
}) => {
  const edit = useCanAccess(actionPermissions.edit, resource);
  const show = useCanAccess(actionPermissions.show, resource);

  const record = useRecordContext();

  if (!edit && !show) {
    return children;
  }

  const field = get(record, source);
  const linkType = edit && !showMode ? '' : '/show';

  if (phone) {
    return (
      <LinkMaterial href={`tel:${field}`} {...rest}>
        {children}
      </LinkMaterial>
    );
  } else if (email) {
    return (
      <LinkMaterial href={`mailto:${field}`} {...rest}>
        {children}
      </LinkMaterial>
    );
  } else {
    return (
      <Link to={`/${resource}/${field}${linkType}`} {...rest}>
        {children}
      </Link>
    );
  }
};

export default LinkField;
