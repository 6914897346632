import Box from '@mui/material/Box';
import React from 'react';
import { Datagrid, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_PLACEMENT } from '../../../provider/dataProvider/constants';
import { ReferenceManyField } from '../../Common/fields/ReferenceManyField';
import CustomBooleanField from '../../CustomBooleanField';
import { placementRowStyle } from '../../Placements/PlacementsList';
import { Empty } from '../../Common/Empty/Empty';
import LinkField from '../../LinkField';
import CustomDateField from '../../Common/fields/CustomDateField';

type PlacementsTabProps = {
  withActions?: boolean;
};

export const PlacementsTab: React.FC<PlacementsTabProps> = ({ withActions = false }) => {
  const record = useRecordContext();

  return (
    <Box display="flex" sx={{ minWidth: '100%' }}>
      <Box flex={1} mr="0.5em">
        <ReferenceManyField reference={RESOURCE_PLACEMENT} target="candidateId" alwaysDisplayWrapper>
          <Datagrid
            bulkActionButtons={false}
            optimized
            rowClick={false}
            rowSx={placementRowStyle}
            empty={<Empty resourceName="placements" />}
          >
            <LinkField source="id" resource={RESOURCE_PLACEMENT} label="ID" sortable={false} showMode={!withActions}>
              <TextField source="id" />
            </LinkField>
            <TextField key="jobTitle" source="job.jobTitle" label="Job Title" sortable={false} />
            <TextField key="client.name" source="client.name" label="Client" sortable={false} />
            <TextField key="project" source="project.name" label="Project" sortable={false} />
            <CustomDateField key="startDate" source="startDate" label="Start" sortable={false} />
            <CustomDateField key="endDate" source="endDate" label="End" sortable={false} />
            <TextField key="payRate" source="straightTimePayRate" label="Straight time pay Rate" sortable={false} />
            <TextField key="billRate" source="straightTimeBillRate" label="Straight time bill rate" sortable={false} />
            <TextField key="perDiem" source="perDiem" label="Per Diem" sortable={false} />
            <TextField key="duration" source="duration" sortable={false} />
            <CustomBooleanField isReversed label="Archived" source="isActive" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Box>
    </Box>
  );
};
