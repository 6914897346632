import AddIcon from '@mui/icons-material/Add';
import IconClose from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FC, useState } from 'react';
import { Button, Datagrid, Filter, RaRecord, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_CANDIDATE, RESOURCE_FAVORITE_CANDIDATE } from '../../../provider/dataProvider/constants';
import { useInvalidateResourceQueryCache } from '../../../provider/dataProvider/useInvalidateResourceQueryCache';
import { SelectButton } from '../../Common/buttons/SelectButton';
import FreeTextSearchInput from '../../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import { List } from '../../Common/list/List';
import { useAddManyToFavoriteList } from '../../Shared/favoriteList/useAddManyToFavoriteList';
import { useAddToFavoriteList } from '../../Shared/favoriteList/useAddToFavoriteList';

type CandidateBulkActionsProps = {
  handleClose: () => void;
};

export const CandidateBulkActions: FC<CandidateBulkActionsProps> = ({ handleClose }) => {
  const record = useRecordContext();
  const addManyToFavoriteList = useAddManyToFavoriteList();

  return (
    <Button
      label="Add to Favorite List"
      onClick={async () => {
        await addManyToFavoriteList(record.id, record.name);
        handleClose();
      }}
    >
      <PlaylistAddIcon />
    </Button>
  );
};

export const CandidatesListActions = () => {
  const record = useRecordContext();
  const [isOpen, setIsOpen] = useState(false);
  const addToFavoriteList = useAddToFavoriteList();
  const invalidateQueries = useInvalidateResourceQueryCache(RESOURCE_FAVORITE_CANDIDATE, true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelect = async (candidateRecord: RaRecord) => {
    await addToFavoriteList(record.id, candidateRecord.id);
    await invalidateQueries();
    setIsOpen(false);
  };

  return (
    <>
      <Button label="Add candidates" startIcon={<AddIcon />} onClick={() => setIsOpen(true)} />
      <Dialog maxWidth="lg" onClose={handleClose} open={isOpen}>
        <DialogTitle>Add Candidate to {record.name}</DialogTitle>
        <DialogContent>
          <List
            resource={RESOURCE_CANDIDATE}
            filter={{ isActive: true }}
            perPage={10}
            filters={
              <Filter variant="outlined">
                <FreeTextSearchInput alwaysOn fullTextSearch />
              </Filter>
            }
            actions={false}
            disableSyncWithLocation
            storeKey={false}
          >
            <Datagrid rowClick={false} bulkActionButtons={<CandidateBulkActions handleClose={handleClose} />}>
              <TextField source="firstName" sortable />
              <TextField source="lastName" sortable />
              <TextField source="personalEmail" sortable />
              <SelectButton onSelect={handleSelect} />
            </Datagrid>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" label="Close">
            <IconClose />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
