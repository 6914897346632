import { FC } from 'react';
import { TextField } from 'react-admin';
import { EditableDatagrid, EditRowButton } from '@react-admin/ra-editable-datagrid';
import { RESOURCE_V3_JOB_ADDRESS } from '../../../provider/dataProvider/constants';
import { ReferenceManyField } from '../../Common/fields/ReferenceManyField';
import { commonStyles } from '../../CommonStyles';
import ActionsColumn from '../../ActionsColumn';
import AddressRowForm from '../../Candidate/AddressRowForm';
import { MAX_RECORD_PER_PAGE, US_COUNTRY_NAME } from '../../../provider/constants';
import { StandaloneCreateButton } from '../../Common/buttons/StandaloneCreateButton';
import { Empty } from '../../Common/Empty/Empty';

type AddressesTabProps = {
  jobId: string | number;
  withActions?: boolean;
};

export const AddressesTab: FC<AddressesTabProps> = ({ withActions = false, jobId }) => {
  return (
    <ReferenceManyField
      reference={RESOURCE_V3_JOB_ADDRESS}
      target="addresses"
      perPage={MAX_RECORD_PER_PAGE}
      sx={commonStyles.fullWidth}
    >
      <EditableDatagrid
        empty={
          withActions ? (
            <StandaloneCreateButton label="Create Address" />
          ) : (
            <Empty resourceName="addresses" withWrapper />
          )
        }
        rowClick={false}
        bulkActionButtons={false}
        mutationMode="pessimistic"
        sx={commonStyles.formTabDatagrid}
        actions={
          withActions && <ActionsColumn actionsSettings={{ show: false }} customEditButton={<EditRowButton />} />
        }
        editForm={withActions && <AddressRowForm hasAddressType={false} hasUnitNumber={false} />}
        createForm={
          withActions && (
            <AddressRowForm
              hasAddressType={false}
              hasUnitNumber={false}
              defaultValues={{
                jobId,
                country: US_COUNTRY_NAME,
              }}
            />
          )
        }
      >
        <TextField source="address" sortable={false} />
        <TextField source="country" sortable={false} />
        <TextField source="state" label="State/Province" sortable={false} />
        <TextField source="city" label="City/Town" sortable={false} />
        <TextField source="zip" sortable={false} />
      </EditableDatagrid>
    </ReferenceManyField>
  );
};
