import { AutocompleteInputProps } from 'ra-ui-materialui/src/input/AutocompleteInput';
import React, { FC } from 'react';
import { maxLength, required } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { RESOURCE_LOOKUP_CITY } from '../../../provider/dataProvider/constants';
import CustomAutoCompleteLookup from '../../CustomAutoComplete/CustomAutoCompleteLookup';

type CityInputProps = AutocompleteInputProps & {
  stateFieldName?: string;
};

export const CityInput: FC<CityInputProps> = ({
  stateFieldName = 'addresses[0].state',
  source = 'addresses[0].city',
  ...rest
}) => {
  const state = useWatch({ name: stateFieldName });

  return (
    <CustomAutoCompleteLookup
      {...rest}
      lookupResource={RESOURCE_LOOKUP_CITY}
      source={source}
      label="City/Town"
      parentName={state}
      validate={[required(), maxLength(50)]}
    />
  );
};
