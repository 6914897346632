import React from 'react';
import { SimpleForm } from 'react-admin';
import { CreateInDialogButton } from '@react-admin/ra-form-layout';
import { CreateInDialogButtonProps } from '@react-admin/ra-form-layout/src/forms/dialog-form/CreateInDialogButton';
import { US_COUNTRY_NAME } from '../../provider/constants';
import { RESOURCE_CLIENT } from '../../provider/dataProvider/constants';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { ClientContactForm } from './ClientContactForm';

export type ClientContactCreateInDialogProps = CreateInDialogButtonProps & {
  clientId: number;
};

export const ClientContactCreateInDialog: React.FC<ClientContactCreateInDialogProps> = ({ clientId, ...rest }) => {
  return (
    <CreateInDialogButton fullWidth maxWidth="lg" label="Create Contact" {...rest}>
      <SimpleForm
        warnWhenUnsavedChanges
        defaultValues={{ clientId, address: { country: US_COUNTRY_NAME } }}
        toolbar={<FooterToolbar cancelButtonResource={RESOURCE_CLIENT} />}
      >
        <ClientContactForm />
      </SimpleForm>
    </CreateInDialogButton>
  );
};
