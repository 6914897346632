import { FC } from 'react';
import { Create, CreateProps, SaveButton, SimpleForm } from 'react-admin';
import moment from 'moment';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { commonStyles } from '../CommonStyles';
import { ISO_DATE_FORMAT } from '../../provider/constants';
import { JobForm } from './JobForm';

export const JobCreate: FC<CreateProps> = (props) => {
  return (
    <Create {...props} actions={false}>
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <FooterToolbar
            SaveButtonComponent={
              <SaveButton
                label="Save"
                variant="text"
                transform={(data) => {
                  return {
                    ...data,
                    isActive: true,
                  };
                }}
              />
            }
            {...props}
          />
        }
        style={commonStyles.fullWidth}
        defaultValues={{
          startDate: moment().format(ISO_DATE_FORMAT),
          endDate: moment().format(ISO_DATE_FORMAT),
        }}
      >
        <JobForm createMode />
      </SimpleForm>
    </Create>
  );
};
