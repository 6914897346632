import React, { useEffect, useState } from 'react';
import { Button, useGetOne, useNotify, useRefresh, useUnselectAll } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import {
  RESOURCE_CLIENT_CONTACT,
  RESOURCE_PROJECT,
  RESOURCE_PROJECT_CONTACT,
} from '../../provider/dataProvider/constants';
import { useDataProvider } from '../../provider/dataProvider/useDataProvider';
import MultipleClientContactSelectionDialog from './MultipleClientContactSelectionDialog';

export const AddProjectContacts = ({ projectId }) => {
  const [filter, setFilter] = useState({ isActive: true });

  const [isOpen, setIsOpen] = useState(false);
  const { data, isSuccess: loaded } = useGetOne(RESOURCE_PROJECT, {
    id: projectId,
  });
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(RESOURCE_CLIENT_CONTACT);
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (loaded && data?.clientId) {
      // @ts-ignore
      setFilter({ ...filter, clientId: data.clientId });
    }
  }, [loaded, data]);

  const onSelectClientContacts = async (ids) => {
    let successCount = 0;
    let errorCount = 0;

    for (const clientContactId of ids) {
      await dataProvider
        .create(RESOURCE_PROJECT_CONTACT, {
          data: {
            projectId,
            clientContactId,
          },
        })
        .then(({ data }) => {
          successCount++;
        })
        .catch((error) => {
          console.log('Error', error);
          errorCount++;
        });
    }

    // Show summary notification
    if (successCount > 0) {
      notify(`Successfully added ${successCount} contact(s)`, {
        type: 'info',
        undoable: false,
      });
    }

    if (errorCount > 0) {
      notify(`Failed to add ${errorCount} contacts(s) to the list`, {
        type: 'warning',
        undoable: false,
      });
    }

    if (successCount > 0) {
      unselectAll();
      refresh();
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button label="Add Contact" onClick={() => setIsOpen(true)}>
        <AddIcon />
      </Button>
      {isOpen && (
        <MultipleClientContactSelectionDialog
          onClose={() => setIsOpen(false)}
          filter={filter}
          onSelect={onSelectClientContacts}
        />
      )}
    </>
  );
};
