import React, { useEffect, useRef } from 'react';
import './ChatMessages.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../provider/authProvider';
import { RootState } from '../../redux/reducers/initialState';
import { isNotEmpty } from '../../provider/UtilityFunctions';
import { archiveChatRoomThenRefetchRooms, fetchChatRoomMessages } from '../../redux/actions/chatkitActions';
import DividerWithText from './DividerWithText';
import ChatMessage from './ChatMessage';
import MessageSend from './MessageSend';

const ChatMessages = () => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const room = useSelector((state: RootState) =>
    state.chatkit.selectedRoomId ? state.chatkit.rooms[state.chatkit.selectedRoomId] : undefined
  );
  const candidates = useSelector((state: RootState) => state.chatkit.candidates);
  const fieldManagers = useSelector((state: RootState) => state.chatkit.fieldManagers);
  const myFieldManagerId = getUser()?.fieldManagerId;

  const lastMessageRef = useRef(null);
  const candidate = room ? candidates[room.candidateId] : undefined;
  const activeRoomTitle = candidate ? `${candidate.firstName} ${candidate.lastName}` : '';

  useEffect(() => {
    if (room && !room.chatMessages.loading && !room.chatMessages.loaded) {
      // @ts-ignore
      dispatch(fetchChatRoomMessages(room.id, notify));
    }
    if (room && room.chatMessages.loaded) {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [room]);

  const onArchiveClicked = () => {
    if (room) {
      // @ts-ignore
      dispatch(archiveChatRoomThenRefetchRooms(room, notify));
    }
  };
  const isSendDisabled = room === undefined || room.chatMessages.loading || isNotEmpty(room.chatMessages.error);

  const renderRoomMessages = (messages) => {
    const retVal = [];
    let lastDateSent = undefined;
    for (let i = 0; i < messages.length; i += 1) {
      const msg = messages[i];

      const dateSent = moment(msg.dateSent).local().format('MMM D');
      if (lastDateSent !== dateSent) {
        retVal.push(<DividerWithText key={msg.id + '-divider'}>{dateSent}</DividerWithText>);
        lastDateSent = dateSent;
      }

      retVal.push(
        <ChatMessage
          key={msg.id}
          ref={i === messages.length - 1 ? lastMessageRef : undefined}
          message={msg}
          candidate={msg.candidateId && !msg.fieldManagerId ? candidates[msg.candidateId] : undefined}
          fieldManager={msg.fieldManagerId ? fieldManagers[msg.fieldManagerId] : undefined}
          isMyMessage={myFieldManagerId && msg.fieldManagerId === myFieldManagerId}
        />
      );
    }
    return retVal;
  };

  return (
    <div className="MessageContainer">
      <div className="MessageContainerHeader">
        <Typography
          variant="h6"
          color="textSecondary"
          align="left"
          onClick={() => navigate(`/candidate/${candidate.id}`)}
        >
          {activeRoomTitle}
        </Typography>

        {room && (
          <Button
            variant="text"
            onClick={onArchiveClicked}
            endIcon={room.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
            size="small"
          >
            {room.isArchived ? 'Unarchive' : 'Archive'}
          </Button>
        )}
      </div>

      <div className="MessageContainerBody">
        {room && room.chatMessages.loading && (
          <div className="LoaderContainer">
            <CircularProgress />
          </div>
        )}

        {room && room.chatMessages.loaded && <ul>{renderRoomMessages(room.chatMessages.messages)}</ul>}

        {room && room.chatMessages.loaded && room.chatMessages.messages.length == 0 && (
          <div className="NoMessage">Type your first message...</div>
        )}
      </div>

      {room && room.chatMessages.loaded && (
        <MessageSend isSendDisabled={isSendDisabled} candidateId={room ? parseInt(room.candidateId) : undefined} />
      )}
    </div>
  );
};

ChatMessages.propTypes = {};

export default ChatMessages;
