import { BooleanInput, Create, CreateProps, email, maxLength, required, SimpleForm, TextInput } from 'react-admin';
import Box from '@mui/material/Box';
import React from 'react';
import { commonStyles } from '../CommonStyles';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import CustomDateInputV3 from '../CustomDateInputV3';

export const CandidateCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props} actions={false}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<FooterToolbar />} sx={commonStyles.flexVertical}>
        <Box sx={commonStyles.flexBox}>
          <TextInput source="title" validate={[required(), maxLength(50)]} sx={commonStyles.flexBoxItem} />
          <TextInput source="firstName" validate={[required(), maxLength(255)]} sx={commonStyles.flexBoxItem} />
          <TextInput source="lastName" validate={[required(), maxLength(255)]} sx={commonStyles.flexBoxItem} />
          <TextInput source="nickName" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput
            source="personalEmail"
            type="email"
            validate={[email(), required(), maxLength(255)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput
            source="workEmail"
            type="email"
            validate={[email(), maxLength(255)]}
            sx={commonStyles.flexBoxItem}
          />
          <TextInput source="mobile" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
          <TextInput source="homePhone" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
          <TextInput source="otherPhone" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput source="birthday" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
          <BooleanInput source="doNotHire" sx={commonStyles.flexBoxItem} />
          <BooleanInput source="hasMilitaryExperience" sx={commonStyles.flexBoxItem} />
        </Box>
        <Box sx={commonStyles.flexBox}>
          <TextInput source="referredBy" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
          <CustomDateInputV3 label="Availability Date" source="availabilityDate" sx={commonStyles.flexBoxItem} />

          <BooleanInput source="sendPasswordReset" label="Send Password Reset Email" sx={commonStyles.flexBoxItem} />
        </Box>
      </SimpleForm>
    </Create>
  );
};
