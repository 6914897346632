import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { styles } from './styles';

export const HelpInfo = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} sx={styles.helpIconContainer}>
        <HelpIcon fontSize="medium" />
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        sx={styles.popover}
      >
        <Box sx={styles.popoverContainer}>
          <Typography variant="body2" color="textSecondary" align="left">
            Search is case-insensitive
          </Typography>
          <Typography variant="body2" color="textSecondary" align="left" sx={styles.textBottomSpacing}>
            Combine Candidate/Placement Id(s) etc with text and operators (+, -, *)
          </Typography>

          <Typography component="p" variant="body2" color="textSecondary" align="center">
            +21361 +Shell -GAS
          </Typography>
          <Typography component="p" variant="body2" color="textSecondary" align="center">
            Excel Can*
          </Typography>

          <Typography component="p" variant="body2" color="textSecondary" align="left" sx={styles.textBottomSpacing}>
            The + and — operators are used to indicate that a word must be present or absent in the record. The asterisk
            is appended to the word. Words match if they begin with the word preceding the * operator.
          </Typography>

          <Typography component="span" variant="body2" color="textSecondary" align="left">
            Operators
            <ul>
              <li>+ stands for AND</li>
              <li>- stands for NOT</li>
              <li>[no operator] implies OR</li>
              <li>* stands for STARTS WITH (min 3 characters)</li>
            </ul>
          </Typography>
        </Box>
      </Popover>
    </>
  );
};
