export const styles = {
  tabContentContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 3,
  },
  parseCvButton: {
    width: '98px',
  },
  downloadCvButton: {
    width: '34px',
  },
};
