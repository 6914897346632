import { isEmpty, isUndefined, omitBy } from 'lodash';
import { DataProvider, GetListParams, GetManyReferenceParams } from 'ra-core/src/types';
import simpleRestProvider from 'ra-data-simple-rest';
import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  fetchUtils,
  GetManyParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';
import { apiConfig, REACT_APP_REVONE_API_URL } from '../api';

const getDataProviderFactory = (postfix: string) => {
  const createHttpClient = (metaParams = {}) => {
    return (url, options: fetchUtils.Options = {}) => {
      if (!options.headers) {
        options.headers = new Headers(options.headers || new Headers(apiConfig.headers));
      }
      options.user = {
        authenticated: true,
      };
      options.credentials = 'include';

      const requestUrl = isEmpty(metaParams) ? url : `${url}&additionalParams=${encodeURI(JSON.stringify(metaParams))}`;
      return fetchUtils.fetchJson(requestUrl, options);
    };
  };

  const createProvider = (httpClient) => simpleRestProvider(`${REACT_APP_REVONE_API_URL}${postfix}`, httpClient);

  const defaultProvider = createProvider(createHttpClient());

  return (params?: any): DataProvider => {
    const metaParams = omitBy(params?.meta || {}, isUndefined);
    return isEmpty(metaParams) ? defaultProvider : createProvider(createHttpClient(metaParams));
  };
};

export const getDefaultDataProvider = (postfix: string = ''): DataProvider => {
  const getProvider = getDataProviderFactory(postfix);

  return {
    create: (resource: string, params: CreateParams) => getProvider(params).create(resource, params),
    delete: (resource: string, params: DeleteParams) => getProvider(params).delete(resource, params),
    deleteMany: (resource: string, params: DeleteManyParams) => getProvider(params).deleteMany(resource, params),
    getList: (resource: string, params: GetListParams) => getProvider(params).getList(resource, params),
    getMany: (resource: string, params: GetManyParams) => getProvider(params).getMany(resource, params),
    getManyReference: (resource: string, params: GetManyReferenceParams) =>
      getProvider(params).getManyReference(resource, params),
    getOne: (resource: string, params: GetOneParams) => getProvider(params).getOne(resource, params),
    update: (resource: string, params: UpdateParams) => getProvider(params).update(resource, params),
    updateMany: (resource: string, params: UpdateManyParams) => getProvider(params).updateMany(resource, params),
  };
};
