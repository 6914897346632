import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { AxiosResponse } from 'axios';
import React from 'react';
import { Button, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../provider/api';
import { sleep } from '../../../provider/UtilityFunctions';
import { selectChatRoom, updateChatCandidates } from '../../../redux/actions/chatkitActions';
import types from '../../../redux/actions/types';
import { ROOM_CHAT_MESSAGES_INITIAL_STATE } from '../../../redux/reducers/chatkitReducer';
import { RootState } from '../../../redux/reducers/initialState';

type CandidateMessageButtonProps = {
  label?: string;
};

const CandidateMessageButton: React.FC<CandidateMessageButtonProps> = ({ label }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const record = useRecordContext();

  const candidateId = record?.candidate?.id || record?.candidateId || record?.id;
  const lastLoginDate = record?.candidate?.lastLoginOnMobileAppDate || record?.lastLoginOnMobileAppDate;
  const candidateUserId = record?.candidate?.userId || record?.userId;

  const rooms = useSelector((state: RootState) => state.chatkit.rooms);
  const chatRooms = Object.values(rooms).filter((room) => room.candidateId === candidateId);
  let roomId = chatRooms.length > 0 ? chatRooms[0].id : undefined;

  const onClick = async () => {
    if (!roomId) {
      const response: AxiosResponse = await api.chat.createChatRoom(candidateId);
      if (response.status === 200 && response.data.isSuccess) {
        const room = response.data.model;
        roomId = room.id;
        dispatch({
          type: types.chatkit.CHAT_ROOMS_ADD_ROOM,
          room: { ...room, ...ROOM_CHAT_MESSAGES_INITIAL_STATE },
        });
        // @ts-ignore
        dispatch(updateChatCandidates(candidateId, notify));
      }
    }
    await sleep(100);
    redirect(`/messaging/${roomId}`);
    // @ts-ignore
    dispatch(selectChatRoom(roomId?.toString(), notify));
  };
  return (
    <Button label={label} variant="text" disabled={!lastLoginDate || !candidateUserId} onClick={onClick}>
      <ChatBubbleIcon />
    </Button>
  );
};

export default CandidateMessageButton;
