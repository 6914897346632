import { FC } from 'react';
import { AppLocationContext } from '@react-admin/ra-navigation';
import { AppBar, Layout, AppBarProps, LayoutProps } from 'react-admin';
import { AppMenu, MyUserMenu } from './components/Menu/AppMenu';
import { ProfileProvider } from './components/Users/ProfileEdit';
import { getUser } from './provider/authProvider';

const MyAppBar: FC<AppBarProps> = (props) => {
  return getUser() ? <AppBar userMenu={<MyUserMenu />} {...props} /> : null;
};

export const AppLayout: FC<LayoutProps> = (props) => {
  return (
    <AppLocationContext>
      <ProfileProvider>
        <Layout appBar={MyAppBar} menu={AppMenu} {...props} />
      </ProfileProvider>
    </AppLocationContext>
  );
};
