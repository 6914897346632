import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Badge from '@mui/material/Badge';
import msgReceivedSound from '../../assets/message-received.mp3';
import { RESOURCE_MESSAGING } from '../../provider/dataProvider/constants';
import { RootState } from '../../redux/reducers/initialState';
import { useChatContext } from './ChatContextProvider';

const MessagingMenuBadge = () => {
  const [play, { stop }] = useSound(msgReceivedSound, { volume: 0.4 });
  const msgCounter = useSelector((state: RootState) => state.chatkit.receivedMessageCounter);
  const totalUnReadMessages = useSelector((state: RootState) => state.chatkit.totalUnReadMessages);
  const chatContext = useChatContext();

  useEffect(() => {
    if (!chatContext.isChatConnected()) {
      chatContext.connect();
    }
    return () => chatContext.disconnect();
  }, []);

  useEffect(() => {
    if (msgCounter) {
      stop();
      play();
    }
  }, [msgCounter]);

  // @ts-ignore
  return (
    <Badge key={RESOURCE_MESSAGING} badgeContent={totalUnReadMessages} color="secondary">
      <ChatBubbleIcon fontSize="small" />
    </Badge>
  );
};

export default MessagingMenuBadge;
