import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import React from 'react';
import { maxLength, required, TextInput, useRecordContext } from 'react-admin';
import { commonStyles } from '../CommonStyles';
import { ROLE_ADMIN_ID, ROLE_CANDIDATE_ID } from '../../provider/constants';

export const RoleForm = () => {
  const record = useRecordContext();
  const isSystemRole = record?.id === ROLE_CANDIDATE_ID || record?.id === ROLE_ADMIN_ID;

  return (
    <>
      {isSystemRole && (
        <Box sx={{ mb: 2 }}>
          <Alert severity="info">This role is a system role and cannot be modified.</Alert>
        </Box>
      )}
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="name"
          label="Role"
          validate={[required(), maxLength(255)]}
          sx={commonStyles.flexBoxLongItem}
          disabled={isSystemRole}
        />
      </Box>
    </>
  );
};
