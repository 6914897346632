import { SelectInputProps } from 'ra-ui-materialui/src/input/SelectInput';
import { FC } from 'react';
import { required, SelectInput, useGetList } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { RESOURCE_CLIENT } from '../../provider/dataProvider/constants';
import { commonStyles } from '../CommonStyles';

export const ClientSelect: FC<SelectInputProps> = (props) => {
  const { data = [], isPending } = useGetList(RESOURCE_CLIENT, {
    pagination: { page: 1, perPage: MAX_RECORD_PER_PAGE },
    sort: { field: 'name', order: 'ASC' },
  });

  const choices = data.map((client) => ({ id: client.id, name: client.name }));

  return (
    <SelectInput
      {...props}
      isPending={isPending}
      source="clientId"
      choices={choices}
      validate={[required()]}
      sx={commonStyles.flexBoxItem}
    />
  );
};
