import IconClose from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import { DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Datagrid, downloadCSV, Identifier, TextField } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { RESOURCE_CANDIDATE } from '../../../provider/dataProvider/constants';
import { clearBasket, removeFromBasket } from '../../../redux/actions/basketActions';
import { RootState } from '../../../redux/reducers/initialState';
import AddToContactFavoriteButton from '../../Common/buttons/AddToContactFavoriteButton';
import { RemoveButton } from '../../Common/buttons/RemoveButton';

export interface CandidateBasketDialogProps {
  onClose: () => void;
}

const CandidateBasketDialog: React.FC<CandidateBasketDialogProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const [Ids, setIds] = useState([]);
  const data = useSelector((state: RootState) => state.basket[RESOURCE_CANDIDATE]);

  useEffect(() => {
    if (data == undefined || data.length == 0) {
      onClose();
    } else {
      setIds(data.map((item) => item.id));
    }
  }, [data]);

  const onRemove = (id: Identifier) => {
    dispatch(removeFromBasket(RESOURCE_CANDIDATE, [id]));
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open>
      <DialogTitle>Candidates Basket</DialogTitle>
      <DialogContent>
        <Datagrid rowClick={false} data={data} total={data.length} optimized>
          <TextField source="firstName" sortable={false} />
          <TextField source="lastName" sortable={false} />
          <TextField source="nickName" sortable={false} />
          <RemoveButton onRemove={onRemove} />
        </Datagrid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <AddToContactFavoriteButton selectedIds={Ids} onSuccess={onClose} />

        <Button
          color="primary"
          label="Export"
          onClick={() => {
            jsonExport(
              data,
              {
                // headers: ['id', 'post_id', 'post_title', 'body'],
              },
              (err, csv) => {
                downloadCSV(csv, `candidate-basket-${moment().format('MMM-DD-YYYYTHHmm')}`);
              }
            );
          }}
        >
          <GetAppIcon />
        </Button>

        <Button
          color="primary"
          label="Clear basket"
          onClick={() => {
            dispatch(clearBasket(RESOURCE_CANDIDATE));
          }}
        />

        <Button onClick={onClose} color="primary" label="Close">
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateBasketDialog;
