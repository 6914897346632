import { SxProps } from '@mui/material';
import { CreateProps } from 'ra-ui-materialui/src/detail/Create';
import React, { ReactElement, ReactNode } from 'react';
import { Create, SimpleForm } from 'react-admin';
import { commonStyles } from '../../CommonStyles';
import { FooterToolbar } from '../FooterToolbar/FooterToolbar';

type ShowFormProps = CreateProps & {
  toolbar?: ReactElement | false;
  children?: ReactNode;
  formSx?: SxProps;
};

export const SimpleCreateForm: React.FC<ShowFormProps> = ({
  children,
  toolbar = <FooterToolbar />,
  formSx,
  ...rest
}) => {
  return (
    <Create redirect="list" {...rest}>
      <SimpleForm warnWhenUnsavedChanges toolbar={toolbar} sx={[commonStyles.flexVertical, formSx]}>
        {children}
      </SimpleForm>
    </Create>
  );
};
