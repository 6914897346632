import { useState } from 'react';
import {
  RESOURCE_HIGH_LEVEL_INDUSTRY,
  RESOURCE_LOOKUP_CLEARANCE,
  RESOURCE_LOOKUP_CLIENT_EXPERIENCE,
  RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE,
  RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_LOOKUP_SKILL,
  RESOURCE_LOOKUP_SUBINDUSTRY,
  RESOURCE_LOOKUP_WORK_EXPERIENCE,
} from '../../provider/dataProvider/constants';
import { SidebarFilter } from '../Common/filter/SidebarFilter';
import { AutocompleteInput } from '../Common/inputs/AutocompleteInput';
import { JobTitleInput } from './components/JobTitleInput';

export const CandidateSidebarFilter = () => {
  const [workExperienceText, setWorkExperienceText] = useState('');
  return (
    <SidebarFilter showQueries>
      <AutocompleteInput label="Skills" source={RESOURCE_LOOKUP_SKILL} inputSource="skillIds" multiple alwaysOn />
      <AutocompleteInput
        label="Clearances"
        source={RESOURCE_LOOKUP_CLEARANCE}
        inputSource="clearanceIds"
        multiple
        alwaysOn
      />
      <AutocompleteInput
        label="Client Experiences"
        source={RESOURCE_LOOKUP_CLIENT_EXPERIENCE}
        inputSource="clientExperienceIds"
        multiple
        alwaysOn
      />
      <AutocompleteInput
        label="OEM Experience Types"
        source={RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE}
        inputSource="oemExperienceTypeIds"
        multiple
        alwaysOn
      />
      <AutocompleteInput
        label="High Level Industries"
        source={RESOURCE_HIGH_LEVEL_INDUSTRY}
        inputSource="highLevelIndustryids"
        multiple
        alwaysOn
      />
      <AutocompleteInput
        label="Subindustres"
        source={RESOURCE_LOOKUP_SUBINDUSTRY}
        inputSource="subindustryIds"
        multiple
        alwaysOn
      />
      <AutocompleteInput
        label="Work Experience Levels"
        source={RESOURCE_LOOKUP_WORK_EXPERIENCE}
        inputSource="workExperienceIds"
        multiple
        alwaysOn
      />
      <AutocompleteInput
        label="Work Scope Experience"
        source={RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE}
        inputSource="primaryWorkScopeExperienceIds"
        onInputChange={(_, text) => setWorkExperienceText(text)}
        alwaysOn
      />
      <JobTitleInput alwaysOn keyword={workExperienceText} />
    </SidebarFilter>
  );
};
