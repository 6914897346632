import { ReferenceInput, SelectInput, useRecordContext } from 'react-admin';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { RESOURCE_CLIENT_CONTACT } from '../../../provider/dataProvider/constants';

type ClientContactInputProps = {
  sx?: SxProps<Theme>;
};

export const ClientContactInput: React.FC<ClientContactInputProps> = ({ sx }) => {
  const record = useRecordContext();

  const filter = {
    clientId: record?.clientId,
  };

  return (
    <ReferenceInput
      link={false}
      label="Client Contact"
      source="clientContactId"
      reference={RESOURCE_CLIENT_CONTACT}
      filter={filter}
    >
      <SelectInput sx={sx} optionText={(record) => `${record.firstName} ${record.lastName}`} label="Client Contact" />
    </ReferenceInput>
  );
};
