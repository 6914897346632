import React, { ReactElement } from 'react';
import { SaveButton } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { TabbedEditForm } from '../Common/forms/TabbedEditForm';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { EditPropsWithLocation } from '../types';
import { RoleForm } from './RoleForm';

const EditToolbar: React.FC = (props) => {
  const { isValid } = useFormState();

  return (
    <FooterToolbar SaveButtonComponent={<SaveButton label="Save" variant="text" disabled={!isValid} />} {...props} />
  );
};

type RoleEditProps = EditPropsWithLocation & {
  expandView?: boolean;
};

export const RoleEdit: React.FC<RoleEditProps> = (props): ReactElement => {
  return (
    <TabbedEditForm {...props} toolbar={<EditToolbar />}>
      <LazyFormTab label="Role">
        <RoleForm />
      </LazyFormTab>
    </TabbedEditForm>
  );
};
