import { FieldProps } from 'ra-ui-materialui/src/field/types';
import React from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_CANDIDATE } from '../../../provider/dataProvider/constants';

export const CandidateLinkField: React.FC<FieldProps> = () => {
  const record = useRecordContext();

  return (
    <Link to={`/${RESOURCE_CANDIDATE}/${record?.id}`}>
      <TextField source="firstName" />
    </Link>
  );
};
