import moment from 'moment';
import { RaRecord } from 'react-admin';
import { colors } from '../../theme';
import { DEFAULT_DATE_FORMAT, PROSPECT_CANDIDATE_SUBMITTED } from '../../provider/constants';

export const candidateRowStyle = (record: RaRecord) => {
  return {
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

const getRowBorderColor = (record: RaRecord) => {
  const jobResponses = Array.isArray(record.jobResponses) ? record.jobResponses : [];
  const submittedJob = jobResponses.find((jr) => jr.prospectStatusId === PROSPECT_CANDIDATE_SUBMITTED);

  if (record.doNotHire) {
    return colors.indicator.black;
  } else if (submittedJob) {
    return colors.indicator.purple;
  } else if (record['availabilityDate']) {
    const availMoment = moment(record['availabilityDate'], DEFAULT_DATE_FORMAT);
    const nowMoment = moment();
    const months = availMoment.diff(nowMoment, 'months');

    if (availMoment.isBefore(nowMoment)) {
      return colors.indicator.red;
    } else if (months < 2) {
      return colors.indicator.green;
    } else if (months < 5) {
      return colors.indicator.yellow;
    }
  }

  return colors.indicator.grey;
};
