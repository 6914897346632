import { Box } from '@mui/material';
import React from 'react';
import { Labeled, TextField } from 'react-admin';
import {
  billingInformation,
  generalTermsAndConditions,
  mobilizationAndDemobilization,
} from '../../../provider/constants';
import { AdditionalInfoType } from '../../../provider/types';
import { ToggleableTextInput } from '../../Common/inputs/ToggleableTextInput';
import { commonStyles } from '../../CommonStyles';

type AdditionalInfoBlockProps = {
  title: string;
  options: AdditionalInfoType;
  isEditMode: boolean;
};

const InfoBlock: React.FC<AdditionalInfoBlockProps> = ({ title, options, isEditMode }) => (
  <>
    <h3>{title}</h3>
    {Object.entries(options).map(([fieldName, choices]) =>
      isEditMode ? (
        <ToggleableTextInput fieldName={fieldName} choices={choices} key={fieldName} />
      ) : (
        <Labeled key={fieldName}>
          <TextField source={fieldName} emptyText={choices[0]} />
        </Labeled>
      )
    )}
  </>
);

type AdditionalInfoTabProps = {
  withActions?: boolean;
};

export const AdditionalInfoTab: React.FC<AdditionalInfoTabProps> = ({ withActions = false }) => (
  <Box sx={[commonStyles.flexVertical, commonStyles.fullWidth]}>
    <InfoBlock title="General Terms And Conditions" options={generalTermsAndConditions} isEditMode={withActions} />
    <InfoBlock
      title="Mobilization and Demobilization"
      options={mobilizationAndDemobilization}
      isEditMode={withActions}
    />
    <InfoBlock title="Billing Information" options={billingInformation} isEditMode={withActions} />
  </Box>
);
