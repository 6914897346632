import { FC } from 'react';
import { AutocompleteArrayInput, AutocompleteArrayInputProps, ReferenceArrayInput } from 'react-admin';
import { RESOURCE_CLIENT } from '../../provider/dataProvider/constants';

type ClientsReferenceArrayInputProps = Omit<
  AutocompleteArrayInputProps,
  'children' | 'source' | 'optionText' | 'optionValue'
>;

export const ClientsReferenceArrayInput: FC<ClientsReferenceArrayInputProps> = (props) => {
  return (
    <ReferenceArrayInput
      source="clients"
      reference={RESOURCE_CLIENT}
      perPage={300}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteArrayInput label="Clients" {...props} />
    </ReferenceArrayInput>
  );
};
