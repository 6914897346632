import Box from '@mui/material/Box';
import React, { FC } from 'react';
import { email, maxLength, required, TextInput } from 'react-admin';
import { commonStyles } from '../CommonStyles';
import { CityInput } from '../Shared/AddressInputs/CityInput';
import { CountryInput } from '../Shared/AddressInputs/CountryInput';
import { StateInput } from '../Shared/AddressInputs/StateInput';

export const ClientContactForm: FC = () => {
  return (
    <Box sx={commonStyles.flexVertical}>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="firstName"
          label="First Name"
          validate={[required(), maxLength(120)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="lastName"
          label="Last Name"
          validate={[required(), maxLength(120)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput source="jobTitle" label="Job Title" validate={[maxLength(120)]} sx={commonStyles.flexBoxItem} />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="address.email"
          label="Email"
          validate={[email(), maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput source="address.phone" label="Phone" validate={[maxLength(255)]} sx={commonStyles.flexBoxItem} />
        <TextInput
          source="address.alternativeEmail"
          label="Alternative Email"
          validate={[email(), maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
        <TextInput
          source="address.alternativePhone"
          label="Alternative Phone"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="address.addressLine1"
          label="Address"
          validate={[maxLength(255)]}
          sx={commonStyles.flexBoxLongItem}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CountryInput sx={commonStyles.flexBoxItem} source="address.country" />
        <StateInput sx={commonStyles.flexBoxItem} source="address.state" countryFieldName="address.country" />
        <CityInput sx={commonStyles.flexBoxItem} source="address.city" stateFieldName="address.state" />
      </Box>
    </Box>
  );
};
