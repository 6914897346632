import { Identifier, useListContext, useNotify, useRefresh, useUnselectAll } from 'react-admin';
import { RESOURCE_CANDIDATE } from '../../../provider/dataProvider/constants';
import { useAddToFavoriteList } from './useAddToFavoriteList';

export const useAddManyToFavoriteList = () => {
  const unselectAll = useUnselectAll(RESOURCE_CANDIDATE);
  const refresh = useRefresh();
  const notify = useNotify();
  const { selectedIds = [] } = useListContext();
  const addToFavoriteList = useAddToFavoriteList();

  return async (favoriteId: Identifier, favoriteName: string) => {
    let successCount = 0;
    let errorCount = 0;

    for (const candidateId of selectedIds) {
      await addToFavoriteList(favoriteId, candidateId).catch(() => errorCount++);
      successCount++;
    }

    if (successCount > 0) {
      notify(`Successfully added ${successCount} candidate(s) to "${favoriteName}" list`, {
        type: 'info',
        undoable: false,
      });
    }

    if (errorCount > 0) {
      notify(`Failed to add ${errorCount} candidate(s) to the list`, {
        type: 'warning',
        undoable: false,
      });
    }

    unselectAll();
    refresh();
  };
};
