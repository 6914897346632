import { format, parse } from 'date-format-parse';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from './constants';

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function isDev() {
  return development;
}

export const unCamelCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isValidDate(d) {
  // @ts-ignore
  return d instanceof Date && !isNaN(d);
}

export function convertStrToDate(value, formatStr = DEFAULT_DATE_FORMAT) {
  if (value) {
    try {
      const d = value instanceof Date ? value : parse(value, formatStr.toUpperCase());
      if (isValidDate(d)) {
        return d;
      }
      // eslint-disable-next-line no-empty
    } catch (_) {}
  }
  return undefined;
}

export function tryJsonParse(value: string) {
  try {
    return JSON.parse(value);
  } catch (e) {
    console.log('tryJsonParse failed', e);
    return value;
  }
}

export function convertDateToStr(value, formatStr = DEFAULT_DATE_FORMAT) {
  if (isNotEmpty(value)) {
    if (value instanceof dayjs) {
      // @ts-ignore
      return value.format(DEFAULT_DATE_FORMAT);
    } else if (isValidDate(value)) {
      return format(value, formatStr.toUpperCase());
    } else if (typeof value == 'string') {
      return value;
    } else {
      throw `Unsupported date to string conversion. See convertDateToStr() for: ${value} ${formatStr} ${typeof value}`;
    }
  }
  return null;
}

export function capitalizeFirstLetter(string) {
  return isEmpty(string) ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}

export function isEmpty(value) {
  return value == null || value == undefined || String(value).length === 0;
}

export function isNotEmpty(value) {
  return !isEmpty(value);
}

export function truncateLongMessage(msg, maxLength) {
  if (isEmpty(msg)) return '';
  if (msg.length >= maxLength) {
    return `${msg.substring(0, maxLength - 3)}...`;
  }
  return msg;
}

/**
 * Returns a big string to search text within object.
 *
 * @param ob input object /array
 * @returns {string} Concatenates string value of all fields of given object.(Run recursively)
 */
export function getValues(ob) {
  let toReturn = '';
  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if (typeof ob[i] == 'object' && ob[i] !== null) {
      toReturn += getValues(ob[i]);
    } else {
      toReturn += ob[i];
    }
  }
  return toReturn;
}

export function getErrorResponseFromStatusCode(code) {
  const isPosibbleCors = code === undefined;
  if (isPosibbleCors) return 'A network error occurred';
  const errorResponseCodes = [
    { code: 400, message: 'Bad Request' },
    { code: 401, message: 'Unauthorized' },
    { code: 402, message: 'Payment Required' },
    { code: 403, message: 'Access Denied' },
    { code: 404, message: 'No Data Found' },
    { code: 405, message: 'Method Not Found' },
    { code: 406, message: 'Not Acceptable' },
    { code: 407, message: 'Proxy Authentication Required' },
    { code: 408, message: 'Request Timeout' },
    { code: 409, message: 'Conflict' },
    { code: 413, message: 'Request Too large' },
    { code: 415, message: 'Unsupported Media-Type' },
    { code: 429, message: 'Quota Exceeded / Throttled' },
    { code: 500, message: 'Internal Server Error' },
    { code: 501, message: 'Not Implemented' },
    { code: 502, message: 'Bad Gateway' },
    { code: 503, message: 'Service Unavailable' },
    { code: 504, message: 'Gateway Timeout' },
    { code: 505, message: 'HTTP Version Not Supported' },
  ];

  const foundResponse = errorResponseCodes.find((x) => x.code === code);
  return foundResponse === undefined ? `Status code ${code} error returned from server` : foundResponse.message;
}

export function getAPIErrorMessage(err, defaultErrorMsg = '') {
  if (err?.response?.data?.message) {
    return Promise.reject(err?.response?.data?.message);
  } else {
    return Promise.reject(defaultErrorMsg || err);
  }
}

export function clearLocalStorageListParams() {
  const keysToRemove = Object.keys(localStorage).filter((key) => key.endsWith('listParams'));

  for (const key in keysToRemove) {
    localStorage.removeItem(keysToRemove[key]);
  }
}

export function clearLocalStorageColumns() {
  const keysToRemove = Object.keys(localStorage).filter((key) => key.endsWith('olumns'));

  for (const key in keysToRemove) {
    localStorage.removeItem(keysToRemove[key]);
  }
}
