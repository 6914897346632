import { useEffect } from 'react';
import { required } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import CustomNumberInput from '../../Common/inputs/CustomNumberInput';
import { commonStyles } from '../../CommonStyles';

export const PayRateInputs = () => {
  const straightTimePayRate = useWatch({ name: 'straightTimePayRate', defaultValue: 0 });
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('overtimePayRate', straightTimePayRate * 1.5);
    setValue('doubleTimePayRate', straightTimePayRate * 2);
  }, [straightTimePayRate]);

  return (
    <>
      <CustomNumberInput source="straightTimePayRate" validate={[required()]} sx={commonStyles.flexBoxItem} />
      <CustomNumberInput source="overtimePayRate" disabled sx={commonStyles.flexBoxItem} />
      <CustomNumberInput source="doubleTimePayRate" sx={commonStyles.flexBoxItem} />
    </>
  );
};
