import Ably, { ClientOptions, ErrorInfo, RealtimeClient, TokenDetails, TokenParams, TokenRequest } from 'ably';
import { getUser } from '../../../provider/authProvider';
import api from '../../../provider/api';

export const authCallback = async (
  data: TokenParams,
  callback: (
    error: ErrorInfo | string | null,
    tokenRequestOrDetails: TokenDetails | TokenRequest | string | null
  ) => void
) => {
  let token: string;
  try {
    token = await api.auth.getAblyToken(); // Make a network request to your server
    console.log('Refreshing ably token: ', token);
  } catch (err) {
    callback(err, null);
    return;
  }
  callback(null, token);
};

export const getAblyConnectionOptions = (): ClientOptions => {
  if (!getUser()) {
    return;
  }
  const connectionOptions = {
    // token: user.ablyToken,
    // clientId,
    authCallback,
  };
  console.log('Ably connection options: ', connectionOptions);
  return connectionOptions;
};

export const initConnection = (): RealtimeClient | undefined => {
  console.log('\n\nConnecting to Chat Server \n\n');

  const connectionOptions = getAblyConnectionOptions();

  if (!connectionOptions) {
    return;
  }

  return new Ably.Realtime(connectionOptions);
};
