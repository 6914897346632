import moment from 'moment/moment';
import { ISO_DATE_FORMAT } from '../../provider/constants';
export const DEFAULT_ROWS_PER_PAGE = 5;

export const ALL_1970 = '1970-01-01';
export const ALL_2099 = '2099-01-01';
export const pastChoices = [
  {
    id: moment().subtract(1, 'months').utc().startOf('day').format(ISO_DATE_FORMAT),
    name: '1 Month',
  },
  {
    id: moment().subtract(3, 'months').utc().startOf('day').format(ISO_DATE_FORMAT),
    name: '3 Months',
  },
  {
    id: moment().subtract(5, 'months').utc().startOf('day').format(ISO_DATE_FORMAT),
    name: '5 Months',
  },
];

export const featureChoices = [
  {
    id: moment().add(1, 'months').utc().startOf('day').format(ISO_DATE_FORMAT),
    name: '1 Month',
  },
  {
    id: moment().add(3, 'months').utc().startOf('day').format(ISO_DATE_FORMAT),
    name: '3 Months',
  },
  {
    id: moment().add(5, 'months').utc().startOf('day').format(ISO_DATE_FORMAT),
    name: '5 Months',
  },
];
