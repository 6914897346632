import * as React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Identifier, useNotify, useRefresh } from 'react-admin';
import { RESOURCE_JOB_RESPONSE } from '../../provider/dataProvider/constants';
import { useDataProvider } from '../../provider/dataProvider/useDataProvider';

export type RemoveButtonProps = {
  selectedIds: Identifier[];
  jobId?: string;
  clearSelection?: () => void;
};

const RemoveMultipleButton: React.FC<RemoveButtonProps> = ({ selectedIds = [], clearSelection }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const onClicked = async () => {
    let successCount = 0;
    let errorCount = 0;

    for (const jobResponseId of selectedIds) {
      await dataProvider
        .delete(RESOURCE_JOB_RESPONSE, {
          id: jobResponseId,
        })
        .then(({ data }) => {
          successCount++;
        })
        .catch((error) => {
          console.log('Error', error);
          errorCount++;
        });
    }

    // Show summary notification
    if (successCount > 0) {
      notify(`Successfully removed ${successCount} Prospect candidate(s)`, { type: 'info', undoable: false });
    }

    if (errorCount > 0) {
      notify(`Failed to remove ${errorCount} Prospect candidate(s)`, { type: 'warning', undoable: false });
    }

    if (successCount > 0) {
      refresh();
      if (clearSelection) clearSelection();
    }
  };

  return (
    <Button label="Remove Prospects" onClick={onClicked}>
      <DeleteIcon />
    </Button>
  );
};

RemoveMultipleButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default RemoveMultipleButton;
