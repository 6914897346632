import { usePermissions } from 'react-admin';
import { canAccess, Permissions } from '@react-admin/ra-rbac';
import { actionPermissions } from '../Permissions/constants';
import { ParentMenuItemData, ResourceMenuItemData } from './menuItems';

const filterItems = (menuItems: Array<ResourceMenuItemData | ParentMenuItemData>, permissions: Permissions) => {
  return menuItems.reduce((acc, menuItem) => {
    if ('resource' in menuItem) {
      if (!menuItem.resource) {
        // If no resource specified, include the menu item
        acc.push(menuItem);
      } else if (Array.isArray(menuItem.resource)) {
        // If resource is an array, check if user has access to any of the resources
        const hasAccessToAny = menuItem.resource.some((resource) =>
          canAccess({
            permissions,
            action: actionPermissions.list,
            resource,
          })
        );
        if (hasAccessToAny) {
          acc.push(menuItem);
        }
      } else {
        // Single resource check
        if (
          canAccess({
            permissions,
            action: actionPermissions.list,
            resource: menuItem.resource,
          })
        ) {
          acc.push(menuItem);
        }
      }
    }
    if ('items' in menuItem) {
      const filteredChildren = filterItems(menuItem.items, permissions);
      if (filteredChildren?.length > 0) {
        acc.push({
          ...menuItem,
          items: filteredChildren,
        });
      }
    }
    return acc;
  }, []);
};

const useFilteredMenuItems = (menuItems: Array<ResourceMenuItemData | ParentMenuItemData>) => {
  const { isLoading, permissions } = usePermissions();

  if (isLoading) {
    return [];
  }

  return filterItems(menuItems, permissions);
};

export default useFilteredMenuItems;
