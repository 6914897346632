import { FC } from 'react';
import { Box } from '@mui/material';
import { TabbedShowLayout, TextField, Labeled, BooleanField, FunctionField } from 'react-admin';
import * as React from 'react';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { commonStyles } from '../CommonStyles';
import { RESOURCE_PLACEMENT_COMMENTS } from '../../provider/dataProvider/constants';
import { CommentsTab } from '../Comment/CommentsTab';
import CustomDateField from '../Common/fields/CustomDateField';
import { JobInfoTab } from './tabs/JobInfoTab';
import { DocumentsTab } from './tabs/DocumentsTab/DocumentsTab';
import { AdditionalInfoTab } from './tabs/AdditionalInfoTab';

type PlacementShowProps = Omit<ShowLayoutProps, 'children'>;

export const PlacementShow: FC<PlacementShowProps> = (props) => {
  return (
    <ShowLayout {...props}>
      <TabbedShowLayout.Tab label="Placement" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="id" label="Placement ID" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <FunctionField
                sx={commonStyles.flexBoxItem}
                label="Candidate"
                render={(record) =>
                  record.candidate ? `${record.candidate?.firstName} ${record.candidate?.lastName}` : ''
                }
              />
            </Labeled>
            <Labeled>
              <TextField source="job.jobTitle" label="Job Title" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField label="Start Date" source="startDate" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <CustomDateField label="End Date" source="endDate" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="duration" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField label="Onboarding Complete" source="onboardingComplete" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <CustomDateField
                label="Background Check Complete"
                source="backgroundCheckComplete"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
            <Labeled>
              <CustomDateField label="Physical Complete" source="physicalComplete" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField label="Drug Screen Complete" source="drugScreenComplete" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <CustomDateField label="Rev1 Training Complete" source="trainingComplete" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <CustomDateField
                label="Client Training Complete"
                source="clientTrainingComplete"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="straightTimeBillRate" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="overtimeBillRate" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="doubleTimeBillRate" label="Double time bill rate" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="straightTimePayRate" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="overtimePayRate" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="doubleTimePayRate" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="perDiem" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="rotationId" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <CustomDateField source="rotationStartDate" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <BooleanField label="Computer Required" source="computerRequired" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <BooleanField label="Email Required" source="emailRequired" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <BooleanField
                label="Bypass Client Training"
                source="bypassClientTraining"
                sx={commonStyles.flexBoxItem}
              />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled sx={commonStyles.flexBoxItem}>
              <FunctionField
                label="Client Contact"
                render={(record) =>
                  record.clientContact ? `${record.clientContact?.firstName} ${record.clientContact?.lastName}` : ''
                }
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <FunctionField
                label="Project Contact"
                render={(record) =>
                  record.projectContact ? `${record.projectContact?.firstName} ${record.projectContact?.lastName}` : ''
                }
              />
            </Labeled>
            <Labeled sx={commonStyles.flexBoxItem}>
              <TextField source="fieldManager.name" label="Field Manager" />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
        </Box>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Comments" sx={commonStyles.formTabHeader}>
        <CommentsTab reference={RESOURCE_PLACEMENT_COMMENTS} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Job Info" sx={commonStyles.formTabHeader}>
        <JobInfoTab />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Additional Info" sx={commonStyles.formTabHeader}>
        <AdditionalInfoTab />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Documents" sx={commonStyles.formTabHeader}>
        <DocumentsTab />
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};
