import { FC, useEffect, useState, useCallback } from 'react';
import { Login, useNotify } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import Logo from '../../assets/LeftLogo.png';
import api from '../../provider/api';
import './CandidateVerifyEmail.css';
import CandidateEmailConfirmation from './CandidateEmailConfirmation';

const CandidateVerifyEmail: FC = () => {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotify();

  const verifyEmail = useCallback(async () => {
    if (!token || !email) return;

    try {
      const response = await api.auth.candidateVerifyEmail(email, token);
      if (response.status === 200) {
        notify(response.data?.message || 'Email verified successfully', { type: 'success', undoable: false });
        setIsVerified(true);
      }
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err?.response?.data?.message) {
        notify(`${err?.response?.data?.message}. Try again, please!`, {
          type: 'error',
          undoable: false,
        });
      } else {
        notify('Email verification failed. Try again, please!', { type: 'error', undoable: false });
      }
    }
  }, [token, email, notify]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = decodeURIComponent(searchParams.get('email') || '');
    const tokenParam = decodeURIComponent(searchParams.get('token') || '');

    if (tokenParam) {
      setToken(tokenParam);
    }

    if (emailParam) {
      setEmail(emailParam);
    }

    if (tokenParam && emailParam) {
      verifyEmail().then();
    }
  }, [location, verifyEmail]);

  if (isVerified) {
    return <CandidateEmailConfirmation />;
  }

  return (
    <Login backgroundImage={Logo}>
      <div className="CandidateVerifyEmailContainer">
        <h2>Email Verification</h2>
        <p>Verifying your email address...</p>
      </div>
    </Login>
  );
};

export default CandidateVerifyEmail;
