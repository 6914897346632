import { FC } from 'react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { TabbedShowLayout, useRecordContext, TextField, Labeled, RichTextField } from 'react-admin';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { ShowLayout, ShowLayoutProps } from '../Common/layout/ShowLayout';
import { UploadDocument } from '../Common/UploadDocument/UploadDocument';
import { commonStyles } from '../CommonStyles';
import {
  RESOURCE_CLIENT,
  RESOURCE_PROJECT_COMMENTS,
  RESOURCE_PROJECT_DOCUMENTS,
} from '../../provider/dataProvider/constants';
import { CommentsTab } from '../Comment/CommentsTab';
import LinkField from '../LinkField';
import { DocumentsTab } from '../Shared/DocumentsTab';
import CustomDateField from '../Common/fields/CustomDateField';
import { ProjectContactList } from '../ProjectContacts/ProjectContactList';
import { JobsTab } from './tabs/JobsTab';
import { IndustriesTab } from './tabs/IndustriesTab';

type ProjectShowProps = Omit<ShowLayoutProps, 'children'>;

export const ProjectShow: FC<ProjectShowProps> = (props) => {
  const { id } = useParams();
  const record = useRecordContext();
  const projectId = id || record?.id;

  return (
    <ShowLayout {...props}>
      <TabbedShowLayout.Tab label="Project" sx={commonStyles.formTabHeader}>
        <Box sx={commonStyles.flexVertical}>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="id" label="Project ID" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="name" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <LinkField source="clientId" resource={RESOURCE_CLIENT} label="Client" showMode>
                <TextField source="client.name" sx={commonStyles.flexBoxItem} />
              </LinkField>
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <RichTextField source="description" sx={commonStyles.flexBoxLongItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <RichTextField source="address.addressLine1" label="Address" sx={commonStyles.flexBoxLongItem} />
            </Labeled>
            <Labeled>
              <TextField source="address.zip" label="zip" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <TextField source="address.country" label="Country" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="address.state" label="State" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="address.city" label="City" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
          <Box sx={commonStyles.flexBox}>
            <Labeled>
              <CustomDateField source="updatedAt" label="Updated At" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedBy" label="Updated by" sx={commonStyles.flexBoxItem} />
            </Labeled>
            <Labeled>
              <TextField source="updatedByName" label="Updated by name" sx={commonStyles.flexBoxItem} />
            </Labeled>
          </Box>
        </Box>
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Jobs" sx={commonStyles.formTabHeader}>
        <JobsTab projectId={projectId} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Contacts" sx={commonStyles.formTabHeader}>
        {projectId && <ProjectContactList projectId={projectId} />}
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Comments" sx={commonStyles.formTabHeader}>
        <CommentsTab reference={RESOURCE_PROJECT_COMMENTS} />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Documents" sx={commonStyles.formTabHeader}>
        <DocumentsTab
          reference={RESOURCE_PROJECT_DOCUMENTS}
          showMode
          perPage={MAX_RECORD_PER_PAGE}
          footerActions={
            <UploadDocument
              idField="projectId"
              idValue={projectId}
              resource={RESOURCE_PROJECT_DOCUMENTS}
              documentTypes={[{ id: 'general', name: 'General' }]}
            />
          }
        />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Industries" sx={commonStyles.formTabHeader}>
        <Box sx={{ width: '40%' }}>
          <IndustriesTab projectId={projectId} />
        </Box>
      </TabbedShowLayout.Tab>
    </ShowLayout>
  );
};
