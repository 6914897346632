import DialogActions from '@mui/material/DialogActions';
import IconClose from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { Button, Datagrid, Filter, FilterProps, List, TextField, TextInput, useRecordContext } from 'react-admin';
import React from 'react';
import Box from '@mui/material/Box';
import { RESOURCE_JOB } from '../../provider/dataProvider/constants';
import { SelectButton } from '../Common/buttons/SelectButton';
import { PostPagination } from '../PostPagination';
import CustomDateField from '../Common/fields/CustomDateField';

export interface JobSelectionDialogProps {
  onClose: () => void;
  onSelect: (record: any) => void;
}

const classes = {
  filter: {
    '& form': {
      minHeight: 70,
    },
  },
};

const JobFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Box sx={classes.filter}>
      <Filter {...props}>
        <TextInput source="q" label="Search" alwaysOn />
      </Filter>
    </Box>
  );
};

const JobSelectionDialog: React.FC<JobSelectionDialogProps> = ({ onClose, onSelect }) => {
  return (
    <Dialog maxWidth="lg" onClose={onClose} open>
      <DialogTitle>Select Job</DialogTitle>
      <DialogContent>
        <List
          title=""
          resource={RESOURCE_JOB}
          sort={{ field: 'jobTitle', order: 'ASC' }}
          filters={<JobFilter />}
          perPage={100}
          pagination={<PostPagination />}
          exporter={false}
          filter={{ isActive: true }}
          storeKey="jobSelection"
          disableSyncWithLocation
        >
          <Datagrid rowClick={false} optimized bulkActionButtons={false}>
            <TextField source="id" label="Job ID" sortable />
            <TextField source="client.name" label="Client" sortable />
            <TextField source="jobTitle" sortable />
            <TextField source="numberOfPositions" label="Number of Pos." sortable />
            <CustomDateField source="startDate" label="Start" sortable />
            <CustomDateField source="endDate" label="End" sortable />
            <SelectButton onSelect={onSelect} />
          </Datagrid>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" label="Close">
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobSelectionDialog;
