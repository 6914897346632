import React, { ReactElement, ReactNode } from 'react';
import { Edit, EditProps, TabbedForm, ValidateForm } from 'react-admin';
import { useInvalidateResourceQueryCache } from '../../../provider/dataProvider/useInvalidateResourceQueryCache';
import { commonStyles } from '../../CommonStyles';
import { FooterToolbar, FooterToolbarProps } from '../FooterToolbar/FooterToolbar';

export type TabbedFormProps = EditProps & {
  children: ReactElement[] | ReactElement;
  expandView?: boolean;
  syncWithLocation?: boolean;
  footerToolbarProps?: FooterToolbarProps;
  validate?: ValidateForm;
  autoSave?: boolean | string;
  onAutoSaveSuccess?: () => void;
};

export const TabbedEditForm: React.FC<TabbedFormProps> = ({
  children,
  expandView,
  syncWithLocation: syncWithLocationFromProps,
  footerToolbarProps,
  validate,
  mutationOptions,
  onAutoSaveSuccess,
  ...props
}) => {
  const title = expandView ? false : props.title;
  const syncWithLocation = expandView ? false : syncWithLocationFromProps;

  const invalidateQueries = useInvalidateResourceQueryCache(undefined, true);

  const clearCache = async () => {
    await invalidateQueries();
  };

  return (
    <Edit
      {...props}
      title={title}
      actions={false}
      mutationMode="pessimistic"
      mutationOptions={{
        ...mutationOptions,
        onSettled: clearCache,
      }}
    >
      <TabbedForm
        warnWhenUnsavedChanges
        toolbar={<FooterToolbar {...footerToolbarProps} />}
        syncWithLocation={syncWithLocation}
        validate={validate}
        sx={commonStyles.tabbedFormBoldHeader}
      >
        {children}
      </TabbedForm>
    </Edit>
  );
};
