import { FC } from 'react';
import { Filter, FilterProps } from 'react-admin';
import FreeTextSearchInput from '../../Common/inputs/FreeTextSearchInput/FreeTextSearchInput';
import CustomDateInputV3 from '../../CustomDateInputV3';

type CandidateFilterProps = Omit<FilterProps, 'children'>;

export const CandidateFilter: FC<CandidateFilterProps> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput alwaysOn fullTextSearch />
      <CustomDateInputV3 source="availabilityDate_gte" label="Avail Start" />
      <CustomDateInputV3 source="availabilityDate_lte" label="Avail End" />
    </Filter>
  );
};
