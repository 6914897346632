import { TextField } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { commonStyles } from '../../CommonStyles';
import { calculateRateWithMsp } from '../utils';

export const NetBillRateInputs = () => {
  const msp = useWatch({ name: 'msp', defaultValue: 0 });
  const straightTimeBillRate = useWatch({ name: 'straightTimeBillRate', defaultValue: 0 });
  const overtimeBillRate = useWatch({ name: 'overtimeBillRate', defaultValue: 0 });
  const doubleTimeBillRate = useWatch({ name: 'doubleTimeBillRate', defaultValue: 0 });

  return (
    <>
      <TextField
        label="Net ST Bill rate"
        sx={commonStyles.flexBoxItem}
        value={(calculateRateWithMsp(straightTimeBillRate, msp) || 0).toFixed(2)}
        disabled
      />
      <TextField
        label="Net OT Bill rate"
        sx={commonStyles.flexBoxItem}
        value={(calculateRateWithMsp(overtimeBillRate, msp) || 0).toFixed(2)}
        disabled
      />
      <TextField
        label="Net DT Bill rate"
        sx={commonStyles.flexBoxItem}
        value={(calculateRateWithMsp(doubleTimeBillRate, msp) || 0).toFixed(2)}
        disabled
      />
    </>
  );
};
