import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  ReferenceArrayInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import CommentIcon from '@mui/icons-material/Comment';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import { RESOURCE_LOOKUP_FIELDMANAGER } from '../../provider/dataProvider/constants';
import { getUser } from '../../provider/authProvider';
import { LOOKUP_PAGE_SIZE } from '../../provider/constants';

const Actions = ({ associatedEntityId, ...rest }) => {
  const form = useFormContext();
  const refresh = useRefresh();
  const user = getUser();

  return (
    <Toolbar {...rest}>
      <SaveButton
        icon={<CommentIcon />}
        label="Add Comment"
        variant="text"
        type="button"
        transform={(data) => {
          return {
            ...data,
            fieldManagerId: user.fieldManagerId,
            associatedEntityId,
            fieldManagerIds: data.fieldManagerIds || [],
          };
        }}
        mutationOptions={{
          onSuccess: () => {
            form.reset();
            refresh();
          },
        }}
      />
    </Toolbar>
  );
};

export type CommentCreateProps = {
  resource: string;
};

export const CommentCreate: React.FC<CommentCreateProps> = ({ resource }) => {
  const record = useRecordContext();

  return (
    <Create title=" " resource={resource} actions={false}>
      <SimpleForm toolbar={<Actions associatedEntityId={record?.id} />}>
        <RichTextInput source="text" label={false} fullWidth validate={[required()]} />

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
          }}
        >
          <ReferenceArrayInput
            reference={RESOURCE_LOOKUP_FIELDMANAGER}
            source="fieldManagerIds"
            label="Notify field managers"
            perPage={LOOKUP_PAGE_SIZE}
          >
            <AutocompleteArrayInput />
          </ReferenceArrayInput>

          <BooleanInput
            source="sendToAll"
            label="Notify all field managers"
            defaultValue={false}
            sx={{ marginLeft: '10px' }}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
