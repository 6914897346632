import React from 'react';
import { SimpleForm } from 'react-admin';
import { EditInDialogButtonProps } from '@react-admin/ra-form-layout/src/forms/dialog-form/EditInDialogButton';
import { EditInDialogButton } from '@react-admin/ra-form-layout';
import isEmpty from 'lodash/isEmpty';
import { FooterToolbar } from '../Common/FooterToolbar/FooterToolbar';
import { RESOURCE_PROJECT } from '../../provider/dataProvider/constants';
import { ProjectContactForm } from './ProjectContactForm';

export const ProjectContactEditInDialog: React.FC<EditInDialogButtonProps> = (props) => {
  return (
    <EditInDialogButton
      label=""
      title="Edit Contact"
      actions={false}
      mutationMode="pessimistic"
      fullWidth
      maxWidth="lg"
      transform={(data) => {
        const sanitized = { ...data };
        if (data?.clientContact?.address) {
          if (isEmpty(data.clientContact.address?.alternativeEmail)) {
            delete sanitized.clientContact.address.alternativeEmail;
          }

          if (isEmpty(data?.clientContact.address?.email)) {
            delete sanitized.clientContact.address.email;
          }
        }
        return sanitized;
      }}
    >
      <SimpleForm warnWhenUnsavedChanges toolbar={<FooterToolbar cancelButtonResource={RESOURCE_PROJECT} />}>
        <ProjectContactForm />
      </SimpleForm>
    </EditInDialogButton>
  );
};
