import axios from 'axios';
import { CreateParams } from 'react-admin';
import { apiConfig, REACT_APP_REVONE_API_URL } from '../api';

export const documentProvider = {
  create: async (resource: string, params: CreateParams) => {
    const formData = new FormData();
    Object.entries(params.data).forEach(([key, value]) => {
      if (value && typeof value === 'object' && 'rawFile' in value) {
        formData.append(key, value.rawFile);
      } else if (value !== null && value !== undefined) {
        formData.append(key, String(value));
      }
    });

    return axios.post(`${REACT_APP_REVONE_API_URL}/v4/${resource}`, formData, {
      ...apiConfig,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
